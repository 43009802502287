import animation from './animation';
import font from './font';
import grid from './grid';
import margin from './margin';
import media from './media';
import misc from './misc';
import normalizers from './normalizers';
import padding from './padding';
import ratio from './ratio';
import specificity from './specificity';

const styleUtils = {
  animation,
  font,
  grid,
  margin,
  media,
  misc,
  normalizers,
  padding,
  ratio,
  specificity,
};

export default styleUtils;
