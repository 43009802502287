// Types
import { CMS_ENUM_SHOWROOM_TYPE } from '../../../types/global';

type FilterOutExclusivePartnersInput = {
  type: CMS_ENUM_SHOWROOM_TYPE;
};

// We don't have pages for exclusive partner showrooms.
const filterOutExclusivePartners = (showroom: FilterOutExclusivePartnersInput) => {
  return showroom.type !== CMS_ENUM_SHOWROOM_TYPE.EXCLUSIVE_PARTNER;
};

export default filterOutExclusivePartners;
