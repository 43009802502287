import buildAbsoluteUrl from './buildAbsoluteUrl';

// Types

type BuildBreadcrumbListItemInput = {
  name: string;
  position: number;
  url: string;
};

const buildNavigationListItem = ({ name, position, url }: BuildBreadcrumbListItemInput) => {
  return {
    '@type': 'SiteNavigationElement',
    item: buildAbsoluteUrl(url),
    name,
    position,
  };
};

export default buildNavigationListItem;
