const isArrowLeftOrUp = (event: KeyboardEvent) => {
  if ('key' in event) {
    return event.key === 'Left' || event.key === 'ArrowLeft' || event.key === 'Up' || event.key === 'ArrowUp';
  }

  if ('keyCode' in event) {
    // @ts-ignore
    return event.keyCode === 37 || event.keyCode === 38;
  }

  return false;
};

export default isArrowLeftOrUp;
