import React from 'react';
import ReactDOM from 'react-dom';

// Types
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const PORTAL_ROOT = typeof document !== `undefined` ? document.getElementById('portal') : null;

const Portal = ({ children }: Props) => {
  const [portalContainer] = React.useState(() => document.createElement('div'));

  React.useEffect(() => {
    if (!!PORTAL_ROOT && !!portalContainer) {
      PORTAL_ROOT.appendChild(portalContainer);
    }

    return () => {
      if (!!PORTAL_ROOT && !!portalContainer) {
        PORTAL_ROOT.removeChild(portalContainer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(children, portalContainer);
};

export default Portal;
