import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SectionItemFooterSocial from '../../../../components/Section/SectionItems/SectionItemFooterSocial';
import { getArchitonicUrl, getFacebookUrl, getHouzzUrl, getInstagramUrl } from './utils';

// Types
import { SectionItemFooterSocialContainerQuery } from './__generated__/SectionItemFooterSocialContainerQuery';

type Props = {
  textColor: string;
};

const SectionItemFooterSocialContainer = ({ textColor }: Props) => {
  const data = useStaticQuery<SectionItemFooterSocialContainerQuery>(graphql`
    query SectionItemFooterSocialContainerQuery {
      site {
        siteMetadata {
          socialMedia {
            architonic
            facebook
            houzz
            instagram
          }
        }
      }
    }
  `);

  const architonicUrl = getArchitonicUrl(data);
  const facebookUrl = getFacebookUrl(data);
  const houzzUrl = getHouzzUrl(data);
  const instagramUrl = getInstagramUrl(data);

  return (
    <SectionItemFooterSocial
      architonicUrl={architonicUrl}
      facebookUrl={facebookUrl}
      houzzUrl={houzzUrl}
      instagramUrl={instagramUrl}
      textColor={textColor}
    />
  );
};

export default SectionItemFooterSocialContainer;
