import React from 'react';

import Navigation from '../../../components/Layout/Navigation';
import NavigationMenuContainer from './NavigationMenu';
import NavigationStructuredData from '../../../utils/seo/NavigationStructuredData';
import useNavigationContext from '../../../utils/context/NavigationContext';
import useOnEscPress from '../../../utils/hooks/useOnEscPress';

// Types

type Props = {};

const NavigationContainer = (_props: Props) => {
  const [isMenuButtonHovering, setIsMenuButtonHovering] = React.useState(false);
  const { closeMenu, isMenuOpen, toggleIsMenuOpen } = useNavigationContext();

  useOnEscPress(() => closeMenu());

  const handleMouseEnter = () => {
    setIsMenuButtonHovering(true);
  };

  const handleMouseLeave = () => {
    setIsMenuButtonHovering(false);
  };

  const navigationMenu = <NavigationMenuContainer />;

  return (
    <>
      {/* SEO */}
      <NavigationStructuredData />

      <Navigation
        isMenuButtonHovering={isMenuButtonHovering}
        isMenuOpen={isMenuOpen}
        navigationMenu={navigationMenu}
        onMenuButtonClick={toggleIsMenuOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
};

export default NavigationContainer;
