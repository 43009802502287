import styled from '../../../utils/styling/styled';

import Heading from '../../Heading';
import styleUtils from '../../../utils/styling/styleUtils';

export const Heading1 = styled(Heading)`
  ${styleUtils.font.weight.bold};
  ${styleUtils.margin.vertical.bottomQuarter};

  color: ${props => props.theme.color.white};
  font-size: 1.6rem;
  text-transform: uppercase;
`;

export const Heading2 = styled(Heading)`
  ${styleUtils.font.weight.bold};
  ${styleUtils.margin.vertical.bottomQuarter};

  color: ${props => props.theme.color.white};
  font-size: 1.2rem;
  text-transform: uppercase;
`;

export const Heading3 = styled(Heading)`
  ${styleUtils.font.weight.bold};
  ${styleUtils.margin.vertical.bottomQuarter};

  color: ${props => props.theme.color.white};
  font-size: 1rem;
  text-transform: uppercase;
`;

export const Heading4 = styled(Heading)`
  ${styleUtils.font.weight.bold};
  ${styleUtils.margin.vertical.bottomQuarter};

  color: ${props => props.theme.color.white};
  font-size: 1rem;
`;
