import css from '@emotion/css';

import media from './media';

const MARGIN_NONE = 0;

const MARGIN_MOBILE = 2;
const MARGIN_DESKTOP = 2;

const MARGIN_DOUBLE_DESKTOP = MARGIN_DESKTOP * 2;
const MARGIN_DOUBLE_MOBILE = MARGIN_MOBILE * 2;

const MARGIN_HALF_DESKTOP = MARGIN_DESKTOP / 2;
const MARGIN_HALF_MOBILE = MARGIN_MOBILE / 2;

const MARGIN_QUADRUPLE_DESKTOP = MARGIN_DESKTOP * 4;
const MARGIN_QUADRUPLE_MOBILE = MARGIN_MOBILE * 4;

const MARGIN_QUARTER_DESKTOP = MARGIN_DESKTOP / 4;
const MARGIN_QUARTER_MOBILE = MARGIN_MOBILE / 4;

// HORIZONTAL NONE

const horizontalMarginLeftNone = css`
  margin-left: ${MARGIN_NONE}rem;
`;

const horizontalMarginRightNone = css`
  margin-right: ${MARGIN_NONE}rem;
`;

const horizontalMarginNone = css`
  ${horizontalMarginLeftNone};
  ${horizontalMarginRightNone};
`;

// HORIZONTAL HALF

const horizontalMarginLeftHalf = css`
  margin-left: ${MARGIN_HALF_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: ${MARGIN_HALF_DESKTOP}rem;
  }
`;

const horizontalMarginRightHalf = css`
  margin-right: ${MARGIN_HALF_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: ${MARGIN_HALF_DESKTOP}rem;
  }
`;

const horizontalMarginHalf = css`
  ${horizontalMarginLeftHalf};
  ${horizontalMarginRightHalf};
`;

// HORIZONTAL QUARTER

const horizontalMarginLeftQuarter = css`
  margin-left: ${MARGIN_QUARTER_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: ${MARGIN_QUARTER_DESKTOP}rem;
  }
`;

const horizontalMarginRightQuarter = css`
  margin-right: ${MARGIN_QUARTER_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: ${MARGIN_QUARTER_DESKTOP}rem;
  }
`;

const horizontalMarginQuarter = css`
  ${horizontalMarginLeftQuarter};
  ${horizontalMarginRightQuarter};
`;

// HORIZONTAL

const horizontalMarginLeft = css`
  margin-left: ${MARGIN_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: ${MARGIN_DESKTOP}rem;
  }
`;

const horizontalMarginRight = css`
  margin-right: ${MARGIN_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: ${MARGIN_DESKTOP}rem;
  }
`;

const horizontalMargin = css`
  ${horizontalMarginLeft};
  ${horizontalMarginRight};
`;

// HORIZONTAL DOUBLE

const horizontalMarginLeftDouble = css`
  margin-left: ${MARGIN_DOUBLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: ${MARGIN_DOUBLE_DESKTOP}rem;
  }
`;

const horizontalMarginRightDouble = css`
  margin-right: ${MARGIN_DOUBLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: ${MARGIN_DOUBLE_DESKTOP}rem;
  }
`;

const horizontalMarginDouble = css`
  ${horizontalMarginLeftDouble};
  ${horizontalMarginRightDouble};
`;

// HORIZONTAL QUADRUPLE

const horizontalMarginLeftQuadruple = css`
  margin-left: ${MARGIN_QUADRUPLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: ${MARGIN_QUADRUPLE_DESKTOP}rem;
  }
`;

const horizontalMarginRightQuadruple = css`
  margin-right: ${MARGIN_QUADRUPLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: ${MARGIN_QUADRUPLE_DESKTOP}rem;
  }
`;

const horizontalMarginQuadruple = css`
  ${horizontalMarginLeftQuadruple};
  ${horizontalMarginRightQuadruple};
`;

// VERTICAL NONE

const verticalMarginBottomNone = css`
  margin-bottom: ${MARGIN_NONE}rem;
`;

const verticalMarginTopNone = css`
  margin-top: ${MARGIN_NONE}rem;
`;

const verticalMarginNone = css`
  ${verticalMarginBottomNone};
  ${verticalMarginTopNone};
`;

// VERTICAL HALF

const verticalMarginBottomHalf = css`
  margin-bottom: ${MARGIN_HALF_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: ${MARGIN_HALF_DESKTOP}rem;
  }
`;

const verticalMarginTopHalf = css`
  margin-top: ${MARGIN_HALF_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: ${MARGIN_HALF_DESKTOP}rem;
  }
`;

const verticalMarginHalf = css`
  ${verticalMarginBottomHalf};
  ${verticalMarginTopHalf};
`;

// VERTICAL QUARTER

const verticalMarginBottomQuarter = css`
  margin-bottom: ${MARGIN_QUARTER_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: ${MARGIN_QUARTER_DESKTOP}rem;
  }
`;

const verticalMarginTopQuarter = css`
  margin-top: ${MARGIN_QUARTER_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: ${MARGIN_QUARTER_DESKTOP}rem;
  }
`;

const verticalMarginQuarter = css`
  ${verticalMarginBottomQuarter};
  ${verticalMarginTopQuarter};
`;

// VERTICAL

const verticalMarginBottom = css`
  margin-bottom: ${MARGIN_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: ${MARGIN_DESKTOP}rem;
  }
`;

const verticalMarginTop = css`
  margin-top: ${MARGIN_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: ${MARGIN_DESKTOP}rem;
  }
`;

const verticalMargin = css`
  ${verticalMarginBottom};
  ${verticalMarginTop};
`;

// VERTICAL DOUBLE

const verticalMarginBottomDouble = css`
  margin-bottom: ${MARGIN_DOUBLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: ${MARGIN_DOUBLE_DESKTOP}rem;
  }
`;

const verticalMarginTopDouble = css`
  margin-top: ${MARGIN_DOUBLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: ${MARGIN_DOUBLE_DESKTOP}rem;
  }
`;

const verticalMarginDouble = css`
  ${verticalMarginBottomDouble};
  ${verticalMarginTopDouble};
`;

// VERTICAL QUADRUPLE

const verticalMarginBottomQuadruple = css`
  margin-bottom: ${MARGIN_QUADRUPLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: ${MARGIN_QUADRUPLE_DESKTOP}rem;
  }
`;

const verticalMarginTopQuadruple = css`
  margin-top: ${MARGIN_QUADRUPLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: ${MARGIN_QUADRUPLE_DESKTOP}rem;
  }
`;

const verticalMarginQuadruple = css`
  ${verticalMarginBottomQuadruple};
  ${verticalMarginTopQuadruple};
`;

// NEGATIVE HORIZONTAL HALF

const negativeHorizontalMarginLeftHalf = css`
  margin-left: -${MARGIN_HALF_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: -${MARGIN_HALF_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginRightHalf = css`
  margin-right: -${MARGIN_HALF_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: -${MARGIN_HALF_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginHalf = css`
  ${negativeHorizontalMarginLeftHalf};
  ${negativeHorizontalMarginRightHalf};
`;

// NEGATIVE HORIZONTAL QUARTER

const negativeHorizontalMarginLeftQuarter = css`
  margin-left: -${MARGIN_QUARTER_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: -${MARGIN_QUARTER_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginRightQuarter = css`
  margin-right: -${MARGIN_QUARTER_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: -${MARGIN_QUARTER_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginQuarter = css`
  ${negativeHorizontalMarginLeftQuarter};
  ${negativeHorizontalMarginRightQuarter};
`;

// NEGATIVE HORIZONTAL

const negativeHorizontalMarginLeft = css`
  margin-left: -${MARGIN_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: -${MARGIN_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginRight = css`
  margin-right: -${MARGIN_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: -${MARGIN_DESKTOP}rem;
  }
`;

const negativeHorizontalMargin = css`
  ${negativeHorizontalMarginLeft};
  ${negativeHorizontalMarginRight};
`;

// NEGATIVE HORIZONTAL DOUBLE

const negativeHorizontalMarginLeftDouble = css`
  margin-left: -${MARGIN_DOUBLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: -${MARGIN_DOUBLE_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginRightDouble = css`
  margin-right: -${MARGIN_DOUBLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: -${MARGIN_DOUBLE_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginDouble = css`
  ${negativeHorizontalMarginLeftDouble};
  ${negativeHorizontalMarginRightDouble};
`;

// NEGATIVE HORIZONTAL QUADRUPLE

const negativeHorizontalMarginLeftQuadruple = css`
  margin-left: -${MARGIN_QUADRUPLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-left: -${MARGIN_QUADRUPLE_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginRightQuadruple = css`
  margin-right: -${MARGIN_QUADRUPLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-right: -${MARGIN_QUADRUPLE_DESKTOP}rem;
  }
`;

const negativeHorizontalMarginQuadruple = css`
  ${negativeHorizontalMarginLeftQuadruple};
  ${negativeHorizontalMarginRightQuadruple};
`;

// NEGATIVE VERTICAL HALF

const negativeVerticalMarginBottomHalf = css`
  margin-bottom: -${MARGIN_HALF_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: -${MARGIN_HALF_DESKTOP}rem;
  }
`;

const negativeVerticalMarginTopHalf = css`
  margin-top: -${MARGIN_HALF_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: -${MARGIN_HALF_DESKTOP}rem;
  }
`;

const negativeVerticalMarginHalf = css`
  ${negativeVerticalMarginBottomHalf};
  ${negativeVerticalMarginTopHalf};
`;

// NEGATIVE VERTICAL QUARTER

const negativeVerticalMarginBottomQuarter = css`
  margin-bottom: -${MARGIN_QUARTER_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: -${MARGIN_QUARTER_DESKTOP}rem;
  }
`;

const negativeVerticalMarginTopQuarter = css`
  margin-top: -${MARGIN_QUARTER_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: -${MARGIN_QUARTER_DESKTOP}rem;
  }
`;

const negativeVerticalMarginQuarter = css`
  ${negativeVerticalMarginBottomQuarter};
  ${negativeVerticalMarginTopQuarter};
`;

// NEGATIVE VERTICAL

const negativeVerticalMarginBottom = css`
  margin-bottom: -${MARGIN_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: -${MARGIN_DESKTOP}rem;
  }
`;

const negativeVerticalMarginTop = css`
  margin-top: -${MARGIN_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: -${MARGIN_DESKTOP}rem;
  }
`;

const negativeVerticalMargin = css`
  ${negativeVerticalMarginBottom};
  ${negativeVerticalMarginTop};
`;

// NEGATIVE VERTICAL DOUBLE

const negativeVerticalMarginBottomDouble = css`
  margin-bottom: -${MARGIN_DOUBLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: -${MARGIN_DOUBLE_DESKTOP}rem;
  }
`;

const negativeVerticalMarginTopDouble = css`
  margin-top: -${MARGIN_DOUBLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: -${MARGIN_DOUBLE_DESKTOP}rem;
  }
`;

const negativeVerticalMarginDouble = css`
  ${negativeVerticalMarginBottomDouble};
  ${negativeVerticalMarginTopDouble};
`;

// NEGATIVE VERTICAL QUADRUPLE

const negativeVerticalMarginBottomQuadruple = css`
  margin-bottom: -${MARGIN_QUADRUPLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-bottom: -${MARGIN_QUADRUPLE_DESKTOP}rem;
  }
`;

const negativeVerticalMarginTopQuadruple = css`
  margin-top: -${MARGIN_QUADRUPLE_MOBILE}rem;
  ${media.tablet.small} {
    margin-top: -${MARGIN_QUADRUPLE_DESKTOP}rem;
  }
`;

const negativeVerticalMarginQuadruple = css`
  ${negativeVerticalMarginBottomQuadruple};
  ${negativeVerticalMarginTopQuadruple};
`;

const margin = {
  horizontal: {
    both: horizontalMargin,
    bothDouble: horizontalMarginDouble,
    bothHalf: horizontalMarginHalf,
    bothQuadruple: horizontalMarginQuadruple,
    bothQuarter: horizontalMarginQuarter,
    left: horizontalMarginLeft,
    leftDouble: horizontalMarginLeftDouble,
    leftHalf: horizontalMarginLeftHalf,
    leftNone: horizontalMarginLeftNone,
    leftQuadruple: horizontalMarginLeftQuadruple,
    leftQuarter: horizontalMarginLeftQuarter,
    none: horizontalMarginNone,
    right: horizontalMarginRight,
    rightDouble: horizontalMarginRightDouble,
    rightHalf: horizontalMarginRightHalf,
    rightNone: horizontalMarginRightNone,
    rightQuadruple: horizontalMarginRightQuadruple,
    rightQuarter: horizontalMarginRightQuarter,
  },
  vertical: {
    both: verticalMargin,
    bothDouble: verticalMarginDouble,
    bothHalf: verticalMarginHalf,
    bothQuadruple: verticalMarginQuadruple,
    bothQuarter: verticalMarginQuarter,
    bottom: verticalMarginBottom,
    bottomDouble: verticalMarginBottomDouble,
    bottomHalf: verticalMarginBottomHalf,
    bottomNone: verticalMarginBottomNone,
    bottomQuadruple: verticalMarginBottomQuadruple,
    bottomQuarter: verticalMarginBottomQuarter,
    none: verticalMarginNone,
    top: verticalMarginTop,
    topDouble: verticalMarginTopDouble,
    topHalf: verticalMarginTopHalf,
    topNone: verticalMarginTopNone,
    topQuadruple: verticalMarginTopQuadruple,
    topQuarter: verticalMarginTopQuarter,
  },
  negativeHorizontal: {
    both: negativeHorizontalMargin,
    bothDouble: negativeHorizontalMarginDouble,
    bothHalf: negativeHorizontalMarginHalf,
    bothQuadruple: negativeHorizontalMarginQuadruple,
    bothQuarter: negativeHorizontalMarginQuarter,
    left: negativeHorizontalMarginLeft,
    leftDouble: negativeHorizontalMarginLeftDouble,
    leftHalf: negativeHorizontalMarginLeftHalf,
    leftQuadruple: negativeHorizontalMarginLeftQuadruple,
    leftQuarter: negativeHorizontalMarginLeftQuarter,
    right: negativeHorizontalMarginRight,
    rightDouble: negativeHorizontalMarginRightDouble,
    rightHalf: negativeHorizontalMarginRightHalf,
    rightQuadruple: negativeHorizontalMarginRightQuadruple,
    rightQuarter: negativeHorizontalMarginRightQuarter,
  },
  negativeVertical: {
    both: negativeVerticalMargin,
    bothDouble: negativeVerticalMarginDouble,
    bothHalf: negativeVerticalMarginHalf,
    bothQuadruple: negativeVerticalMarginQuadruple,
    bothQuarter: negativeVerticalMarginQuarter,
    bottom: negativeVerticalMarginBottom,
    bottomDouble: negativeVerticalMarginBottomDouble,
    bottomHalf: negativeVerticalMarginBottomHalf,
    bottomQuadruple: negativeVerticalMarginBottomQuadruple,
    bottomQuarter: negativeVerticalMarginBottomQuarter,
    top: negativeVerticalMarginTop,
    topDouble: negativeVerticalMarginTopDouble,
    topHalf: negativeVerticalMarginTopHalf,
    topQuadruple: negativeVerticalMarginTopQuadruple,
    topQuarter: negativeVerticalMarginTopQuarter,
  },
};

export default margin;
