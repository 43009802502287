import React from 'react';

import * as S from './styles';
import { getAwardName, getAwardUrl } from './util';

// Types
import { CMS_ENUM_RUG_AWARD } from '../../../../../types/global';
import { HTMLAttributes } from 'react';

type Props = {
  award?: CMS_ENUM_RUG_AWARD | null;
} & HTMLAttributes<HTMLImageElement>;

const RugAward = ({ award, ...rest }: Props) => {
  const awardName = getAwardName(award);
  const awardUrl = getAwardUrl(award);

  if (!award || !awardName || !awardUrl) {
    return null;
  }

  return <S.Award alt={awardName} src={awardUrl} {...rest} />;
};

export default RugAward;
