import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styleUtils from '../../../utils/styling/styleUtils';
import styled from '../../../utils/styling/styled';

export const ActualCheckbox = styled.input`
  display: none;
`;

export const Label = styled.span`
  ${styleUtils.margin.horizontal.leftQuarter};

  color: ${props => props.theme.color.white};
`;

type CheckboxProps = {
  isChecked: boolean;
};

export const Checkbox = styled.label<CheckboxProps>`
  /* Flex Container */
  align-items: center;
  display: flex;
  justify-content: center;

  background-color: ${props => props.theme.color.white};
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
`;

export const Checkmark = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color.brand};
`;

export const Wrapper = styled.div`
  ${styleUtils.padding.vertical.bothHalf};

  /* Flex Container */
  display: flex;
  flex-direction: row;
`;
