import React from 'react';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const Logo = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162.494 161.811" {...props}>
      <path d="M61.487 41.137l-5.334 19.078h11.232l-5.334-19.078h-.564zm8.435 46l-5.334 19.078H75.82l-5.334-19.078h-.564zM0 0v161.811h162.494V0H0zm116.203 49.262h17.732v17.733h-17.732V49.262zM25.021 71.037l3.077-2c1.18 1.898 3.231 3.283 5.539 3.283 2.514 0 4.514-1.231 4.514-5.488V38.264h4.104v27.491c0 6.976-2.975 10.052-8.515 10.052-4.307 0-6.769-2.256-8.719-4.77zm23.668 50.257L37.663 101.6l-6.001 7.283v12.411h-4.104v-37.03h4.104v19.233h.103L47.1 84.264h4.873l-11.49 13.951 12.874 23.079h-4.668zm2.951-45.999h-4.309l11.387-37.031h6.104l11.386 37.031h-4.309l-3.487-11.592H55.127L51.64 75.295zm28.693 45.999l-3.487-11.591H63.562l-3.487 11.591h-4.309l11.387-37.03h6.104l11.385 37.03h-4.309zm27.207-33.542H96.462v33.542h-4.103V87.752h-11.08v-3.488h26.261v3.488zm.104-12.457h-3.385l-18.771-33.03h-.103v33.03h-3.691V38.264h5.896l16.155 28.518h.206V38.264h3.692v37.031zm29.952 45.999h-4.104v-17.643h-17.745v17.643h-4.104v-37.03h4.104v15.9h17.745v-15.9h4.104v37.03z" />
    </svg>
  );
};

export default Logo;
