import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLParagraphElement>;

const StaticPageText = ({ children, ...rest }: Props) => {
  return <S.Text {...rest}>{children}</S.Text>;
};

export default StaticPageText;
