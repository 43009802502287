import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';

export const Section = styled(ScrollSection)`
  /* Flex Container */
  flex-direction: column;

  position: relative;
  /* We got some white artifacts in Chrome when automatically scrolling the carousel, this seems to fix it. */
  /* See: https://stackoverflow.com/a/17411191 */
  transform: translate3d(0, 0, 0);

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.specificity.increaseSpecificity} {
      padding-left: 0 !important;
      padding-right: 0 !important;
      flex-basis: ${props => props.theme.section.carousel.landscape.width}px;
      height: 100%;
      max-height: 100%;
      min-width: ${props => props.theme.section.carousel.landscape.width}px;
      width: ${props => props.theme.section.carousel.landscape.width}px;
    }
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.specificity.increaseSpecificity} {
      flex-basis: ${props => props.theme.section.carousel.portrait.height}px;
      height: ${props => props.theme.section.carousel.portrait.height}px;
      max-width: 100%;
      min-height: ${props => props.theme.section.carousel.portrait.height}px;
      width: 100vw;
    }
  }
`;
