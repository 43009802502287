import styled from '../../../../../utils/styling/styled';

import styleUtils from '../../../../../utils/styling/styleUtils';

export const Name = styled.span`
  ${styleUtils.font.weight.bold};

  color: white;
  font-size: 1.3rem;
  line-height: 1.2;
  padding: 1rem;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  width: 100%;
  word-break: keep-all;
  word-wrap: break-word;
`;
