import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const NavigationMenuCollectionGridItemGrid = ({ children, ...rest }: Props) => {
  return <S.Grid {...rest}>{children}</S.Grid>;
};

export default NavigationMenuCollectionGridItemGrid;
