import React from 'react';

import * as S from './styles';

type Props = {
  isMenuOpen: boolean;
};

const NavigationTriggerIe = ({ isMenuOpen }: Props) => {
  return (
    <S.Trigger isMenuOpen={isMenuOpen} preserveAspectRatio="none" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 25L0 50V0h50L25 25z" />
    </S.Trigger>
  );
};

export default NavigationTriggerIe;
