import React from 'react';

import NavigationMenuCollectionGridDesktop from './NavigationMenuCollectionGridDesktop';
import NavigationMenuCollectionGridMobile from './NavigationMenuCollectionGridMobile';

// Types
import { ReactNode } from 'react';

type Props = {
  collections: ReactNode;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
};

const NavigationMenuCollectionGrid = ({ collections, hasNextPage, hasPreviousPage, nextPage, previousPage }: Props) => {
  return (
    <>
      <NavigationMenuCollectionGridDesktop
        collections={collections}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />

      <NavigationMenuCollectionGridMobile
        collections={collections}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </>
  );
};

export default NavigationMenuCollectionGrid;
