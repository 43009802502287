import React from 'react';

import VisuallyHiddenSpan from '../../VisuallyHiddenSpan';
import routes from '../../../config/routes';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  backButtonTitle: string;
  backButtonUrl: string;
  isMenuOpen: boolean;
  isTitleHeaderVisible: boolean;
  logoColor?: string | null;
  navigation: ReactNode;
  titleHeaderSubtitle: string | null;
  titleHeaderTitle: string | null;
};

const Header = ({
  backButtonTitle,
  backButtonUrl,
  isMenuOpen,
  isTitleHeaderVisible,
  logoColor,
  navigation,
  titleHeaderSubtitle,
  titleHeaderTitle,
}: Props) => {
  return (
    // Overview pages are the collection overview pages or the search page.
    <S.Header isTitleHeaderVisible={isTitleHeaderVisible}>
      {isTitleHeaderVisible && (
        <S.StyledTitleHeader
          backButtonTitle={backButtonTitle}
          headerTitle={titleHeaderTitle}
          subtitle={titleHeaderSubtitle}
          url={backButtonUrl}
        />
      )}

      <S.LogoLink url={routes.home}>
        <VisuallyHiddenSpan>Back to the Homepage</VisuallyHiddenSpan>

        {/* The logo should be white (the default color) when the menu is open. */}
        <S.Logo logoColor={!isMenuOpen ? logoColor : undefined} />
      </S.LogoLink>

      {navigation}
    </S.Header>
  );
};

export default Header;
