import React from 'react';

import * as S from './styles';

// Types
import { InputHTMLAttributes } from 'react';

type Props = {} & InputHTMLAttributes<HTMLInputElement>;

const FormInput = (props: Props) => {
  return <S.Input {...props} />;
};

export default FormInput;
