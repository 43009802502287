import React from 'react';

import Link from '../../Link';
import envConfig from '../../../config/env';
import routes from '../../../config/routes';
import usePermanentToggle from '../../../utils/hooks/usePermanentToggle';
import useLocalStorage from '../../../utils/hooks/useLocalStorage';
import { config, useSpring } from '../../../utils/styling/spring';

import * as S from './styles';

type Props = {};

const PrivacyBanner = (_props: Props) => {
  const [isVisible, toggleIsVisible] = usePermanentToggle(true);
  // We want to fade the banner out before removing it from the DOM.
  // So we toggle the opacity through `isVisible` and the DOM on `isBannerClosed`.
  // This also helps with saving whether the banner should be initially visible or not in local storage.
  const [isBannerClosed, setIsBannerClosed] = useLocalStorage(envConfig.lsKeyPrivacyBannerClosed, false);

  const permanentlyToggleIsBannerClosed = () => {
    if (!isBannerClosed) {
      setIsBannerClosed(true);
    }
  };

  const handleVisibilityAnimationEnd = () => {
    if (!isVisible) {
      permanentlyToggleIsBannerClosed();
    }
  };

  const bannerSpring = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: !isVisible ? 0 : 1 },
    onRest: handleVisibilityAnimationEnd,
  });

  if (isBannerClosed) {
    return null;
  }

  return (
    <S.Wrapper style={bannerSpring}>
      <S.Text>
        By using the jan-kath.com website, you agree to the use of cookies by us and third parties to enhance your web experience. Please
        check our <Link url={routes.privacyPolicyDe}>Privacy Policy</Link> to alter your cookie settings or for more information.
      </S.Text>

      <S.Button onClick={toggleIsVisible} type="button">
        Ok
      </S.Button>
    </S.Wrapper>
  );
};

export default PrivacyBanner;
