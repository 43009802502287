import { Accordion, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import styled from '../../../../../utils/styling/styled';

export const SubMenu = styled(Accordion)`
  * [hidden] {
    display: block;
  }
`;

export const SubMenuButton = styled(AccordionItemButton)`
  /* Flex Container */
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:focus {
    outline: none;
  }
`;

export const SubMenuContent = styled(AccordionItemPanel)``;
