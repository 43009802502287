import React from 'react';
import useMeasure from 'use-measure';
import { AccordionItem, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';

import NavigationMenuItemList from '../NavigationMenuItems/NavigationMenuItemList';
import NavigationMenuItemListItem from '../NavigationMenuItems/NavigationMenuItemListItem';
import NavigationMenuMenuExpandIcon from './NavigationMenuMenuExpandIcon/NavigationMenuMenuExpandIcon';
import useToggle from '../../../../../utils/hooks/useToggle';
import { animated, useSpring } from '../../../../../utils/styling/spring';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  title: string;
};

const NavigationMenuSubMenu = ({ children, title }: Props) => {
  const ref = React.useRef(null);
  const measurement = useMeasure(ref);
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  const sectionSpring = useSpring({
    from: { height: 0, overflow: 'hidden' },
    to: { height: isExpanded ? measurement.height : 0, overflow: 'hidden' },
  });

  return (
    <NavigationMenuItemListItem isSubMenuExpanded={isExpanded}>
      <S.SubMenu allowZeroExpanded={true} onChange={toggleIsExpanded}>
        <AccordionItem>
          <AccordionItemHeading>
            <S.SubMenuButton>
              <span>{title}</span>

              <NavigationMenuMenuExpandIcon isExpanded={isExpanded} />
            </S.SubMenuButton>
          </AccordionItemHeading>

          <animated.div style={sectionSpring}>
            <div ref={ref}>
              <AccordionItemPanel>
                <NavigationMenuItemList>{children}</NavigationMenuItemList>
              </AccordionItemPanel>
            </div>
          </animated.div>
        </AccordionItem>
      </S.SubMenu>
    </NavigationMenuItemListItem>
  );
};

export default NavigationMenuSubMenu;
