import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Link from '../../../../../Link';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  hasLargePadding?: boolean;
  isExternal?: boolean;
  isLevel2?: boolean;
  isSubMenuExpanded?: boolean;
  url?: string;
} & HTMLAttributes<HTMLLIElement>;

const NavigationMenuItemListItem = ({ children, hasLargePadding = false, isExternal=false, isLevel2 = false, isSubMenuExpanded, url, ...rest }: Props) => {
  return (
    <S.ListItem hasLargePadding={hasLargePadding} isLevel2={isLevel2} isSubMenuExpanded={isSubMenuExpanded} {...rest}>
      <Link url={url} target={isExternal ? '_blank' : undefined} rel={isExternal ? 'noopener noreferrer' : undefined}>
        {isLevel2 && (
          <span>
            <S.ChevronRight icon={faChevronRight} size="sm" />
          </span>
        )}

        {children}
      </Link>
    </S.ListItem>
  );
};

export default NavigationMenuItemListItem;
