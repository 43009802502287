import React from 'react';

import SectionCarouselItemName from './SectionCarouselItemName';

import * as S from './styles';

// Types
import { FluidImage } from '../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { HTMLAttributes } from 'react';

type Props = {
  name: string;
  picture: FluidImage;
  url: string;
} & HTMLAttributes<HTMLAnchorElement>;

const SectionCarouselItem = ({ name, picture, url, ...rest }: Props) => {
  return (
    <S.Link url={url} {...rest}>
      <S.Cover backgroundImage={picture} critical={true} fadeIn="soft">
        <SectionCarouselItemName id="name">{name}</SectionCarouselItemName>

        <S.Shadow id="shadow" />
      </S.Cover>
    </S.Link>
  );
};

export default SectionCarouselItem;
