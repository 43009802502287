import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import NavigationMenuItemListItem from '../../../../../components/Layout/Navigation/NavigationMenu/NavigationMenuItems/NavigationMenuItemListItem';
import buildShowroomPageUrl from '../../../../../utils/url/buildShowroomPageUrl';

// Types
import { NavigationMenuShowroomListItemFragment } from './__generated__/NavigationMenuShowroomListItemFragment';

export const fragment = graphql`
  fragment NavigationMenuShowroomListItemFragment on CMS_Showroom {
    name
    nameInNavigation
    showroomPage {
      slug
    }
  }
`;

type Props = {
  showroom: NavigationMenuShowroomListItemFragment;
};

const NavigationMenuShowroomListItemContainer = ({ showroom }: Props) => {
  const slug = idx(showroom, _ => _.showroomPage.slug);

  if (!slug) {
    return null;
  }

  const url = buildShowroomPageUrl(slug);

  return (
    <NavigationMenuItemListItem isLevel2={true} url={url}>
      {showroom.nameInNavigation || showroom.name}
    </NavigationMenuItemListItem>
  );
};

export default NavigationMenuShowroomListItemContainer;
