import styled from '../../../../../utils/styling/styled';

import styleUtils from '../../../../../utils/styling/styleUtils';

export const ListItem = styled.li`
  ${styleUtils.animation.navigationListItem};

  border-top: 0.5px solid currentColor;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  &:first-of-type {
    border-top: 0;
  }
`;

export const NewsletterButton = styled.div`
  /* Flex Container */
  display: flex;
  flex-direction: row;

  cursor: pointer;
  font-size: 0.9rem;
  overflow: hidden;
  text-transform: uppercase;
`;
