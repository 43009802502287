import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

type TriggerProps = {
  isMenuOpen: boolean;
};

export const Trigger = styled.svg<TriggerProps>`
  left: 0;
  min-height: ${props => (props.isMenuOpen ? '200vh' : 0)};
  min-width: ${props => (props.isMenuOpen ? '200vw' : 0)};
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 0;
  z-index: 20;

  path {
    fill: ${props => props.theme.color.brand};
  }

  display: none;

  ${styleUtils.media.ie11} {
    display: block;
  }
`;
