import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLSpanElement>;

const CollectionCoverName = ({ children, ...rest }: Props) => {
  return <S.Name {...rest}>{children}</S.Name>;
};

export default CollectionCoverName;
