import React from 'react';

import NavigationMenuButton from './NavigationMenuButton';
import NavigationMenuButtonIe from './NavigationMenuButtonIe';
import NavigationTrigger from './NavigationTrigger';
import NavigationTriggerIe from './NavigationTriggerIe';

// Types
import { ReactNode } from 'react';

type Props = {
  isMenuButtonHovering: boolean;
  isMenuOpen: boolean;
  navigationMenu: ReactNode;
  onMenuButtonClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const Navigation = ({ isMenuOpen, isMenuButtonHovering, navigationMenu, onMenuButtonClick, onMouseEnter, onMouseLeave }: Props) => {
  return (
    <>
      <NavigationTrigger isMenuButtonHovering={isMenuButtonHovering} isMenuOpen={isMenuOpen} />
      <NavigationTriggerIe isMenuOpen={isMenuOpen} />

      <NavigationMenuButton
        isMenuButtonHovering={isMenuButtonHovering}
        isMenuOpen={isMenuOpen}
        onMenuButtonClick={onMenuButtonClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />

      <NavigationMenuButtonIe onMenuButtonClick={onMenuButtonClick} />

      {navigationMenu}
    </>
  );
};

export default Navigation;
