import React from 'react';
import { Global } from '@emotion/core';

import CollectionOverviewButton from '../Collection/CollectionPage/CollectionOverviewButton';
import IEBanner from './IEBanner';
import PrivacyBanner from './PrivacyBanner';
import Tutorial from './Tutorial';
import globalStyles from '../../utils/styling/globalStyles';
import useHorizontalScroll from '../../utils/hooks/useHorizontalScroll';
import useIsMounted from '../../utils/hooks/useIsMounted';
import useSticky from '../../utils/hooks/useSticky';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  backgroundColor?: string;
  children: ReactNode;
  collectionOverviewUrl?: string;
  header: ReactNode;
  isMenuOpen: boolean;
  seo: ReactNode;
};

// TODO: Background color also to body
const Layout = ({ backgroundColor, children, collectionOverviewUrl, header, isMenuOpen, seo }: Props) => {
  const isMounted = useIsMounted();
  const scrollRef = useHorizontalScroll<HTMLDivElement>();
  const { marginBottom, marginRight } = useSticky({ containerRef: scrollRef, isDisabled: !collectionOverviewUrl });

  return (
    <>
      <Global styles={globalStyles} />

      {seo}

      {header}

      {isMounted() && (
        <>
          <Tutorial />

          <IEBanner />

          <S.BannersWrapper>
            <PrivacyBanner />
          </S.BannersWrapper>
        </>
      )}

      <S.ScrollContainer backgroundColor={backgroundColor} isMenuOpen={isMenuOpen} ref={scrollRef}>
        {!!collectionOverviewUrl && (
          <CollectionOverviewButton marginBottom={marginBottom} marginRight={marginRight} url={collectionOverviewUrl} />
        )}

        {children}
      </S.ScrollContainer>
    </>
  );
};

export default Layout;
