import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../utils/styling/styled';

import LocalLink from '../../../Link';
import styleUtils from '../../../../utils/styling/styleUtils';
import css from '@emotion/css';

export const ChevronRight = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const Link = styled(LocalLink)`
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;

type ListItemProps = {
  hasExtraTopMargin?: boolean;
};

export const ListItem = styled.li<ListItemProps>`
  ${styleUtils.animation.navigationListItem};
  ${styleUtils.font.weight.bold};

  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  ${props =>
    props.hasExtraTopMargin &&
    css`
      margin-top: 1.5rem;
    `}

  ${styleUtils.media.desktop.xsmall} {
    margin-bottom: 0.2rem;
    margin-top: 0.2rem;
  }

  ${styleUtils.media.desktop.small} {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
`;
