import React from 'react';
import { faApple, faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';

import * as S from './styles';

type Props = {
  appStoreUrl: string | null;
  facebookUrl: string | null;
  instagramUrl: string | null;
};

const NavigationMenuSocial = ({ appStoreUrl, facebookUrl, instagramUrl }: Props) => {
  return (
    <S.List>
      {!!facebookUrl && (
        <S.ListItem isExternal={true} url={facebookUrl}>
          {
            // @ts-ignore
            <S.Icon icon={faFacebookSquare} />
          }
          Follow us on Facebook
        </S.ListItem>
      )}

      {!!instagramUrl && (
        <S.ListItem isExternal={true} hasBorderTop={true} url={instagramUrl}>
          {
            // @ts-ignore
            <S.Icon icon={faInstagram} />
          }
          Follow us on Instagram
        </S.ListItem>
      )}

      {!!appStoreUrl && (
        <S.ListItemApp hasBorderTop={true} url={appStoreUrl}>
          {
            // @ts-ignore
            <S.Icon icon={faApple} />
          }
          Virtual Experience
        </S.ListItemApp>
      )}
    </S.List>
  );
};

export default NavigationMenuSocial;
