import css from '@emotion/css';

import styled from '../../../utils/styling/styled';

import styleUtils from '../../../utils/styling/styleUtils';
import { animated } from '../../../utils/styling/spring';

type ButtonProps = {
  direction: 'down' | 'left' | 'right' | 'up';
};

export const Button = styled(animated.button)<ButtonProps>`
  ${styleUtils.font.weight.bold};
  ${styleUtils.padding.vertical.bothQuarter};
  ${styleUtils.animation.showOnHover};

  /* Try to always show the buttons if we're on a touch device (and such don't hover). */
  ${styleUtils.media.touchDevice} {
    opacity: 1;
  }

  background-color: ${props => props.theme.color.black80};
  color: white;
  position: absolute;
  z-index: 2;

  ${styleUtils.media.orientation.landscape} {
    ${props =>
      props.direction === 'left' &&
      css`
        display: none;
      `};
      
      ${props =>
        props.direction === 'right' &&
        css`
          display: none;
        `};

    width: 100%;
  };

  ${styleUtils.media.orientation.portrait} {
    ${props =>
      props.direction === 'down' &&
      css`
        display: none;
      `};
      
      ${props =>
        props.direction === 'up' &&
        css`
          display: none;
        `};

    height: ${props => props.theme.section.carousel.portrait.height}px;
    width: 52px;
  };

${props =>
  props.direction === 'down' &&
  css`
    bottom: 0;
  `};

${props =>
  props.direction === 'left' &&
  css`
    left: 0;
    top: 0;
  `};

${props =>
  props.direction === 'right' &&
  css`
    right: 0;
    top: 0;
  `};

  ${props =>
    props.direction === 'up' &&
    css`
      top: 0;
    `}

  };

    &:focus {
    outline: 0;
`;
