import React from 'react';
import { useFormik } from 'formik';

import FormCheckbox from '../../../../../Form/FormCheckbox';
import FormInput from '../../../../../Form/FormInput';
import NewsletterSubscribeButton from '../../../NewsletterSubscribeButton';
import Portal from '../../../../../Portal';
import routes from '../../../../../../config/routes';
import useToggle from '../../../../../../utils/hooks/useToggle';

import * as S from './styles';

type Props = {
  onClose: () => void;
};

const SectionItemFooterSocialListItemNewsletterModal = ({ onClose }: Props) => {
  const { dirty, handleBlur, handleChange, handleSubmit, values } = useFormik({
    initialValues: { familyName: '', givenName: '', email: '', company: '' },
    onSubmit: (filledOutForm: any) => {
      console.log(filledOutForm);
    },
  });

  const [isChecked, toggleIsChecked] = useToggle(false);

  return (
    <Portal>
      <S.Wrapper>
        <S.Modal>
          <S.CloseButton onClick={onClose} title="Close">
            <S.CloseButtonIcon />
          </S.CloseButton>

          <S.Headline> Newsletter Subscription</S.Headline>

          <S.Form onSubmit={handleSubmit}>
            <S.Text>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
              aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
              takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
              eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
              et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </S.Text>

            <FormInput
              name="givenName"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Given Name *"
              required={true}
              type="text"
              value={values.givenName}
            />

            <FormInput
              name="familyName"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Family Name *"
              required={true}
              type="text"
              value={values.familyName}
            />

            <FormInput
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Email *"
              required={true}
              type="email"
              value={values.email}
            />

            <FormInput
              name="company"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Company (optional)"
              type="text"
              value={values.company}
            />

            <FormCheckbox
              isChecked={isChecked}
              label={
                <>
                  I agree to&nbsp;
                  <S.StyledLink asExternal={true} url={routes.privacyPolicyDe}>
                    Datenschutz
                  </S.StyledLink>
                </>
              }
              onClick={toggleIsChecked}
              title="You have to agree to the privacy policy to sign up"
            />

            <NewsletterSubscribeButton buttonText="Subscribe" disabled={!isChecked} />
          </S.Form>
        </S.Modal>
      </S.Wrapper>

      <S.Background onClick={!dirty ? onClose : undefined} />
    </Portal>
  );
};

export default SectionItemFooterSocialListItemNewsletterModal;
