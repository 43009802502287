import React from 'react';

import envConfig from '../../config/env';
import isPortrait from '../styling/isPortrait';
import useInterval from './useInterval';

// Types
import { RefObject } from 'react';

enum IntervalScrollDirection {
  Down,
  Up,
}

// Currently, it only appends new items when scrolling down/right.
// This is not the cleanest implementation (we're just appending new items instead of removing unused ones)
// but that is fine for this use case.

type UseCarouselInput = {
  allSlides: any[];
  ref: RefObject<HTMLDivElement>;
};

const useCarousel = <T extends HTMLElement>({ allSlides, ref }: UseCarouselInput) => {
  const [intervalScrollCount, setIntervalScrollCount] = React.useState<number>(0);
  const [intervalScrollDirection, setIntervalScrollDirection] = React.useState<IntervalScrollDirection>(IntervalScrollDirection.Down);

  const [currentSlides, setCurrentSlides] = React.useState(allSlides);
  const [shouldAutoScroll, setShouldAutoScroll] = React.useState<boolean>(true);

  const handleScrollDown = () => {
    if (!!ref.current) {
      // `clientHeight`: How big is the container.
      // `scrollHeight`: How big would the container be, if we were not hiding the overflow.
      // `scrollTop`: How much is the container scrolled from the top
      const { clientHeight, scrollHeight, scrollTop } = ref.current;

      // How high is one item.
      const itemHeight = scrollHeight / currentSlides.length;
      // How much height have we left before we approach the very bottom.
      const heightLeft = scrollHeight - scrollTop - clientHeight;

      // Do we have more space left then 3 items can fit?
      const enoughSpace = heightLeft > itemHeight * 3;

      // If not, we need to append the array again at the end of the array.
      if (!enoughSpace) {
        setCurrentSlides(prev => [...prev, ...allSlides]);
      }

      ref.current.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: scrollTop + itemHeight / 2 + itemHeight / 3,
      });
    }
  };

  const handleScrollLeft = () => {
    if (!!ref.current) {
      // `scrollLeft`: How much is the container scrolled from the left
      // `scrollWidth`: How big is the container.
      const { scrollLeft, scrollWidth } = ref.current;

      // How wide is one item.
      const itemWidth = scrollWidth / currentSlides.length;

      ref.current.scrollTo({
        behavior: 'smooth',
        left: Math.floor(Math.max(scrollLeft - (itemWidth / 2 + itemWidth / 3), 0)),
        top: 0,
      });
    }
  };

  const handleScrollRight = () => {
    if (!!ref.current) {
      // `clientWidth`: How big is the container.
      // `scrollLeft`: How much is the container scrolled from the left
      // `scrollWidth`: How big would the container be, if we were not hiding the overflow.
      const { clientWidth, scrollWidth, scrollLeft } = ref.current;

      // How wide is one item.
      const itemWidth = scrollWidth / currentSlides.length;
      // How much height have we left before we approach the very right.
      const heightLeft = scrollWidth - scrollLeft - clientWidth;

      // Do we have more space left then 3 items can fit?
      const enoughSpace = heightLeft > itemWidth * 3;

      // If not, we need to append the array again at the end of the array.
      if (!enoughSpace) {
        setCurrentSlides(prev => [...prev, ...allSlides]);
      }

      ref.current.scrollTo({
        behavior: 'smooth',
        left: scrollLeft + itemWidth / 2 + itemWidth / 3,
        top: 0,
      });
    }
  };

  const handleScrollUp = () => {
    if (!!ref.current) {
      // `scrollHeight`: How big is the container.
      // `scrollTop`: How much is the container scrolled from the top
      const { scrollHeight, scrollTop } = ref.current;

      // How high is one item.
      const itemHeight = scrollHeight / currentSlides.length;

      ref.current.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: Math.max(scrollTop - (itemHeight / 2 + itemHeight / 3), 0),
      });
    }
  };

  const handleScrollPrevious = () => {
    if (isPortrait()) {
      handleScrollLeft();
    } else {
      handleScrollUp();
    }
  };

  const handleScrollNext = () => {
    if (isPortrait()) {
      handleScrollRight();
    } else {
      handleScrollDown();
    }
  };

  const handleIntervalScroll = () => {
    if (shouldAutoScroll) {
      if (intervalScrollDirection === IntervalScrollDirection.Down) {
        handleScrollNext();
        setIntervalScrollCount(prev => prev + 1);

        if (intervalScrollCount >= 5) {
          setIntervalScrollCount(0);
          setIntervalScrollDirection(IntervalScrollDirection.Up);
        }

        return;
      }

      if (intervalScrollDirection === IntervalScrollDirection.Up) {
        handleScrollPrevious();
        setIntervalScrollCount(prev => prev + 1);

        if (intervalScrollCount >= 5) {
          setIntervalScrollCount(0);
          setIntervalScrollDirection(IntervalScrollDirection.Down);
        }
      }
    }
  };

  useInterval(handleIntervalScroll, shouldAutoScroll ? envConfig.carouselScrollInterval : null);

  return {
    ref,
    currentSlides,
    handleScrollDown,
    handleScrollUp,
    handleScrollPrevious,
    handleScrollNext,
    setShouldAutoScroll,
  };
};

export default useCarousel;
