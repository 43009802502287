import React from 'react';

import * as S from './styles';

// Types
import { FluidImage } from '../../../../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  coverImage: FluidImage;
  name: string;
  onNavigation: () => void;
  slug: string;
};

const NavigationMenuCollectionGridItemCollection = ({ coverImage, name, onNavigation, slug }: Props) => {
  return <S.Collection coverImage={coverImage} name={name} onClick={onNavigation} slug={slug} />;
};

export default NavigationMenuCollectionGridItemCollection;
