import React from 'react';

import * as S from './styles';

// Types
import { GatsbyImageProps } from 'gatsby-image';
import { FluidImage } from '../GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  caption?: string | null;
  image: FluidImage;
  // This is for images that should given a height and scale their width accordingly,
  // `gatsby-image` can't do that (yet?). So we hack it together.
  // We will set the width to `(aspectRatio * vh)vh`. This works for our usecases.
  vh?: number;
} & GatsbyImageProps;

const MediaImageFluid = ({ Tag = 'figure', alt, caption, image, imgStyle: incomingImgStyle = {}, title, vh, ...rest }: Props) => {
  const imgStyle = { ...incomingImgStyle, objectFit: 'contain' };

  return (
    <S.ImageFluid
      Tag={Tag}
      alt={alt || title || caption || undefined}
      // @ts-ignore
      fluid={image}
      imgStyle={imgStyle}
      vh={vh}
      {...rest}
    />
  );
};

export default MediaImageFluid;
