import styled from '../../../../../../utils/styling/styled';

import Link from '../../../../../Link';
import StaticPageHeadline from '../../../../../StaticPageItems/StaticPageHeadline';
import UnstyledCloseButton from '../../../../../SVG/CloseButton';
import styleUtils from '../../../../../../utils/styling/styleUtils';

export const Background = styled.div`
  background-color: ${props => props.theme.color.black};
  height: 100vh;
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10;
`;

export const CloseButtonIcon = styled(UnstyledCloseButton)`
  ${styleUtils.font.weight.bold};

  fill: ${props => props.theme.color.white};
  height: 1.5rem;
`;

export const CloseButton = styled.button`
  ${styleUtils.animation.buttons};
  ${styleUtils.padding.horizontal.leftQuarter};
  ${styleUtils.padding.vertical.topQuarter};

  /* Flex Container */
  display: flex;
  left: 0;
  position: absolute;
  top: 0;
`;

export const Headline = styled(StaticPageHeadline)`
  color: ${props => props.theme.color.white};
  text-align: center;
`;

export const Form = styled.form`
  ${styleUtils.padding.horizontal.bothHalf};
  ${styleUtils.padding.vertical.bothHalf};

  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const Modal = styled.div`
  ${styleUtils.padding.horizontal.both};
  ${styleUtils.padding.vertical.both};

  /* Flex Container */
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.color.brand};
  margin: auto;
  max-height: 80vh;
  max-width: 1200px;
  overflow: auto;
  pointer-events: all;
  position: relative;
  z-index: 1000;

  width: 90vw;

  ${styleUtils.media.tablet.small} {
    width: 90vw;
  }

  ${styleUtils.media.desktop.small} {
    width: 80vw;
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

export const Text = styled.p`
  color: ${props => props.theme.color.white};
  text-align: justify;
  text-justify: inter-word;
`;

export const Wrapper = styled.div`
  /* Flex Container */
  display: flex;
  flex-direction: column;

  height: 100vh;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 15;
`;
