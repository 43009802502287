type BuildAddressRow1Input = {
  street: string | null;
  streetNumber: string | null;
};

export const buildAddressRow1 = ({ street, streetNumber }: BuildAddressRow1Input) => {
  if (!street) {
    return null;
  }

  return `${street} ${streetNumber || ''}`.trim();
};

type BuildAddressRow2Input = {
  city: string | null;
  country: string | null;
  postalCode: string | null;
};

export const buildAddressRow2 = ({ city, country, postalCode }: BuildAddressRow2Input) => {
  if (!city && !country && !postalCode) {
    return null;
  }

  return `${postalCode || ''} ${city || ''} ${country ? `· ${country}` : ''}`.trim();
};
