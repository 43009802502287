/* eslint-disable no-underscore-dangle */
import React from 'react';

// Types
import { CMS_ENUM_RUG_AWARD } from '../../../types/global';
import { Dispatch, ReactNode } from 'react';
import { FluidImage } from '../../components/MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type RugSearchResult = {
  __typename: 'CMS_Rug';
  award: CMS_ENUM_RUG_AWARD | null;
  collectionName: string | null;
  collectionSlug: string | null;
  color: string | null;
  created_at: string;
  extra: string | null;
  id: string;
  isVisibleOnWebsite: boolean;
  material: string | null;
  name: string;
  picture: {
    __typename: 'CMS_UploadFile';
    file: { __typename: 'File'; childImageSharp: { __typename: 'ImageSharp'; fluid: FluidImage | null } };
    name: string;
    url: string;
  } | null;
};

enum SearchContextActionType {
  SetSearchTerm = 'SET_SEARCH_TERM',
  SetSearchResults = 'SET_SEARCH_RESULTS',
}

type SearchContextAction =
  | { type: SearchContextActionType.SetSearchTerm; payload: string | null }
  | { type: SearchContextActionType.SetSearchResults; payload: RugSearchResult[] };

type State = {
  results: RugSearchResult[];
  term: string | null;
};

type Context = {
  dispatch: Dispatch<SearchContextAction> | null;
  state: State;
};

const initialState: State = {
  results: [] as RugSearchResult[],
  term: null,
};

const reducer = (state: State, action: SearchContextAction) => {
  switch (action.type) {
    case SearchContextActionType.SetSearchResults:
      return {
        ...state,
        results: action.payload,
      };

    case SearchContextActionType.SetSearchTerm:
      return {
        ...state,
        term: action.payload,
      };

    default:
      return state;
  }
};

const SearchContext = React.createContext<Context>({ state: initialState, dispatch: null });

type SearchContextProviderProps = {
  children: ReactNode;
};

export const SearchContextProvider = ({ children }: SearchContextProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <SearchContext.Provider value={{ dispatch, state }}>{children}</SearchContext.Provider>;
};

const useSearchContext = () => {
  const {
    dispatch,
    state: { results, term },
  } = React.useContext(SearchContext);

  const setSearchResults = (nextResults: RugSearchResult[]) => {
    if (!!dispatch) {
      dispatch({ type: SearchContextActionType.SetSearchResults, payload: nextResults });
    }
  };

  const setSearchTerm = (nextSearchTerm: string | null) => {
    if (!!dispatch) {
      dispatch({ type: SearchContextActionType.SetSearchTerm, payload: nextSearchTerm });
    }
  };

  return { results, setSearchResults, setSearchTerm, term };
};

export default useSearchContext;
