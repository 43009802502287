/**
 * This is a kind of hack, Emotion will pass through all props to the component,
 * which in turn will pass all props through to the DOM. We don't want that.
 * So we have to define the props we don't want to forward with this function.
 */
const createOmitProps = (propsToOmit: string | string[]) => (propName: string) => {
  if (!Array.isArray(propsToOmit)) {
    return propName !== propsToOmit;
  }

  return !propsToOmit.includes(propName);
};

export default createOmitProps;
