import React from 'react';
import idx from 'idx';
import { graphql, useStaticQuery } from 'gatsby';

import NavigationMenuSocial from '../../../../../components/Layout/Navigation/NavigationMenu/NavigationMenuSocial';

// Types
import { NavigationMenuSocialContainerQuery } from './__generated__/NavigationMenuSocialContainerQuery';

type Props = {};

const NavigationMenuSocialContainer = (_props: Props) => {
  const data = useStaticQuery<NavigationMenuSocialContainerQuery>(graphql`
    query NavigationMenuSocialContainerQuery {
      site {
        siteMetadata {
          socialMedia {
            appStore
            facebook
            instagram
          }
        }
      }
    }
  `);

  const appStoreUrl = idx(data, _ => _.site.siteMetadata.socialMedia.appStore) || null;
  const facebookUrl = idx(data, _ => _.site.siteMetadata.socialMedia.facebook) || null;
  const instagramUrl = idx(data, _ => _.site.siteMetadata.socialMedia.instagram) || null;

  return <NavigationMenuSocial appStoreUrl={appStoreUrl} facebookUrl={facebookUrl} instagramUrl={instagramUrl} />;
};

export default NavigationMenuSocialContainer;
