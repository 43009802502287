import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Heading from '../../../Heading';
import Link from '../../../Link';

import * as S from './styles';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  hasExtraTopMargin?: boolean;
  name: string;
  url: string;
} & HTMLAttributes<HTMLLIElement>;

const SectionFooterShowroomsShowroom = ({ hasExtraTopMargin, name, url, ...rest }: Props) => {
  return (
    <S.ListItem hasExtraTopMargin={hasExtraTopMargin} {...rest}>
      <Link url={url}>
        <Heading>
          <S.ChevronRight icon={faChevronRight} size="sm" />

          {name}
        </Heading>
      </Link>
    </S.ListItem>
  );
};

export default SectionFooterShowroomsShowroom;
