/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CMS_ENUM_RUG_AWARD {
  WALLPAPER_DESIGN_AWARD_2015_WINNER = "WALLPAPER_DESIGN_AWARD_2015_WINNER",
  WALLPAPER_DESIGN_AWARD_2017 = "WALLPAPER_DESIGN_AWARD_2017",
}

export enum CMS_ENUM_SECTION_BACKGROUNDPATTERN {
  CARDBOARD = "CARDBOARD",
  CARDBOARD_BLUE = "CARDBOARD_BLUE",
  CHROME = "CHROME",
  GOLD = "GOLD",
  SPACE = "SPACE",
  WALL = "WALL",
}

export enum CMS_ENUM_SECTION_CONTENTPOSITIONX {
  ELEMENT_POSITION_X_CENTER = "ELEMENT_POSITION_X_CENTER",
  ELEMENT_POSITION_X_LEFT = "ELEMENT_POSITION_X_LEFT",
  ELEMENT_POSITION_X_RIGHT = "ELEMENT_POSITION_X_RIGHT",
}

export enum CMS_ENUM_SECTION_CONTENTPOSITIONY {
  ELEMENT_POSITION_Y_BOTTOM = "ELEMENT_POSITION_Y_BOTTOM",
  ELEMENT_POSITION_Y_MIDDLE = "ELEMENT_POSITION_Y_MIDDLE",
  ELEMENT_POSITION_Y_TOP = "ELEMENT_POSITION_Y_TOP",
}

export enum CMS_ENUM_SECTION_TEXTALIGN {
  TEXT_ALIGN_CENTER = "TEXT_ALIGN_CENTER",
  TEXT_ALIGN_JUSTIFIED = "TEXT_ALIGN_JUSTIFIED",
  TEXT_ALIGN_LEFT = "TEXT_ALIGN_LEFT",
  TEXT_ALIGN_RIGHT = "TEXT_ALIGN_RIGHT",
}

export enum CMS_ENUM_SECTION_TYPE {
  CAROUSEL_COLLECTIONS = "CAROUSEL_COLLECTIONS",
  CAROUSEL_SHOWROOMS = "CAROUSEL_SHOWROOMS",
  COLLECTION_TEXT_INTRO = "COLLECTION_TEXT_INTRO",
  CONTENT_DEFAULT = "CONTENT_DEFAULT",
  CONTENT_FRAMED = "CONTENT_FRAMED",
  CONTENT_MAP = "CONTENT_MAP",
  EVENTS_CONTACT = "EVENTS_CONTACT",
  EVENTS_EVENTS = "EVENTS_EVENTS",
  FOOTER_CONTACT = "FOOTER_CONTACT",
  FOOTER_SHOWROOMS = "FOOTER_SHOWROOMS",
  FULL_SCREEN_WALKTHROUGH = "FULL_SCREEN_WALKTHROUGH",
  HOME_COLLECTION = "HOME_COLLECTION",
  HOME_CONTENT_PAGE_TEASER = "HOME_CONTENT_PAGE_TEASER",
  HOME_EVENT_TEASER = "HOME_EVENT_TEASER",
  HOME_SHOWROOMS = "HOME_SHOWROOMS",
  NEWSLETTER_SUBSCRIPTION_PRESS = "NEWSLETTER_SUBSCRIPTION_PRESS",
  PRESS_CONTACT = "PRESS_CONTACT",
  PRESS_DOWNLOAD_COMPANY_INFO = "PRESS_DOWNLOAD_COMPANY_INFO",
  PRODUCT = "PRODUCT",
  RUG_FULL_HEIGHT = "RUG_FULL_HEIGHT",
  RUG_LIST = "RUG_LIST",
  SHOWROOM_GLOBAL_DISTRIBUTION = "SHOWROOM_GLOBAL_DISTRIBUTION",
  SHOWROOM_INTRO = "SHOWROOM_INTRO",
  SHOWROOM_MAP = "SHOWROOM_MAP",
  STORE_LOCATOR = "STORE_LOCATOR",
  VIDEO_FULL_SCREEN = "VIDEO_FULL_SCREEN",
  VIDEO_FULL_SCREEN_VIMEO = "VIDEO_FULL_SCREEN_VIMEO",
}

export enum CMS_ENUM_SECTION_WIDTH {
  SECTION_WIDTH_100 = "SECTION_WIDTH_100",
  SECTION_WIDTH_25 = "SECTION_WIDTH_25",
  SECTION_WIDTH_50 = "SECTION_WIDTH_50",
  SECTION_WIDTH_75 = "SECTION_WIDTH_75",
}

export enum CMS_ENUM_SHOWROOM_REGION {
  AUSTRALIA = "AUSTRALIA",
  EASTERN_EUROPE = "EASTERN_EUROPE",
  EAST_ASIA = "EAST_ASIA",
  MIDDLE_EAST = "MIDDLE_EAST",
  NORTH_AMERICA = "NORTH_AMERICA",
  SOUTH_AMERICA = "SOUTH_AMERICA",
  WESTERN_EUROPE = "WESTERN_EUROPE",
}

export enum CMS_ENUM_SHOWROOM_TYPE {
  EXCLUSIVE_PARTNER = "EXCLUSIVE_PARTNER",
  SHOWROOM = "SHOWROOM",
  STORE = "STORE",
}

export enum CMS_ENUM_STORE_TYPE {
  DEALER = "DEALER",
  SHOWROOM_OR_STORE = "SHOWROOM_OR_STORE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
