import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';
import { animated } from '../../../../utils/styling/spring';

export const Trigger = styled(animated.svg)`
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 20;

  path {
    fill: ${props => props.theme.color.brand};
  }

  ${styleUtils.media.ie11} {
    display: none;
  }
`;
