import styled from '../../../../utils/styling/styled';

export const Button = styled.button`
  left: 0;
  margin-left: 1.2rem;
  margin-top: 1.2rem;
  pointer-events: all;
  position: fixed;
  top: 0;
  z-index: 25;
`;
