import React from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import VisuallyHiddenSpan from '../VisuallyHiddenSpan';

import * as S from './styles';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  url?: string | null;
} & HTMLAttributes<HTMLAnchorElement>;

const BackButton = ({ title, url, ...rest }: Props) => {
  return (
    <S.BackButton url={url} {...rest}>
      <VisuallyHiddenSpan>{title}</VisuallyHiddenSpan>

      <S.Chevron icon={faChevronLeft} />
    </S.BackButton>
  );
};

export default BackButton;
