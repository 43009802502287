import React from 'react';

import isArrowLeftOrUp from '../misc/isArrowLeftOrUp';
import isArrowRightOrDown from '../misc/isArrowRightOrDown';
import isLandscape from '../styling/isLandscape';
import isPortrait from '../styling/isPortrait';
import notNullOrUndefined from '../misc/notNullOrUndefined';
import { NO_SCROLL_ID } from '../../config/constants';

const MIN_SCROLL_DELTA_NEGATIVE = -150;
const MIN_SCROLL_DELTA_POSTIVE = 150;

const useHorizontalScroll = <T extends HTMLElement>() => {
  const ref = React.createRef<T>();

  React.useEffect(() => {
    const handleScroll = (wheelEvent: WheelEvent) => {
      if (isPortrait()) {
        return;
      }

      const target = wheelEvent.target;

      if (notNullOrUndefined(target)) {
        if (wheelEvent.metaKey) {
          return;
        }

        if ('id' in target) {
          if (target.id === NO_SCROLL_ID) {
            return;
          }
        }
      }

      if (!!ref.current) {
        if (typeof ref.current.scrollLeft === 'number' && typeof wheelEvent.deltaY === 'number') {
          if (wheelEvent.deltaX !== 0) {
            if (wheelEvent.deltaX <= 0) {
              ref.current.scrollLeft += Math.min(wheelEvent.deltaX, MIN_SCROLL_DELTA_NEGATIVE);
            } else {
              ref.current.scrollLeft += Math.max(wheelEvent.deltaX, MIN_SCROLL_DELTA_POSTIVE);
            }
          } else if (wheelEvent.deltaY <= 0) {
            ref.current.scrollLeft += Math.min(wheelEvent.deltaY, MIN_SCROLL_DELTA_NEGATIVE);
          } else {
            ref.current.scrollLeft += Math.max(wheelEvent.deltaY, MIN_SCROLL_DELTA_POSTIVE);
          }
        }
      }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (isArrowLeftOrUp(event)) {
        if (!!ref.current) {
          if (isLandscape()) {
            ref.current.scrollLeft += MIN_SCROLL_DELTA_NEGATIVE;
          } else {
            ref.current.scrollTop += MIN_SCROLL_DELTA_NEGATIVE;
          }
        }
      }

      if (isArrowRightOrDown(event)) {
        if (!!ref.current) {
          if (isLandscape()) {
            ref.current.scrollLeft += MIN_SCROLL_DELTA_POSTIVE;
          } else {
            ref.current.scrollTop += MIN_SCROLL_DELTA_POSTIVE;
          }
        }
      }
    };

    if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
      window.document.addEventListener('wheel', handleScroll);
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
        window.document.removeEventListener('wheel', handleScroll);
        window.removeEventListener('keydown', handleKeyPress);
      }
    };
  });

  return ref;
};

export default useHorizontalScroll;
