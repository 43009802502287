import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const NavigationMenuCollectionGridItemWrapper = React.forwardRef<HTMLDivElement, Props>(({ children, ...rest }: Props, ref) => {
  return (
    <S.Wrapper ref={ref} {...rest}>
      {children}
    </S.Wrapper>
  );
});

export default NavigationMenuCollectionGridItemWrapper;
