import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../utils/styling/styled';

import styleUtils from '../../utils/styling/styleUtils';

export const Header = styled.div`
  ${styleUtils.font.weight.bold};

  color: ${props => props.theme.color.black};
  font-size: 1.3rem;
  text-transform: uppercase;
`;

export const Chevron = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color.black};
`;

export const Title = styled.div`
  color: ${props => props.theme.color.black};
  font-size: 1.3rem;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.black80};
  min-height: 3.9rem;
  position: relative;
  z-index: 1;

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.horizontal.leftQuadruple};

    line-height: 1.1;
    min-width: 10%;
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.margin.vertical.bottom};
    ${styleUtils.padding.vertical.bottomHalf};

    /* Flex Container */
    display: flex;
    flex-direction: row;

    line-height: 1.2;
    width: 100%;

    ${styleUtils.media.tablet.small} {
      width: 40%;
    }

    ${styleUtils.media.tablet.large} {
      width: 30%;
    }
  }
`;
