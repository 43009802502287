const mediaPhoneSmall = 'only screen';
const mediaPhoneLarge = 'only screen and (min-width : 480px)';
const mediaTabletSmall = 'only screen and (min-width : 768px)';
const mediaTabletLarge = 'only screen and (min-width : 992px)';
const mediaDesktopXSmall = 'only screen and (min-width : 1200px) and (max-height: 750px)';
const mediaDesktopSmall = 'only screen and (min-width : 1200px) and (min-height: 751px)';
const mediaDesktopLarge = 'only screen and (min-width : 1500px)';
const mediaDesktopHuge = 'only screen and (min-width : 2000px)';
const mediaDesktopMega = 'only screen and (min-width : 3000px)';

const mediaEdge = '(-ms-ime-align:auto)';

const mediaTouchDevice = '(-moz-touch-enabled: 1), (pointer:coarse)';

export const mediaOrientationLandscape = 'only screen and (min-width : 1000px) and (min-height : 610px) and (orientation: landscape)';
export const mediaOrientationPortrait = 'only screen and (orientation: portrait), (max-width : 1000px), (max-height: 609px)';

const media = {
  orientation: {
    landscape: `@media ${mediaOrientationLandscape}`,
    portrait: `@media ${mediaOrientationPortrait}`,
  },
  phone: {
    small: `@media ${mediaPhoneSmall}`,
    large: `@media ${mediaPhoneLarge}`,
  },
  tablet: {
    small: `@media ${mediaTabletSmall}`,
    large: `@media ${mediaTabletLarge}`,
  },
  desktop: {
    xsmall: `@media ${mediaDesktopXSmall}`,
    small: `@media ${mediaDesktopSmall}`,
    large: `@media ${mediaDesktopLarge}`,
    huge: `@media ${mediaDesktopHuge}`,
    mega: `@media ${mediaDesktopMega}`,
  },
  edge: `@supports ${mediaEdge}`,
  ie11: `@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)`,
  touchDevice: `@media ${mediaTouchDevice}`,
};

export default media;
