import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../../utils/styling/styled';

import NavigationMenuItemList from '../NavigationMenuItems/NavigationMenuItemList';
import styleUtils from '../../../../../utils/styling/styleUtils';

export const HomeIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const List = styled(NavigationMenuItemList)`
  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.margin.vertical.top};

    padding-top: 5px;
  }

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.vertical.none};

    padding-top: 5px;
  }
`;
