import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../../../components/Layout/SEO';

import { getAuthor, getCanonicalUrl, getDescription, getLang, getOpenGraph, getTitle, getTitleTemplate, getTwitter } from './utils';

// Types
import { SEOContainerQuery } from './__generated__/SEOContainerQuery';

type Props = {
  author?: string | null;
  canonicalUrl?: string | null;
  description?: string | null;
  image?: { alt?: string | null; height?: number | null; url?: string | null; width?: number | null } | null;
  lang?: string | null;
  openGraphDescription?: string | null;
  openGraphSiteName?: string | null;
  openGraphTitle?: string | null;
  openGraphType?: string | null;
  title?: string | null;
  titleTemplate?: string | null;
  twitterCardType?: string | null;
};

const SEOContainer = (props: Props) => {
  const data = useStaticQuery<SEOContainerQuery>(graphql`
    query SEOContainerQuery {
      site {
        siteMetadata {
          author
          canonicalUrl
          description
          lang
          title
          titleTemplate
          defaultImage {
            alt
            height
            url
            width
          }
          openGraph {
            description
            siteName
            title
            type
          }
          socialMedia {
            architonic
            facebook
            houzz
            instagram
          }
          twitter {
            cardType
          }
        }
      }
    }
  `);

  const author = props.author || getAuthor(data);
  const canonicalUrl = props.canonicalUrl || getCanonicalUrl(data);
  const description = props.description || getDescription(data);
  const lang = props.lang || getLang(data);
  const title = props.title || getTitle(data);
  const titleTemplate = props.titleTemplate || getTitleTemplate(data);

  const defaultOpenGraph = getOpenGraph(data);
  const defaultTwitter = getTwitter(data);

  const openGraphDescription = props.openGraphDescription || props.description || defaultOpenGraph.description;
  const openGraphImage = { ...defaultOpenGraph.image, ...(props.image || {}) };
  const openGraphSiteName = props.openGraphSiteName || defaultOpenGraph.siteName;
  const openGraphTitle = `${props.openGraphTitle || defaultOpenGraph.title}${titleTemplate}`;
  const openGraphType = props.openGraphType || defaultOpenGraph.type;

  const twitterCardType = props.twitterCardType || defaultTwitter.cardType;

  return (
    <SEO
      author={author}
      canonicalUrl={canonicalUrl}
      description={description}
      lang={lang}
      openGraphDescription={openGraphDescription}
      openGraphImage={openGraphImage}
      openGraphSiteName={openGraphSiteName}
      openGraphTitle={openGraphTitle}
      openGraphType={openGraphType}
      title={title}
      titleTemplate={`%s${titleTemplate}`}
      twitterCardType={twitterCardType}
    />
  );
};

export default SEOContainer;
