import css from '@emotion/css';

/* See: https://github.com/emotion-js/emotion/issues/1105 */
const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const gap = css`
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;

  &:nth-child${ignoreSsrWarning}(3n) {
    margin-bottom: 0;
    margin-right: 0;
  }
`;

const grid = {
  gap,
};

export default grid;
