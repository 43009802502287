import css from '@emotion/css';

import media from './media';

const PADDING_NONE = 0;

const PADDING_MOBILE = 2;
const PADDING_DESKTOP = 2;

const PADDING_DOUBLE_DESKTOP = PADDING_DESKTOP * 2;
const PADDING_DOUBLE_MOBILE = PADDING_MOBILE * 2;

const PADDING_HALF_DESKTOP = PADDING_DESKTOP / 2;
const PADDING_HALF_MOBILE = PADDING_MOBILE / 2;

const PADDING_QUADRUPLE_DESKTOP = PADDING_DESKTOP * 4;
const PADDING_QUADRUPLE_MOBILE = PADDING_MOBILE * 4;

const PADDING_QUARTER_DESKTOP = PADDING_DESKTOP / 4;
const PADDING_QUARTER_MOBILE = PADDING_MOBILE / 4;

// HORIZONTAL NONE

const horizontalPaddingLeftNone = css`
  padding-left: ${PADDING_NONE}rem;
`;

const horizontalPaddingRightNone = css`
  padding-right: ${PADDING_NONE}rem;
`;

const horizontalPaddingNone = css`
  ${horizontalPaddingLeftNone};
  ${horizontalPaddingRightNone};
`;

// HORIZONTAL HALF

const horizontalPaddingLeftHalf = css`
  padding-left: ${PADDING_HALF_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: ${PADDING_HALF_DESKTOP}rem;
  }
`;

const horizontalPaddingRightHalf = css`
  padding-right: ${PADDING_HALF_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: ${PADDING_HALF_DESKTOP}rem;
  }
`;

const horizontalPaddingHalf = css`
  ${horizontalPaddingLeftHalf};
  ${horizontalPaddingRightHalf};
`;

// HORIZONTAL QUARTER

const horizontalPaddingLeftQuarter = css`
  padding-left: ${PADDING_QUARTER_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: ${PADDING_QUARTER_DESKTOP}rem;
  }
`;

const horizontalPaddingRightQuarter = css`
  padding-right: ${PADDING_QUARTER_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: ${PADDING_QUARTER_DESKTOP}rem;
  }
`;

const horizontalPaddingQuarter = css`
  ${horizontalPaddingLeftQuarter};
  ${horizontalPaddingRightQuarter};
`;

// HORIZONTAL

const horizontalPaddingLeft = css`
  padding-left: ${PADDING_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: ${PADDING_DESKTOP}rem;
  }
`;

const horizontalPaddingRight = css`
  padding-right: ${PADDING_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: ${PADDING_DESKTOP}rem;
  }
`;

const horizontalPadding = css`
  ${horizontalPaddingLeft};
  ${horizontalPaddingRight};
`;

// HORIZONTAL DOUBLE

const horizontalPaddingLeftDouble = css`
  padding-left: ${PADDING_DOUBLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: ${PADDING_DOUBLE_DESKTOP}rem;
  }
`;

const horizontalPaddingRightDouble = css`
  padding-right: ${PADDING_DOUBLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: ${PADDING_DOUBLE_DESKTOP}rem;
  }
`;

const horizontalPaddingDouble = css`
  ${horizontalPaddingLeftDouble};
  ${horizontalPaddingRightDouble};
`;

// HORIZONTAL QUADRUPLE

const horizontalPaddingLeftQuadruple = css`
  padding-left: ${PADDING_QUADRUPLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: ${PADDING_QUADRUPLE_DESKTOP}rem;
  }
`;

const horizontalPaddingRightQuadruple = css`
  padding-right: ${PADDING_QUADRUPLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: ${PADDING_QUADRUPLE_DESKTOP}rem;
  }
`;

const horizontalPaddingQuadruple = css`
  ${horizontalPaddingLeftQuadruple};
  ${horizontalPaddingRightQuadruple};
`;

// VERTICAL NONE

const verticalPaddingBottomNone = css`
  padding-bottom: ${PADDING_NONE}rem;
`;

const verticalPaddingTopNone = css`
  padding-top: ${PADDING_NONE}rem;
`;

const verticalPaddingNone = css`
  ${verticalPaddingBottomNone};
  ${verticalPaddingTopNone};
`;

// VERTICAL HALF

const verticalPaddingBottomHalf = css`
  padding-bottom: ${PADDING_HALF_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: ${PADDING_HALF_DESKTOP}rem;
  }
`;

const verticalPaddingTopHalf = css`
  padding-top: ${PADDING_HALF_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: ${PADDING_HALF_DESKTOP}rem;
  }
`;

const verticalPaddingHalf = css`
  ${verticalPaddingBottomHalf};
  ${verticalPaddingTopHalf};
`;

// VERTICAL QUARTER

const verticalPaddingBottomQuarter = css`
  padding-bottom: ${PADDING_QUARTER_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: ${PADDING_QUARTER_DESKTOP}rem;
  }
`;

const verticalPaddingTopQuarter = css`
  padding-top: ${PADDING_QUARTER_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: ${PADDING_QUARTER_DESKTOP}rem;
  }
`;

const verticalPaddingQuarter = css`
  ${verticalPaddingBottomQuarter};
  ${verticalPaddingTopQuarter};
`;

// VERTICAL

const verticalPaddingBottom = css`
  padding-bottom: ${PADDING_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: ${PADDING_DESKTOP}rem;
  }
`;

const verticalPaddingTop = css`
  padding-top: ${PADDING_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: ${PADDING_DESKTOP}rem;
  }
`;

const verticalPadding = css`
  ${verticalPaddingBottom};
  ${verticalPaddingTop};
`;

// VERTICAL DOUBLE

const verticalPaddingBottomDouble = css`
  padding-bottom: ${PADDING_DOUBLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: ${PADDING_DOUBLE_DESKTOP}rem;
  }
`;

const verticalPaddingTopDouble = css`
  padding-top: ${PADDING_DOUBLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: ${PADDING_DOUBLE_DESKTOP}rem;
  }
`;

const verticalPaddingDouble = css`
  ${verticalPaddingBottomDouble};
  ${verticalPaddingTopDouble};
`;

// VERTICAL QUADRUPLE

const verticalPaddingBottomQuadruple = css`
  padding-bottom: ${PADDING_QUADRUPLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: ${PADDING_QUADRUPLE_DESKTOP}rem;
  }
`;

const verticalPaddingTopQuadruple = css`
  padding-top: ${PADDING_QUADRUPLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: ${PADDING_QUADRUPLE_DESKTOP}rem;
  }
`;

const verticalPaddingQuadruple = css`
  ${verticalPaddingBottomQuadruple};
  ${verticalPaddingTopQuadruple};
`;

// NEGATIVE HORIZONTAL HALF

const negativeHorizontalPaddingLeftHalf = css`
  padding-left: -${PADDING_HALF_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: -${PADDING_HALF_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingRightHalf = css`
  padding-right: -${PADDING_HALF_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: -${PADDING_HALF_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingHalf = css`
  ${negativeHorizontalPaddingLeftHalf};
  ${negativeHorizontalPaddingRightHalf};
`;

// NEGATIVE HORIZONTAL QUARTER

const negativeHorizontalPaddingLeftQuarter = css`
  padding-left: -${PADDING_QUARTER_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: -${PADDING_QUARTER_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingRightQuarter = css`
  padding-right: -${PADDING_QUARTER_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: -${PADDING_QUARTER_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingQuarter = css`
  ${negativeHorizontalPaddingLeftQuarter};
  ${negativeHorizontalPaddingRightQuarter};
`;

// NEGATIVE HORIZONTAL

const negativeHorizontalPaddingLeft = css`
  padding-left: -${PADDING_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: -${PADDING_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingRight = css`
  padding-right: -${PADDING_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: -${PADDING_DESKTOP}rem;
  }
`;

const negativeHorizontalPadding = css`
  ${negativeHorizontalPaddingLeft};
  ${negativeHorizontalPaddingRight};
`;

// NEGATIVE HORIZONTAL DOUBLE

const negativeHorizontalPaddingLeftDouble = css`
  padding-left: -${PADDING_DOUBLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: -${PADDING_DOUBLE_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingRightDouble = css`
  padding-right: -${PADDING_DOUBLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: -${PADDING_DOUBLE_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingDouble = css`
  ${negativeHorizontalPaddingLeftDouble};
  ${negativeHorizontalPaddingRightDouble};
`;

// NEGATIVE HORIZONTAL QUADRUPLE

const negativeHorizontalPaddingLeftQuadruple = css`
  padding-left: -${PADDING_QUADRUPLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-left: -${PADDING_QUADRUPLE_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingRightQuadruple = css`
  padding-right: -${PADDING_QUADRUPLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-right: -${PADDING_QUADRUPLE_DESKTOP}rem;
  }
`;

const negativeHorizontalPaddingQuadruple = css`
  ${negativeHorizontalPaddingLeftQuadruple};
  ${negativeHorizontalPaddingRightQuadruple};
`;

// NEGATIVE VERTICAL HALF

const negativeVerticalPaddingBottomHalf = css`
  padding-bottom: -${PADDING_HALF_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: -${PADDING_HALF_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingTopHalf = css`
  padding-top: -${PADDING_HALF_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: -${PADDING_HALF_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingHalf = css`
  ${negativeVerticalPaddingBottomHalf};
  ${negativeVerticalPaddingTopHalf};
`;

// NEGATIVE VERTICAL QUARTER

const negativeVerticalPaddingBottomQuarter = css`
  padding-bottom: -${PADDING_QUARTER_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: -${PADDING_QUARTER_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingTopQuarter = css`
  padding-top: -${PADDING_QUARTER_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: -${PADDING_QUARTER_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingQuarter = css`
  ${negativeVerticalPaddingBottomQuarter};
  ${negativeVerticalPaddingTopQuarter};
`;

// NEGATIVE VERTICAL

const negativeVerticalPaddingBottom = css`
  padding-bottom: -${PADDING_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: -${PADDING_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingTop = css`
  padding-top: -${PADDING_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: -${PADDING_DESKTOP}rem;
  }
`;

const negativeVerticalPadding = css`
  ${negativeVerticalPaddingBottom};
  ${negativeVerticalPaddingTop};
`;

// NEGATIVE VERTICAL DOUBLE

const negativeVerticalPaddingBottomDouble = css`
  padding-bottom: -${PADDING_DOUBLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: -${PADDING_DOUBLE_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingTopDouble = css`
  padding-top: -${PADDING_DOUBLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: -${PADDING_DOUBLE_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingDouble = css`
  ${negativeVerticalPaddingBottomDouble};
  ${negativeVerticalPaddingTopDouble};
`;

// NEGATIVE VERTICAL QUADRUPLE

const negativeVerticalPaddingBottomQuadruple = css`
  padding-bottom: -${PADDING_QUADRUPLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-bottom: -${PADDING_QUADRUPLE_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingTopQuadruple = css`
  padding-top: -${PADDING_QUADRUPLE_MOBILE}rem;

  ${media.tablet.small} {
    padding-top: -${PADDING_QUADRUPLE_DESKTOP}rem;
  }
`;

const negativeVerticalPaddingQuadruple = css`
  ${negativeVerticalPaddingBottomQuadruple};
  ${negativeVerticalPaddingTopQuadruple};
`;

const padding = {
  horizontal: {
    both: horizontalPadding,
    bothDouble: horizontalPaddingDouble,
    bothHalf: horizontalPaddingHalf,
    bothQuadruple: horizontalPaddingQuadruple,
    bothQuarter: horizontalPaddingQuarter,
    left: horizontalPaddingLeft,
    leftDouble: horizontalPaddingLeftDouble,
    leftHalf: horizontalPaddingLeftHalf,
    leftNone: horizontalPaddingLeftNone,
    leftQuadruple: horizontalPaddingLeftQuadruple,
    leftQuarter: horizontalPaddingLeftQuarter,
    none: horizontalPaddingNone,
    right: horizontalPaddingRight,
    rightDouble: horizontalPaddingRightDouble,
    rightHalf: horizontalPaddingRightHalf,
    rightNone: horizontalPaddingRightNone,
    rightQuadruple: horizontalPaddingRightQuadruple,
    rightQuarter: horizontalPaddingRightQuarter,
  },
  vertical: {
    both: verticalPadding,
    bothDouble: verticalPaddingDouble,
    bothHalf: verticalPaddingHalf,
    bothQuadruple: verticalPaddingQuadruple,
    bothQuarter: verticalPaddingQuarter,
    bottom: verticalPaddingBottom,
    bottomDouble: verticalPaddingBottomDouble,
    bottomHalf: verticalPaddingBottomHalf,
    bottomNone: verticalPaddingBottomNone,
    bottomQuadruple: verticalPaddingBottomQuadruple,
    bottomQuarter: verticalPaddingBottomQuarter,
    none: verticalPaddingNone,
    top: verticalPaddingTop,
    topDouble: verticalPaddingTopDouble,
    topHalf: verticalPaddingTopHalf,
    topNone: verticalPaddingTopNone,
    topQuadruple: verticalPaddingTopQuadruple,
    topQuarter: verticalPaddingTopQuarter,
  },
  negativeHorizontal: {
    both: negativeHorizontalPadding,
    bothDouble: negativeHorizontalPaddingDouble,
    bothHalf: negativeHorizontalPaddingHalf,
    bothQuadruple: negativeHorizontalPaddingQuadruple,
    bothQuarter: negativeHorizontalPaddingQuarter,
    left: negativeHorizontalPaddingLeft,
    leftDouble: negativeHorizontalPaddingLeftDouble,
    leftHalf: negativeHorizontalPaddingLeftHalf,
    leftQuadruple: negativeHorizontalPaddingLeftQuadruple,
    leftQuarter: negativeHorizontalPaddingLeftQuarter,
    right: negativeHorizontalPaddingRight,
    rightDouble: negativeHorizontalPaddingRightDouble,
    rightHalf: negativeHorizontalPaddingRightHalf,
    rightQuadruple: negativeHorizontalPaddingRightQuadruple,
    rightQuarter: negativeHorizontalPaddingRightQuarter,
  },
  negativeVertical: {
    both: negativeVerticalPadding,
    bothDouble: negativeVerticalPaddingDouble,
    bothHalf: negativeVerticalPaddingHalf,
    bothQuadruple: negativeVerticalPaddingQuadruple,
    bothQuarter: negativeVerticalPaddingQuarter,
    bottom: negativeVerticalPaddingBottom,
    bottomDouble: negativeVerticalPaddingBottomDouble,
    bottomHalf: negativeVerticalPaddingBottomHalf,
    bottomQuadruple: negativeVerticalPaddingBottomQuadruple,
    bottomQuarter: negativeVerticalPaddingBottomQuarter,
    top: negativeVerticalPaddingTop,
    topDouble: negativeVerticalPaddingTopDouble,
    topHalf: negativeVerticalPaddingTopHalf,
    topQuadruple: negativeVerticalPaddingTopQuadruple,
    topQuarter: negativeVerticalPaddingTopQuarter,
  },
};

export default padding;
