type BuildRugNameInput = {
  extra: string | null;
  name: string;
  postExtra?: string | null;
  preExtra?: string | null;
};

const buildRugName = ({ extra, name, postExtra, preExtra }: BuildRugNameInput) => {
  return `${preExtra || ''} ${name} ${extra || ''} ${postExtra || ''}`.trim();
};

export default buildRugName;
