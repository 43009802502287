import React from 'react';

import NavigationMenuCollectionGridItemGrid from '../NavigationMenuCollectionGridItems/NavigationMenuCollectionGridItemGrid';
import NavigationMenuCollectionGridItemPaginationButton from '../NavigationMenuCollectionGridItems/NavigationMenuCollectionGridItemPaginationButton';
import useCollectionGridSizing from '../../../../../../utils/hooks/useCollectionGridSizing';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  collections: ReactNode;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
};

const NavigationMenuCollectionGridDesktop = ({ collections, hasNextPage, hasPreviousPage, nextPage, previousPage }: Props) => {
  const { returnSize, wrapperRef } = useCollectionGridSizing();

  return (
    <S.Wrapper aria-hidden={true} ref={wrapperRef}>
      <NavigationMenuCollectionGridItemGrid style={{ height: returnSize, width: returnSize }}>
        {hasPreviousPage && <NavigationMenuCollectionGridItemPaginationButton arrowDirection="left" onClick={previousPage} />}

        {collections}

        {hasNextPage && <NavigationMenuCollectionGridItemPaginationButton arrowDirection="right" onClick={nextPage} />}
      </NavigationMenuCollectionGridItemGrid>
    </S.Wrapper>
  );
};

export default NavigationMenuCollectionGridDesktop;
