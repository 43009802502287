import css from '@emotion/css';

import styleUtils from '../../utils/styling/styleUtils';
import { CLIPPED_ANGLE } from '../../config/constants';

import bgCardboard from './assets/bg_cardboard.jpg';
import bgCardboardBlue from './assets/bg_cardboard_blue.jpg';
import bgChrome from './assets/bg_chrome.jpg';
import bgGold from './assets/bg_gold.jpg';
import bgSpace from './assets/bg_space.jpg';
import bgWall from './assets/bg_wall.png';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../types/global';
import { SectionWidth } from '../../../types/sections';

type SetWidthProps = {
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
  shouldNotMakeWiderIfNeighborIsClipped?: boolean;
};

export const setWidth = ({ isClipped, isNeighborClipped, sectionWidth, shouldNotMakeWiderIfNeighborIsClipped }: SetWidthProps) => {
  if (!isClipped && !isNeighborClipped && shouldNotMakeWiderIfNeighborIsClipped) {
    return css`
      ${styleUtils.media.orientation.landscape} {
        flex: 0 0 calc(${sectionWidth + 1}% + 1px);
        min-width: ${sectionWidth}rem;
        width: calc(${sectionWidth + 1}% + 1px);
      }
    `;
  }

  if (isNeighborClipped) {
    return css`
      ${styleUtils.media.orientation.landscape} {
        flex: 0 0 calc(${sectionWidth + 1}% + 1px + ${CLIPPED_ANGLE}rem);
        min-width: ${sectionWidth}rem;
        width: calc(${sectionWidth + 1}% + 1px + ${CLIPPED_ANGLE}rem);
      }
    `;
  }

  return css`
    ${styleUtils.media.orientation.landscape} {
      flex: 0 0 calc(${sectionWidth + 1}% + 1px);
      min-width: ${sectionWidth}rem;
      width: calc(${sectionWidth + 1}% + 1px);
    }
  `;
};

type SetMarginLeftProps = {
  isClipped?: boolean;
};

export const setMarginLeft = ({ isClipped }: SetMarginLeftProps) => {
  if (!isClipped) {
    /* The -1px will prevent the background from flashing through, especially in dark sections. */
    return css`
      margin-left: -1px;
    `;
  }

  return css`
    margin-left: -${CLIPPED_ANGLE}rem;

    ${styleUtils.media.ie11} {
      margin-left: -1px;
    }

    ${styleUtils.media.edge} {
      margin-left: -1px;
    }
  `;
};

export const getBackgroundPattern = (backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN) => {
  switch (backgroundPattern) {
    case CMS_ENUM_SECTION_BACKGROUNDPATTERN.CARDBOARD:
      return bgCardboard;

    case CMS_ENUM_SECTION_BACKGROUNDPATTERN.CARDBOARD_BLUE:
      return bgCardboardBlue;

    case CMS_ENUM_SECTION_BACKGROUNDPATTERN.CHROME:
      return bgChrome;

    case CMS_ENUM_SECTION_BACKGROUNDPATTERN.GOLD:
      return bgGold;

    case CMS_ENUM_SECTION_BACKGROUNDPATTERN.SPACE:
      return bgSpace;

    case CMS_ENUM_SECTION_BACKGROUNDPATTERN.WALL:
      return bgWall;
  }
};
