import React from 'react';

import Heading from '../../Heading';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  carousel: ReactNode;
  isHiddenOnMobile: boolean;
  title?: string;
};

const SectionCarousel = ({ carousel, isHiddenOnMobile, title }: Props) => {
  return (
    <S.Section
      as="nav"
      contentPositionY="flex-start"
      isHiddenOnMobile={isHiddenOnMobile}
      isNotClipable={true}
      sectionWidth={100}
      shouldNotAutoHeight={true}
      shouldNotMakeWiderIfNeighborIsClipped={true}
    >
      {!!title && <Heading isVisuallyHidden={true}>{title}</Heading>}

      {carousel}
    </S.Section>
  );
};

export default SectionCarousel;
