// Types
import { FluidImage } from './GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

export const normalizeFluidObject = (fluid: FluidImage) => {
  return {
    aspectRatio: fluid.aspectRatio || 1,
    base64: fluid.base64 || '',
    media: fluid.media || '',
    sizes: fluid.sizes || '',
    src: fluid.src || '',
    srcSet: fluid.srcSet || '',
    srcSetWebp: fluid.srcSetWebp || '',
    srcWebp: fluid.srcWebp || '',
    tracedSVG: fluid.tracedSVG || '',
  };
};
