import idx from 'idx';

type GetArchitonicUrlInput = {
  site?: {
    siteMetadata?: {
      socialMedia?: {
        architonic?: string | null;
      } | null;
    } | null;
  } | null;
};

export const getArchitonicUrl = (input: GetArchitonicUrlInput) => {
  const architonicUrl = idx(input, _ => _.site.siteMetadata.socialMedia.architonic);

  if (typeof architonicUrl !== 'string') {
    return null;
  }

  return architonicUrl;
};

type GetFacebookUrlInput = {
  site?: {
    siteMetadata?: {
      socialMedia?: {
        facebook?: string | null;
      } | null;
    } | null;
  } | null;
};

export const getFacebookUrl = (input: GetFacebookUrlInput) => {
  const facebookUrl = idx(input, _ => _.site.siteMetadata.socialMedia.facebook);

  if (typeof facebookUrl !== 'string') {
    return null;
  }

  return facebookUrl;
};

type GetHouzzUrlInput = {
  site?: {
    siteMetadata?: {
      socialMedia?: {
        houzz?: string | null;
      } | null;
    } | null;
  } | null;
};

export const getHouzzUrl = (input: GetHouzzUrlInput) => {
  const houzzUrl = idx(input, _ => _.site.siteMetadata.socialMedia.houzz);

  if (typeof houzzUrl !== 'string') {
    return null;
  }

  return houzzUrl;
};

type GetInstagramUrlInput = {
  site?: {
    siteMetadata?: {
      socialMedia?: {
        instagram?: string | null;
      } | null;
    } | null;
  } | null;
};

export const getInstagramUrl = (input: GetInstagramUrlInput) => {
  const instagramUrl = idx(input, _ => _.site.siteMetadata.socialMedia.instagram);

  if (typeof instagramUrl !== 'string') {
    return null;
  }

  return instagramUrl;
};
