import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../utils/styling/styled';

import Link from '../Link';
import styleUtils from '../../utils/styling/styleUtils';

export const BackButton = styled(Link)`
  ${styleUtils.animation.collectionOverviewBackButton};

  /* Flex Container */
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  background-color: ${props => props.theme.color.greyLight};
  height: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  pointer-events: all;
  width: 3rem;

  ${styleUtils.media.orientation.landscape} {
    height: 3rem;
    left: -4rem;
    min-height: 3rem;
    min-width: 3rem;
    position: absolute;
    top: 0;
    width: 3rem;
    z-index: 1;
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.margin.horizontal.rightHalf};

    position: static;

    ${styleUtils.media.tablet.large} {
      height: 6rem;
      min-height: 6rem;
      min-width: 6rem;
      width: 6rem;
    }
  }
`;

export const Chevron = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color.black};
`;
