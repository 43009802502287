import React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import * as S from './styles';

type Props = {
  isExpanded: boolean;
};

const NavigationMenuMenuExpandIcon = ({ isExpanded }: Props) => {
  return <S.ExpandIcon isExpanded={isExpanded} icon={faChevronDown} />;
};

export default NavigationMenuMenuExpandIcon;
