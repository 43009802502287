import wallpaperDesignAward2015WinnerAward from './assets/wallpaper_design_award_2015_winner.png';
import wallpaperDesignAward2017Award from './assets/wallpaper_design_award_2017.png';

// Types
import { CMS_ENUM_RUG_AWARD } from '../../../../../types/global';

export const getAwardName = (award?: CMS_ENUM_RUG_AWARD | null) => {
  switch (award) {
    case CMS_ENUM_RUG_AWARD.WALLPAPER_DESIGN_AWARD_2015_WINNER:
      return 'Wallpaper* Design Award 2015 – Winner';

    case CMS_ENUM_RUG_AWARD.WALLPAPER_DESIGN_AWARD_2017:
      return 'Wallpaper* Design Award 2017';

    default:
      return null;
  }
};

export const getAwardUrl = (award?: CMS_ENUM_RUG_AWARD | null) => {
  switch (award) {
    case CMS_ENUM_RUG_AWARD.WALLPAPER_DESIGN_AWARD_2015_WINNER:
      return wallpaperDesignAward2015WinnerAward;

    case CMS_ENUM_RUG_AWARD.WALLPAPER_DESIGN_AWARD_2017:
      return wallpaperDesignAward2017Award;

    default:
      return null;
  }
};
