import React from 'react';
import Helmet from 'react-helmet';

import SEOOpenGraph from './SEOOpenGraph';
import SEOTwitter from './SEOTwitter';
import buildAbsoluteUrl from '../../../utils/seo/buildAbsoluteUrl';

type Props = {
  author?: string | null;
  canonicalUrl?: string | null;
  description?: string | null;
  lang?: string | null;
  openGraphImage: { alt?: string | null; height?: number | null; url?: string | null; width?: number | null };
  openGraphDescription?: string | null;
  openGraphSiteName?: string | null;
  openGraphTitle?: string | null;
  openGraphType?: string | null;
  title?: string | null;
  titleTemplate?: string | null;
  twitterCardType?: string | null;
};

const SEO = (props: Props) => {
  const absoluteCanonicalUrl = buildAbsoluteUrl(props.canonicalUrl);

  return (
    <>
      <Helmet title={props.title || undefined} titleTemplate={props.titleTemplate || undefined}>
        {!!props.author && <meta name="author" content={props.author}></meta>}

        {!!props.author && <meta name="copyright" content={props.author}></meta>}

        {!!props.author && <meta name="owner" content={props.author}></meta>}

        {!!props.canonicalUrl && <link rel="canonical" href={absoluteCanonicalUrl || undefined} />}

        {!!props.description && <meta name="description" content={props.description} />}

        {!!props.lang && <html lang={props.lang} />}
      </Helmet>

      <SEOOpenGraph
        canonicalUrl={props.canonicalUrl}
        description={props.openGraphDescription}
        image={props.openGraphImage}
        siteName={props.openGraphSiteName}
        title={props.openGraphTitle}
        type={props.openGraphType}
      />

      <SEOTwitter cardType={props.twitterCardType} />
    </>
  );
};

export default SEO;
