import styled from '../../utils/styling/styled';

import styleUtils from '../../utils/styling/styleUtils';

export const Carousel = styled.div`
  /* Flex Container */
  display: flex;

  ${styleUtils.media.orientation.portrait} {
    /* Flex Container */
    flex-direction: row;

    height: ${props => props.theme.section.carousel.portrait.height}px;
    max-width: 100%;
    scroll-snap-type: x mandatory;
    width: 100vw;
  }

  ${styleUtils.media.orientation.landscape} {
    /* Flex Container */
    flex-direction: column;

    height: 100%;
    max-height: 100%;
    scroll-snap-type: y mandatory;
    width: ${props => props.theme.section.carousel.landscape.width}px;
  }

  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  top: 0;

  -webkit-overflow-scrolling: touch;
`;

export const Wrapper = styled.div``;
