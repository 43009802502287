import React from 'react';

import isEscKey from '../misc/isEscKey';

const useOnEscPress = (callback: () => any) => {
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (isEscKey(event)) {
        callback();
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', handleKeyPress);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnEscPress;
