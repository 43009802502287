import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../../utils/styling/styled';

import styleUtils from '../../../../../utils/styling/styleUtils';

export const ChevronRight = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const Content = styled.div`
  /* Flex Container */
  display: flex;
  flex-direction: row;

  font-size: 0.9rem;
  text-transform: uppercase;

  svg {
    margin-right: 0.5rem;
  }
`;

export const ListItem = styled.li`
  ${styleUtils.animation.navigationListItem};

  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-top: 0.5px solid currentColor;

  &:first-of-type {
    border-top: 0;
  }
`;
