import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

export const Button = styled.button`
  left: 0;
  pointer-events: all;
  position: fixed;
  top: 0;
  z-index: 25;

  display: none;

  ${styleUtils.media.ie11} {
    display: block;
  }
`;

export const Hamburger = styled.img`
  width: 4rem;
`;
