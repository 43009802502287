import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import NavigationMenuCollectionGrid from '../../../../../components/Layout/Navigation/NavigationMenu/NavigationMenuCollectionGrid';
import NavigationMenuCollectionGridItemCollectionContainer from './NavigationMenuCollectionGridItems/NavigationMenuCollectionGridItemCollection';
import notNullOrUndefined from '../../../../../utils/misc/notNullOrUndefined';
import useCollectionGridPagination from '../../../../../utils/hooks/useCollectionGridPagination';

// Types
import { NavgitationCollectionsQuery } from './__generated__/NavgitationCollectionsQuery';

type Props = {};

const NavigationMenuCollectionGridContainer = (_props: Props) => {
  const data = useStaticQuery<NavgitationCollectionsQuery>(graphql`
    query NavgitationCollectionsQuery {
      cms {
        collections(sort: "order") {
          ...NavigationMenuCollectionGridItemCollectionContainerFragment
          id
          isVisibleOnWebsite
          cover {
            id
          }
          collectionPage {
            slug
          }
        }
      }
    }
  `);

  if (!data.cms.collections) {
    throw Error('Something went wrong.');
  }

  const collections = data.cms.collections
    .filter(notNullOrUndefined)
    .filter(collection => collection.isVisibleOnWebsite && !!collection.cover && !!collection.collectionPage);

  const { currentCollections, hasNextPage, hasPreviousPage, nextPage, previousPage } = useCollectionGridPagination(collections);

  const mappedCollections = currentCollections.map(collection => (
    <NavigationMenuCollectionGridItemCollectionContainer collection={collection} key={collection.id} />
  ));

  return (
    <NavigationMenuCollectionGrid
      collections={mappedCollections}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
      nextPage={nextPage}
      previousPage={previousPage}
    />
  );
};

export default NavigationMenuCollectionGridContainer;
