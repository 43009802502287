import React from 'react';

// Types
import { Dispatch, ReactNode } from 'react';

enum NavigationContextActionType {
  MenuClose = 'MENU_CLOSE',
  MenuOpen = 'MENU_OPEN',
}

type NavigationContextAction = {
  type: NavigationContextActionType;
};

type State = {
  isMenuOpen: boolean;
};

type Context = {
  dispatch: Dispatch<NavigationContextAction> | null;
  state: State;
};

const initialState: State = {
  isMenuOpen: false,
};

const reducer = (state: State, action: NavigationContextAction) => {
  switch (action.type) {
    case NavigationContextActionType.MenuClose:
      return {
        isMenuOpen: false,
      };

    case NavigationContextActionType.MenuOpen:
      return {
        isMenuOpen: true,
      };

    default:
      return state;
  }
};

const NavigationContext = React.createContext<Context>({ state: initialState, dispatch: null });

type NavigationContextProviderProps = {
  children: ReactNode;
};

export const NavigationContextProvider = ({ children }: NavigationContextProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <NavigationContext.Provider value={{ dispatch, state }}>{children}</NavigationContext.Provider>;
};

const useNavigationContext = () => {
  const {
    dispatch,
    state: { isMenuOpen },
  } = React.useContext(NavigationContext);

  const closeMenu = () => {
    if (!!dispatch) {
      dispatch({ type: NavigationContextActionType.MenuClose });
    }
  };

  const openMenu = () => {
    if (!!dispatch) {
      dispatch({ type: NavigationContextActionType.MenuOpen });
    }
  };

  const toggleIsMenuOpen = () => {
    if (!!dispatch) {
      if (isMenuOpen) {
        dispatch({ type: NavigationContextActionType.MenuClose });
      } else {
        dispatch({ type: NavigationContextActionType.MenuOpen });
      }
    }
  };

  return {
    closeMenu,
    isMenuOpen,
    openMenu,
    toggleIsMenuOpen,
  };
};

export default useNavigationContext;
