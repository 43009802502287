import idx from 'idx';

// Types
import { SEOContainerQuery } from './__generated__/SEOContainerQuery';

export const getAuthor = (input: SEOContainerQuery) => {
  const author = idx(input, _ => _.site.siteMetadata.author);

  if (!author) {
    return undefined;
  }

  return author;
};

export const getCanonicalUrl = (input: SEOContainerQuery) => {
  const canonicalUrl = idx(input, _ => _.site.siteMetadata.canonicalUrl);

  if (!canonicalUrl) {
    return undefined;
  }

  return canonicalUrl;
};

export const getDescription = (input: SEOContainerQuery) => {
  const description = idx(input, _ => _.site.siteMetadata.description);

  if (!description) {
    return undefined;
  }

  return description;
};

export const getLang = (input: SEOContainerQuery) => {
  const lang = idx(input, _ => _.site.siteMetadata.lang);

  if (!lang) {
    return undefined;
  }

  return lang;
};

export const getTitle = (input: SEOContainerQuery) => {
  const title = idx(input, _ => _.site.siteMetadata.title);

  if (!title) {
    return undefined;
  }

  return title;
};

export const getTitleTemplate = (input: SEOContainerQuery) => {
  const titleTemplate = idx(input, _ => _.site.siteMetadata.titleTemplate);

  if (!titleTemplate) {
    return undefined;
  }

  return titleTemplate;
};

export const getDefaultImage = (input: SEOContainerQuery) => {
  const alt = idx(input, _ => _.site.siteMetadata.defaultImage.alt);
  const height = idx(input, _ => _.site.siteMetadata.defaultImage.height);
  const url = idx(input, _ => _.site.siteMetadata.defaultImage.url);
  const width = idx(input, _ => _.site.siteMetadata.defaultImage.width);

  if (!alt || typeof height !== 'number' || !url || typeof width !== 'number') {
    return undefined;
  }

  return { alt, height, url, width };
};

export const getOpenGraph = (input: SEOContainerQuery) => {
  const canonicalUrl = getCanonicalUrl(input) || null;
  const description = idx(input, _ => _.site.siteMetadata.openGraph.description) || null;
  const image = getDefaultImage(input) || {};
  const siteName = idx(input, _ => _.site.siteMetadata.openGraph.siteName) || null;
  const title = idx(input, _ => _.site.siteMetadata.openGraph.title) || null;
  const type = idx(input, _ => _.site.siteMetadata.openGraph.type) || null;

  return { canonicalUrl, description, image, siteName, title, type };
};

export const getSocialMedia = (input: SEOContainerQuery) => {
  const architonic = idx(input, _ => _.site.siteMetadata.socialMedia.architonic) || null;
  const facebook = idx(input, _ => _.site.siteMetadata.socialMedia.facebook) || null;
  const houzz = idx(input, _ => _.site.siteMetadata.socialMedia.houzz) || null;
  const instagram = idx(input, _ => _.site.siteMetadata.socialMedia.instagram) || null;

  return { architonic, facebook, houzz, instagram };
};

export const getTwitter = (input: SEOContainerQuery) => {
  const cardType = idx(input, _ => _.site.siteMetadata.twitter.cardType) || null;

  return { cardType };
};
