const color = {
  black40: 'hsla(0, 0%, 19%, 0.4)',
  black70: 'hsla(0, 0%, 19%, 0.7)',
  black80: 'hsla(0, 0%, 19%, 0.8)',
  black: 'hsl(0, 0%, 19%)',
  brand: 'hsl(345, 100%, 50%)',
  brandDark: 'hsl(345, 100%, 40%)',
  green: '#00FF00',
  grey: '#606060',
  greyHalfLight: 'hsl(0, 0%, 60%)',
  greyLight: 'hsl(0, 0%, 95%)',
  trueBlack30: 'hsla(0, 0%, 0%, 0.3)',
  trueBlack40: 'hsla(0, 0%, 0%, 0.4)',
  white40: 'rgba(255,255,255,0.4)',
  white: 'rgba(255,255,255,1)',
};

export default color;
