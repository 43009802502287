import { describe, Type } from '@mrboolean/envconfig';

type EnvConfig = {
  appName: string;
  appUrl: string;
  carouselScrollInterval: number;
  endpointCms: string;
  googleAnalyticsId: string;
  googleMapsApiKey: string;
  mapboxApiKey: string;
  isDev: boolean;
  isProduction: boolean;
  lsKeyIeBannerClosed: string;
  lsKeyPrivacyBannerClosed: string;
  lsKeyTutorialWatched: string;
  pathPrefix: string;
  rugIsNewDays: number;
  searchIndexFilename: string;
  ssKeyMenuPosition: string;
};

const envConfig: EnvConfig = describe(
  {
    appName: {
      name: 'GATSBY_APP_NAME',
      type: Type.STRING,
      isRequired: true,
    },
    appUrl: {
      name: 'GATSBY_APP_URL',
      type: Type.STRING,
      isRequired: true,
    },
    carouselScrollInterval: {
      name: 'GATSBY_CAROUSEL_SCROLL_INTERVAL',
      type: Type.NUMBER,
      isRequired: false,
      standard: 5500,
    },
    endpointCms: {
      name: 'GATSBY_ENDPOINT_CMS',
      type: Type.STRING,
      isRequired: true,
    },
    googleAnalyticsId: {
      name: 'GATSBY_GOOGLE_ANALYTICS_ID',
      type: Type.STRING,
      isRequired: true,
    },
    googleMapsApiKey: {
      name: 'GATSBY_GOOGLE_MAPS_API_KEY',
      type: Type.STRING,
      isRequired: true,
    },
    mapboxApiKey: {
      name: 'GATSBY_MAPBOX_API_KEY',
      type: Type.STRING,
      isRequired: true,
    },
    isDev: {
      name: 'NODE_ENV',
      isRequired: true,
      sanitize: (value: any): boolean => value !== 'production',
    },
    isProduction: {
      name: 'NODE_ENV',
      isRequired: true,
      sanitize: (value: any): boolean => value === 'production',
    },
    lsKeyIeBannerClosed: {
      name: 'GATSBY_LS_KEY_IE_BANNER_CLOSED',
      type: Type.STRING,
      isRequired: false,
      standard: 'IE_BANNER_CLOSED',
    },
    lsKeyPrivacyBannerClosed: {
      name: 'GATSBY_LS_KEY_PRIVACY_BANNER_CLOSED',
      type: Type.STRING,
      isRequired: false,
      standard: 'PRIVACY_BANNER_CLOSED',
    },
    lsKeyTutorialWatched: {
      name: 'GATSBY_LS_KEY_TUTORIAL_WATCHED',
      type: Type.STRING,
      isRequired: false,
      standard: 'TUTORIAL_WATCHED',
    },
    pathPrefix: {
      name: 'GATSBY_PATH_PREFIX',
      type: Type.STRING,
      isRequired: false,
      standard: '/',
    },
    rugIsNewDays: {
      name: 'GATSBY_RUG_IS_NEW_DAYS',
      type: Type.NUMBER,
      isRequired: false,
      standard: 90,
    },
    searchIndexFilename: {
      name: 'GATSBY_SEARCH_INDEX_FILENAME',
      type: Type.STRING,
      isRequired: true,
    },
    ssKeyMenuPosition: {
      name: 'GATSBY_SS_KEY_MENU_POSITION',
      type: Type.STRING,
      isRequired: false,
      standard: 'MENU_POSITION',
    },
  },
  // We can't just pass `process.env` because the values can't be destructured from it, we have to define each manually.
  // See: https://www.gatsbyjs.org/docs/environment-variables/#example
  {
    GATSBY_APP_NAME: process.env.GATSBY_APP_NAME,
    GATSBY_APP_URL: process.env.GATSBY_APP_URL,
    GATSBY_CAROUSEL_SCROLL_INTERVAL: process.env.GATSBY_CAROUSEL_SCROLL_INTERVAL,
    GATSBY_ENDPOINT_CMS: process.env.GATSBY_ENDPOINT_CMS,
    GATSBY_GOOGLE_ANALYTICS_ID: process.env.GATSBY_GOOGLE_ANALYTICS_ID,
    GATSBY_GOOGLE_MAPS_API_KEY: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    GATSBY_MAPBOX_API_KEY: process.env.GATSBY_MAPBOX_API_KEY,
    GATSBY_LS_KEY_IE_BANNER_CLOSED: process.env.GATSBY_LS_KEY_IE_BANNER_CLOSED,
    GATSBY_LS_KEY_PRIVACY_BANNER_CLOSED: process.env.GATSBY_LS_KEY_PRIVACY_BANNER_CLOSED,
    GATSBY_LS_KEY_TUTORIAL_WATCHED: process.env.GATSBY_LS_KEY_TUTORIAL_WATCHED,
    GATSBY_PATH_PREFIX: process.env.GATSBY_PATH_PREFIX,
    GATSBY_RUG_IS_NEW_DAYS: process.env.GATSBY_RUG_IS_NEW_DAYS,
    GATSBY_SEARCH_INDEX_FILENAME: process.env.GATSBY_SEARCH_INDEX_FILENAME,
    GATSBY_SS_KEY_MENU_POSITION: process.env.GATSBY_SS_KEY_MENU_POSITION,
    NODE_ENV: process.env.NODE_ENV,
  },
);

export default envConfig;
