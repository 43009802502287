import { keyframes } from '@emotion/core';

import styled from '../../../utils/styling/styled';

const cursorAnimation = keyframes`
  0%,
  100% {
      transform: translate(0, 0)
  }

  25% {
      transform: translate(-30px, -3px)
  }

  50%,
  54% {
      transform: translate(-75px, -27px)
  }

  52% {
      transform: translate(-71px, -24px) scale(.95);
      transform-origin: center center
  }
`;

export const Cursor = styled.path`
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-name: ${cursorAnimation};
  animation-timing-function: ease;
`;
