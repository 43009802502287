import React from 'react';
import { Generic, JSONLD } from 'react-structured-data';

import buildAbsoluteUrl from './buildAbsoluteUrl';
import routes from '../../config/routes';
import { STRUCTURED_DATA_ORGANIZATION } from './constants';

// Types

type Props = {};

const WebsiteStructuredData = (_props: Props) => {
  const schema = {
    author: STRUCTURED_DATA_ORGANIZATION,
    copyrightHolder: STRUCTURED_DATA_ORGANIZATION,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${buildAbsoluteUrl(routes.search)}?term={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
    url: STRUCTURED_DATA_ORGANIZATION.url,
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="WebSite" schema={schema} />
    </JSONLD>
  );
};

export default WebsiteStructuredData;
