import React from 'react';

import * as S from './styles';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { GatsbyBackgroundImageFluidProps } from '../../MediaImage/GatsbyBackgroundImageWrapperFluid/GatsbyBackgroundImageWrapperFluid';
import { HTMLAttributes, ReactHTML, ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type Props = {
  backgroundImage: FluidImage;
  children: ReactNode;
  contentPositionY: string;
  index?: number;
  isClipped?: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped?: boolean;
  isNotClipable?: boolean;
  sectionWidth?: SectionWidth;
  shouldNotAutoHeight?: boolean;
  shouldNotMakeWiderIfNeighborIsClipped?: boolean;
  Tag?: keyof ReactHTML;
} & HTMLAttributes<HTMLDivElement> &
  GatsbyBackgroundImageFluidProps;

const ScrollSectionBackgroundImageFluid = ({
  backgroundImage,
  children,
  contentPositionY,
  index,
  isClipped,
  isHiddenOnMobile,
  isNeighborClipped,
  isNotClipable,
  sectionWidth = 100,
  shouldNotAutoHeight,
  shouldNotMakeWiderIfNeighborIsClipped,
  ...rest
}: Props) => {
  return (
    <S.ScrollSection
      backgroundImage={backgroundImage}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      isNotClipable={isNotClipable}
      sectionWidth={sectionWidth}
      shouldNotAutoHeight={shouldNotAutoHeight}
      shouldNotMakeWiderIfNeighborIsClipped={shouldNotMakeWiderIfNeighborIsClipped}
      {...rest}
    >
      {children}
    </S.ScrollSection>
  );
};

export default ScrollSectionBackgroundImageFluid;
