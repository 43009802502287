const isEscKey = (event: KeyboardEvent) => {
  if ('key' in event) {
    return event.key === 'Escape' || event.key === 'Esc';
  }

  if ('keyCode' in event) {
    // @ts-ignore
    return event.keyCode === 27;
  }

  return false;
};

export default isEscKey;
