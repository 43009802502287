import React from 'react';

import Header from '../../../components/Layout/Header';
import useHeaderContext from '../../../utils/context/HeaderContext';
import useNavigationContext from '../../../utils/context/NavigationContext';

// Types
import { ReactNode } from 'react';

type Props = {
  logoColor?: string | null;
  navigation: ReactNode;
};

const HeaderContainer = ({ logoColor, navigation }: Props) => {
  const { backButtonTitle, backButtonUrl, isTitleHeaderVisible, subtitle, title } = useHeaderContext();
  const { isMenuOpen } = useNavigationContext();

  return (
    <Header
      backButtonTitle={backButtonTitle}
      backButtonUrl={backButtonUrl}
      isMenuOpen={isMenuOpen}
      isTitleHeaderVisible={isTitleHeaderVisible}
      logoColor={logoColor}
      navigation={navigation}
      titleHeaderSubtitle={subtitle}
      titleHeaderTitle={title}
    />
  );
};

export default HeaderContainer;
