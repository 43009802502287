import React from 'react';

import Link from '../../../../Link';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  icon?: ReactNode;
  name: string;
  url: string;
} & HTMLAttributes<HTMLLIElement>;

const SectionItemFooterSocialListItem = ({ icon, name, url, ...rest }: Props) => {
  return (
    <S.ListItem {...rest}>
      <Link url={url}>
        <S.Content>
          {icon && icon}

          {name}
        </S.Content>
      </Link>
    </S.ListItem>
  );
};

export default SectionItemFooterSocialListItem;
