import color from './color';

const theme = {
  color,
  section: {
    carousel: {
      landscape: {
        height: 240,
        width: 320,
      },
      portrait: {
        height: 320,
        width: 427,
      },
    },
    footer: {
      landscape: {
        width: 550,
      },
      portrait: {
        height: 470,
      },
    },
  },
  navigation: {
    collectionBoxShadowDefault: 'inset 0 0 0px rgba(0, 0, 0, 0.8)',
    collectionBoxShadowHover: 'inset 0 0 40px rgba(0, 0, 0, 0.8)',
  },
};

export default theme;
