import React from 'react';

import NavigationMenuCollectionGridItemGrid from '../NavigationMenuCollectionGridItems/NavigationMenuCollectionGridItemGrid';
import NavigationMenuCollectionGridItemPaginationButton from '../NavigationMenuCollectionGridItems/NavigationMenuCollectionGridItemPaginationButton';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  collections: ReactNode;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
};

const NavigationMenuCollectionGridMobile = ({ collections, hasNextPage, hasPreviousPage, nextPage, previousPage }: Props) => {
  return (
    <S.Wrapper>
      <NavigationMenuCollectionGridItemGrid>
        {hasPreviousPage && <NavigationMenuCollectionGridItemPaginationButton arrowDirection="left" onClick={previousPage} />}

        {collections}

        {hasNextPage && <NavigationMenuCollectionGridItemPaginationButton arrowDirection="right" onClick={nextPage} />}
      </NavigationMenuCollectionGridItemGrid>
    </S.Wrapper>
  );
};

export default NavigationMenuCollectionGridMobile;
