import styled from '../../../../../../utils/styling/styled';

import NavigationMenuCollectionGridItemWrapper from '../NavigationMenuCollectionGridItems/NavigationMenuCollectionGridItemWrapper';
import styleUtils from '../../../../../../utils/styling/styleUtils';

export const Wrapper = styled(NavigationMenuCollectionGridItemWrapper)`
  display: none;

  ${styleUtils.media.orientation.portrait} {
    margin-top: 2rem;
    display: flex;
  }
`;
