import css from '@emotion/css';

import color from './color';
import media from './media';

export const ANIMATION_DURATION = 300;

const appIconHover = css`
  transition: box-shadow 300ms ease-in-out;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 2px 2px 2px 2px rgba(0, 0, 0, 0.06);

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`;

const appStoreAltButtonHover = css`
  #bg {
    transition: fill 300ms ease-in-out;
  }

  &:hover {
    #bg {
      fill: ${color.black};
    }
  }
`;

const buttons = css`
  transition: transform ${ANIMATION_DURATION}ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const collectionHover = css`
  #name {
    transition: letter-spacing ${ANIMATION_DURATION}ms ease-in-out;
    letter-spacing: 0;
  }

  #shadow {
    &::after {
      box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.8);
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      transition: opacity ${ANIMATION_DURATION}ms ease-in-out;
      width: 100%;
    }
  }

  &:hover {
    #name {
      letter-spacing: 0.1rem;
    }

    #shadow::after {
      opacity: 1;
    }
  }
`;

const collectionGridButtonHover = css`
  #name {
    transition: margin-left ${ANIMATION_DURATION}ms ease-in-out;
  }

  &::after {
    box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.8);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity ${ANIMATION_DURATION}ms ease-in-out;
    width: 100%;

    ${media.ie11} {
      display: none;
    }
  }

  &:hover {
    ${media.phone.large} {
      #name {
        margin-left: 0.5rem;
      }
    }

    &::after {
      opacity: 1;
    }
  }
`;

const collectionOverviewBackButton = css`
  transition: background-color ${ANIMATION_DURATION}ms ease-in-out;

  svg {
    transition: color ${ANIMATION_DURATION}ms ease-in-out;
  }

  &:hover {
    background-color: ${color.brand};

    svg {
      color: ${color.white};
    }
  }
`;

const navigationListItem = css`
  transition: padding-left ${ANIMATION_DURATION}ms ease-in-out;

  &:hover {
    padding-left: 0.25rem;
  }
`;

const rugAward = css`
  transform: rotate(12deg) scale(1);
  transition: transform ${ANIMATION_DURATION}ms ease-in-out;

  &:hover {
    transform: rotate(0deg) scale(1.1);
  }
`;

const searchButtonHover = css`
  transition: background-color 300ms ease-in-out, border 300ms ease-in-out;

  &:hover {
    background-color: ${color.brandDark};
    border: 1px solid ${color.brandDark};
  }
`;

const scaleImageOnHover = css`
  figure {
    transition: transform 300ms ease-in-out;
  }

  &:hover {
    figure {
      transform: scale(1.02);
    }
  }
`;

type SlideInProps = {
  isInView: boolean;
};

const slideIn = ({ isInView }: SlideInProps) => {
  return css`
    ${media.orientation.landscape} {
      opacity: ${isInView ? 1 : 0};
      transform: ${isInView ? 'translateX(0%)' : 'translateX(80%)'};
      transition: opacity 1.5s ease-in-out, transform 1s ease-in-out;
    }
  `;
};

const showOnHover = css`
  opacity: 0;
  transition: opacity ${ANIMATION_DURATION}ms ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const viewCollectionChevron = css`
  svg {
    transition: transform ${ANIMATION_DURATION}ms ease-in-out;
  }

  &:hover {
    ${media.phone.large} {
      svg {
        transform: translateX(0.5rem);
      }
    }
  }
`;

const animation = {
  appIconHover,
  appStoreAltButtonHover,
  buttons,
  collectionGridButtonHover,
  collectionHover,
  collectionOverviewBackButton,
  navigationListItem,
  rugAward,
  scaleImageOnHover,
  searchButtonHover,
  showOnHover,
  slideIn,
  viewCollectionChevron,
};

export default animation;
