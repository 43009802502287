import React from 'react';

import * as S from './styles';
import { buildAddressRow1, buildAddressRow2 } from './utils';

type Props = {
  cell: string | null;
  city: string | null;
  country: string | null;
  email: string | null;
  fax: string | null;
  headline: string | null;
  name: string | null;
  phone: string | null;
  postalCode: string | null;
  street: string | null;
  streetNumber: string | null;
  textColor: string;
};

const SectionFooterContactContact = ({
  cell,
  city,
  country,
  email,
  fax,
  headline,
  name,
  phone,
  postalCode,
  street,
  streetNumber,
  textColor,
}: Props) => {
  if (!headline) {
    return null;
  }

  const addressRow1 = buildAddressRow1({ street, streetNumber });
  const addressRow2 = buildAddressRow2({ city, country, postalCode });

  return (
    <S.ContactWrapper textColor={textColor}>
      <S.ContactHeadline>{headline}</S.ContactHeadline>

      {!!name && <S.ContactRow>{name}</S.ContactRow>}

      {!!addressRow1 && <S.ContactRow>{addressRow1}</S.ContactRow>}

      {!!addressRow2 && <S.ContactRow>{addressRow2}</S.ContactRow>}

      {!!phone && <S.ContactRow>T: {phone}</S.ContactRow>}

      {!!fax && <S.ContactRow>F: {fax}</S.ContactRow>}

      {!!cell && <S.ContactRow>C: {cell}</S.ContactRow>}

      {!!email && <S.ContactRowLink url={`mailto:${email}`}>{email}</S.ContactRowLink>}
    </S.ContactWrapper>
  );
};

export default SectionFooterContactContact;
