import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionFooterShowroomsShowroom from '../../../../components/Section/SectionFooterShowrooms/SectionFooterShowroomsShowroom';
import buildShowroomPageUrl from '../../../../utils/url/buildShowroomPageUrl';

// Types
import { SectionFooterShowroomsShowroomContainerFragment } from './__generated__/SectionFooterShowroomsShowroomContainerFragment';

export const fragment = graphql`
  fragment SectionFooterShowroomsShowroomContainerFragment on CMS_Showroom {
    name
    nameInNavigation
    showroomPage {
      slug
    }
  }
`;

type Props = {
  showroom: SectionFooterShowroomsShowroomContainerFragment;
};

const SectionFooterShowroomsShowroomContainer = ({ showroom }: Props) => {
  const slug = idx(showroom, _ => _.showroomPage.slug);

  if (!slug) {
    return null;
  }

  const url = buildShowroomPageUrl(slug);

  return <SectionFooterShowroomsShowroom name={showroom.nameInNavigation || showroom.name} url={url} />;
};

export default SectionFooterShowroomsShowroomContainer;
