import React from 'react';
import { faInternetExplorer } from '@fortawesome/free-brands-svg-icons';

import Link from '../../Link';
import envConfig from '../../../config/env';
import usePermanentToggle from '../../../utils/hooks/usePermanentToggle';
import useSessionStorage from '../../../utils/hooks/useSessionStorage';
import { config, useSpring } from '../../../utils/styling/spring';

import * as S from './styles';

type Props = {};

const IEBanner = (_props: Props) => {
  const [isVisible, toggleIsVisible] = usePermanentToggle(true);
  // We want to fade the banner out before removing it from the DOM.
  // So we toggle the opacity through `isVisible` and the DOM on `isBannerClosed`.
  // This also helps with saving whether the banner should be initially visible or not in local storage.
  const [isBannerClosed, setIsBannerClosed] = useSessionStorage(envConfig.lsKeyIeBannerClosed, false);

  const permanentlyToggleIsBannerClosed = () => {
    if (!isBannerClosed) {
      setIsBannerClosed(true);
    }
  };

  const handleVisibilityAnimationEnd = () => {
    if (!isVisible) {
      permanentlyToggleIsBannerClosed();
    }
  };

  const bannerSpring = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: !isVisible ? 0 : 1 },
    onRest: handleVisibilityAnimationEnd,
  });

  if (isBannerClosed) {
    return null;
  }

  return (
    <S.Wrapper style={bannerSpring}>
      <S.Icon icon={faInternetExplorer} size="3x" />

      <S.Headline>Browser not supported</S.Headline>

      <S.Text>
        This website will not work correctly on your browser. We strongly suggest using an up-to-date browser like{' '}
        <Link url="https://www.google.com/chrome/">Google Chrome</Link> or{' '}
        <Link url="https://www.mozilla.org/firefox/">Mozilla Firefox</Link>.
      </S.Text>

      <S.Button onClick={toggleIsVisible} type="button">
        Ok
      </S.Button>
    </S.Wrapper>
  );
};

export default IEBanner;
