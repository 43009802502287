import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Carousel from '../../../components/Carousel/Carousel';
import SectionCarousel from '../../../components/Section/SectionCarousel';
import SectionCarouselCollectionsCollectionContainer from './SectionCarouselCollectionsCollection';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';

// Types
import { SectionCarouselCollectionsContainerQuery } from './__generated__/SectionCarouselCollectionsContainerQuery';

type Props = {
  isHiddenOnMobile: boolean;
};

const SectionCarouselCollectionsContainer = ({ isHiddenOnMobile }: Props) => {
  const data = useStaticQuery<SectionCarouselCollectionsContainerQuery>(graphql`
    query SectionCarouselCollectionsContainerQuery {
      cms {
        collections(sort: "order") {
          ...SectionCarouselCollectionsCollectionContainerFragment
          id
          isVisibleOnWebsite
        }
      }
    }
  `);

  if (!data.cms.collections) {
    throw Error('Something went wrong.');
  }

  const carousel = (
    <Carousel
      renderItem={(item, index) => <SectionCarouselCollectionsCollectionContainer collection={item} key={`${item.id}-${index}`} />}
      slides={data.cms.collections.filter(notNullOrUndefined).filter(collection => collection.isVisibleOnWebsite)}
    />
  );

  return <SectionCarousel carousel={carousel} isHiddenOnMobile={isHiddenOnMobile} title="Collections" />;
};

export default SectionCarouselCollectionsContainer;
