import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../../../utils/styling/styled';

import createOmitProps from '../../../../../../utils/styling/createOmitProps';

type ExpandIconProps = {
  isExpanded: boolean;
};

export const ExpandIcon = styled(FontAwesomeIcon, { shouldForwardProp: createOmitProps(['isExpanded']) })<ExpandIconProps>`
  transition: transform 400ms ease;

  transform: ${props => (!props.isExpanded ? 'rotate(0deg)' : 'rotate(-180deg)')};
`;
