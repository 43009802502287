import GatsbyImage from 'gatsby-background-image';
import React from 'react';

import { normalizeFluidObject } from '../utils';

// Types
import { FluidImage } from '../GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { IBackgroundImageProps } from 'gatsby-background-image';

// This is needed because of: https://github.com/gatsbyjs/gatsby/issues/12149

export type GatsbyBackgroundImageFluidProps = Omit<IBackgroundImageProps, 'fluid'>;

type Props = {
  fluid?: FluidImage | FluidImage[] | null;
} & GatsbyBackgroundImageFluidProps;

const GatsbyBackgroundImageWrapperFluid = ({ fluid, ...rest }: Props) => {
  if (!fluid) {
    return null;
  }

  const normalizedFluid = Array.isArray(fluid) ? fluid.map(normalizeFluidObject) : normalizeFluidObject(fluid);

  return <GatsbyImage fluid={normalizedFluid} {...rest} />;
};

export default GatsbyBackgroundImageWrapperFluid;
