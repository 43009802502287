import React from 'react';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const HouzzIcon = (props: Props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0v24h24V0zm19.56 20.4H14v-5.6h-4v5.6H4.44V3.6h3.92v3.92l11.2 3.21z" />
    </svg>
  );
};

export default HouzzIcon;
