import styled from '../../../utils/styling/styled';

import styleUtils from '../../../utils/styling/styleUtils';
import { animated } from '../../../utils/styling/spring';

export const Button = styled.button`
  ${styleUtils.font.weight.bold};
  ${styleUtils.padding.horizontal.bothHalf};

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
  min-width: 80px;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  width: 8vw;

  margin-left: auto;
  margin-top: 1rem;

  ${styleUtils.media.tablet.large} {
    margin-left: 0;
    margin-top: 0;
  }

  &:focus {
    outline: 0;
  }
`;

export const Text = styled.p`
  ${styleUtils.font.weight.bold};

  color: white;

  ${styleUtils.media.tablet.large} {
    max-width: 80vw;
  }

  ${styleUtils.media.desktop.small} {
    max-width: 90vw;
  }

  a {
    text-decoration: underline;
  }
`;

export const Wrapper = styled(animated.dialog)`
  ${styleUtils.padding.horizontal.both};
  ${styleUtils.padding.vertical.bothHalf};

  /* Flex Container */
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${styleUtils.media.tablet.large} {
    align-items: center;
    flex-direction: row;
  }

  background-color: ${props => props.theme.color.brand};
  /* bottom: 0; */
  /* left: 0; */
  /* position: absolute; */
  position: relative;
  width: 100vw;
  /* z-index: 11; */
`;
