import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import NavigationMenu from '../../../../components/Layout/Navigation/NavigationMenu';
import NavigationMenuCollectionGridContainer from './NavigationMenuCollectionGrid';
import NavigationMenuShowroomListItemContainer from './NavigationMenuShowroomListItem';
import NavigationMenuSocialContainer from './NavigationMenuSocial';
import filterOutExclusivePartners from '../../../../utils/showroom/filterOutExclusivePartners';
import notNullOrUndefined from '../../../../utils/misc/notNullOrUndefined';

// Types
import { NavigationMenuContainerQuery } from './__generated__/NavigationMenuContainerQuery';

type Props = {};

const NavigationMenuContainer = (_props: Props) => {
  const data = useStaticQuery<NavigationMenuContainerQuery>(graphql`
    query NavigationMenuContainerQuery {
      cms {
        showrooms(sort: "order") {
          ...NavigationMenuShowroomListItemFragment
          id
          type
        }
      }
    }
  `);

  if (!data.cms.showrooms) {
    throw Error('Something went wrong.');
  }

  const collectionGrid = <NavigationMenuCollectionGridContainer />;

  const showrooms = data.cms.showrooms
    .filter(notNullOrUndefined)
    .filter(filterOutExclusivePartners)
    .map(showroom => <NavigationMenuShowroomListItemContainer key={showroom.id} showroom={showroom} />);

  const social = <NavigationMenuSocialContainer />;

  return <NavigationMenu collectionGrid={collectionGrid} showrooms={showrooms} social={social} />;
};

export default NavigationMenuContainer;
