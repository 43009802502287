import React from 'react';
import usePermanentToggle from './usePermanentToggle';

// 39 = Arrow Key Right
// 40 = Arrow Key Down
const ARROW_KEYS = [39, 40];

// We try to detect a user scroll event and call the `onScroll` callback.
// This can happen through arrow key presses, `touchmove`s or scroll wheel triggers.
// Then we clean up after ourselves and don't leave a trace (we remove all unneccessary event listeners)
const useSelfDestroyingOnScroll = (onScroll: () => void) => {
  const [didScroll, toggleDidScroll] = usePermanentToggle(false);

  React.useEffect(() => {
    const callback = () => {
      onScroll();
      toggleDidScroll();
    };

    const handleOnScroll = (event: KeyboardEvent | TouchEvent | WheelEvent) => {
      if ('keyCode' in event && typeof event.keyCode === 'number') {
        if (!ARROW_KEYS.includes(event.keyCode)) {
          return;
        }
      }

      callback();

      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', handleOnScroll);
        window.removeEventListener('touchmove', handleOnScroll);
        window.removeEventListener('wheel', handleOnScroll);
      }
    };

    if (typeof window !== 'undefined') {
      if (!didScroll) {
        window.addEventListener('keydown', handleOnScroll);
        window.addEventListener('touchmove', handleOnScroll);
        window.addEventListener('wheel', handleOnScroll);
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', handleOnScroll);
        window.removeEventListener('touchmove', handleOnScroll);
        window.removeEventListener('wheel', handleOnScroll);
      }
    };
  });
};

export default useSelfDestroyingOnScroll;
