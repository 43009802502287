import { mediaOrientationPortrait } from './media';

const isPortrait = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia(mediaOrientationPortrait).matches;
  }

  return false;
};

export default isPortrait;
