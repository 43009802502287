import css from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../../../utils/styling/styled';

import styleUtils from '../../../../../../utils/styling/styleUtils';

export const ChevronRight = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

type ListItemProps = {
  hasLargePadding: boolean;
  isLevel2: boolean;
  isSubMenuExpanded?: boolean;
};

export const ListItem = styled.li<ListItemProps>`
  ${props => !props.isSubMenuExpanded && styleUtils.animation.navigationListItem}

  color: white;
  padding-bottom: ${props => (!props.isLevel2 ? '0.5rem' : '0.25rem')};
  padding-top: ${props => (!props.isLevel2 ? '0.5rem' : '0.25rem')};
  text-transform: ${props => (!props.isLevel2 ? 'uppercase' : 'none')};

  ${props =>
    !props.isLevel2 &&
    css`
      ${styleUtils.font.weight.bold};

      border-top: 0.5px solid ${props.theme.color.black};

      &:last-child {
        border-bottom: 0.5px solid ${props.theme.color.black};
      }
    `}

  ${props =>
    props.hasLargePadding &&
    css`
      padding-top: 1rem;
    `}
`;
