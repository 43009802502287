import React from 'react';

import ContextProvider from '../../utils/context/ContextProvider';
import HeaderContainer from './Header';
import Layout from '../../components/Layout';
import NavigationContainer from './Navigation';
import SEOContainer from './SEO';
import WebsiteStructuredData from '../../utils/seo/WebsiteStructuredData';
import useNavigationContext from '../../utils/context/NavigationContext';

// Types
import { ReactNode } from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';

type Props = {
  backgroundColor?: string;
  children: ReactNode;
  collectionOverviewUrl?: string;
  logoColor?: string | null;
};

const LayoutContainer = ({ backgroundColor, children, collectionOverviewUrl, logoColor }: Props) => {
  const { isMenuOpen } = useNavigationContext();

  const navigation = <NavigationContainer />;
  const seo = <SEOContainer />;

  const header = <HeaderContainer logoColor={logoColor} navigation={navigation} />;

  return (
    <ContextProvider>
      {/* SEO */}
      <WebsiteStructuredData />

      <Layout
        backgroundColor={backgroundColor}
        collectionOverviewUrl={collectionOverviewUrl}
        header={header}
        isMenuOpen={isMenuOpen}
        seo={seo}
      >
        {children}
      </Layout>
    </ContextProvider>
  );
};

export default LayoutContainer;
