import styled from '../../../../../../../utils/styling/styled';

import styleUtils from '../../../../../../../utils/styling/styleUtils';

export const Grid = styled.div`
  /* Flex Container */
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${styleUtils.media.orientation.portrait} {
    width: calc(100vw - 3rem);

    ${styleUtils.media.desktop.small} {
      width: calc(100vw - 2rem);
    }
  }

  ${styleUtils.media.orientation.landscape} {
    /* Initital values, will be changed with JavaScript to match the screen size. */
    height: 100vh;
    width: 100vh;
  }
`;
