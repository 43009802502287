import React from 'react';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const CloseButton = (props: Props) => {
  return (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        rx="8"
        style={{ height: '20px', transform: 'rotate(45deg) translateY(0px) translateX(0px)', transformOrigin: '15% 35%' }}
        width="90"
        x="5"
        y="13"
      />

      <rect
        rx="8"
        style={{ height: '20px', transform: 'rotate(-45deg) translateY(0px) translateX(0px)', transformOrigin: '11% 69%' }}
        width="90"
        x="5"
        y="73"
      />
    </svg>
  );
};

export default CloseButton;
