import { mediaOrientationLandscape } from './media';

const isLandscape = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia(mediaOrientationLandscape).matches;
  }

  return false;
};

export default isLandscape;
