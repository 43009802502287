import envConfig from '../../config/env';

import { withPrefix } from 'gatsby';

const buildAbsoluteUrl = (relativeUrl?: string | null) => {
  if ((relativeUrl || '').startsWith('http')) {
    return relativeUrl;
  }

  const absoluteUrl = `${envConfig.appUrl}${withPrefix(relativeUrl || '/')}`;

  return absoluteUrl;
};

export default buildAbsoluteUrl;
