import React from 'react';

import { useSpring } from '../../../../utils/styling/spring';

import * as S from './styles';

type Props = {
  isMenuButtonHovering: boolean;
  isMenuOpen: boolean;
};

const NavigationTrigger = ({ isMenuButtonHovering, isMenuOpen }: Props) => {
  const triggerSpring = useSpring({
    config: { friction: 60, mass: 1, tension: 220 },
    minHeight: isMenuOpen ? '200vh' : '0vh',
    minWidth: isMenuOpen ? '200vw' : '0vw',
    width: isMenuButtonHovering ? '7.5rem' : '6.5rem',
  });

  return (
    <S.Trigger preserveAspectRatio="none" style={triggerSpring} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 25L0 50V0h50L25 25z" />
    </S.Trigger>
  );
};

export default NavigationTrigger;
