import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export const getChevronFromDirection = (direction: 'down' | 'left' | 'right' | 'up') => {
  switch (direction) {
    case 'down':
      return faChevronDown;

    case 'left':
      return faChevronLeft;

    case 'right':
      return faChevronRight;

    case 'up':
      return faChevronUp;
  }
};
