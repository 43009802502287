import css from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

export const Button = styled.button`
  ${styleUtils.padding.horizontal.bothHalf};
  ${styleUtils.padding.vertical.bothQuarter};
  ${styleUtils.margin.vertical.topHalf};

  background-color: ${props => (!props.disabled ? props.theme.color.brand : props.theme.color.grey)};
  border-color: white;
  border-left: 0;
  border: 1px solid ${props => (!props.disabled ? props.theme.color.white : props.theme.color.grey)};
  color: white;

  ${props =>
    !props.disabled
      ? css`
          ${styleUtils.animation.buttons};
        `
      : css`
          cursor: not-allowed;
          opacity: 0.8;
        `}
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color.white};
  margin-right: 0.5rem;
`;
