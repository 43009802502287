import React from 'react';
import Helmet from 'react-helmet';

import buildAbsoluteUrl from '../../../../utils/seo/buildAbsoluteUrl';

type Props = {
  canonicalUrl?: string | null;
  description?: string | null;
  image?: { alt?: string | null; height?: number | null; url?: string | null; width?: number | null } | null;
  siteName?: string | null;
  title?: string | null;
  type?: string | null;
};

const SEOOpenGraph = ({ canonicalUrl, description, image, siteName, title, type }: Props) => {
  const absoluteCanonicalUrl = buildAbsoluteUrl(canonicalUrl);

  return (
    <>
      <Helmet>
        {!!canonicalUrl && <meta name="og:url" content={absoluteCanonicalUrl || undefined} />}

        {!!description && <meta name="og:description" content={description} />}

        {!!siteName && <meta name="og:site_name" content={siteName} />}

        {!!title && <meta name="og:title" content={title} />}

        {!!type && <meta name="og:type" content={type} />}

        {!!image && !!image.url && <meta property="og:image" content={image.url} />}

        {!!image && !!image.url && !!image.alt && <meta property="og:image:alt" content={image.alt} />}

        {!!image && !!image.url && typeof image.height === 'number' && (
          <meta property="og:image:height" content={image.height.toString()} />
        )}

        {!!image && !!image.url && typeof image.width === 'number' && <meta property="og:image:width" content={image.width.toString()} />}
      </Helmet>
    </>
  );
};

export default SEOOpenGraph;
