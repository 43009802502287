import React from 'react';

import SectionItemFooterSocialListItemNewsletterModal from './SectionItemFooterSocialListItemNewsletterModal';
import useToggle from '../../../../../utils/hooks/useToggle';

import * as S from './styles';

type Props = {};

const SectionItemFooterSocialNewsletter = (_props: Props) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  return (
    <>
      <S.ListItem title="Subscribe to our Newsletter">
        <S.NewsletterButton onClick={toggleIsModalOpen}>Newsletter</S.NewsletterButton>
      </S.ListItem>

      {isModalOpen && <SectionItemFooterSocialListItemNewsletterModal onClose={toggleIsModalOpen} />}
    </>
  );
};

export default SectionItemFooterSocialNewsletter;
