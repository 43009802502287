import styled from '../../../utils/styling/styled';

import styleUtils from '../../../utils/styling/styleUtils';
import { animated } from '../../../utils/styling/spring';

const TUTORIAL_SIZE = 400;

export const Section = styled.div`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: ${TUTORIAL_SIZE}px;
  overflow: hidden;
  width: ${TUTORIAL_SIZE}px;
`;

export const Text = styled.span`
  ${styleUtils.font.weight.bold};

  color: white;
  margin-top: 1rem;
  text-transform: uppercase;
`;

export const TutorialWrapper = styled(animated.div)`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.color.black80};
  border-radius: 50%;
  cursor: pointer;
  height: ${TUTORIAL_SIZE}px;
  left: 0;
  margin-left: calc(50vw - ${TUTORIAL_SIZE / 2}px);
  margin-right: calc(50vw - ${TUTORIAL_SIZE / 2}px);
  margin-top: calc(50vh - ${TUTORIAL_SIZE / 2}px);
  overflow: hidden;
  position: absolute;
  top: 0;
  width: ${TUTORIAL_SIZE}px;
  z-index: 10;

  ${styleUtils.media.orientation.portrait} {
    display: none;
  }
`;
