import React from 'react';

import ScrollSectionBackgroundImageFluid from './ScrollSectionBackgroundImageFluid';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../types/global';
import { FluidImage } from '../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { HTMLAttributes, ReactHTML, ReactNode } from 'react';
import { SectionWidth } from '../../../types/sections';

type Props = {
  as?: keyof ReactHTML;
  backgroundImage?: FluidImage | null;
  backgroundPattern?: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  children: ReactNode;
  contentPositionY: string;
  index?: number;
  isClipped?: boolean;
  isCritical?: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped?: boolean;
  isNotClipable?: boolean;
  sectionWidth?: SectionWidth;
  shouldNotAutoHeight?: boolean;
  shouldNotMakeWiderIfNeighborIsClipped?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const ScrollSection = ({
  as = 'section',
  backgroundImage,
  backgroundPattern,
  children,
  contentPositionY,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  isNotClipable,
  onLoad: _onLoad,
  sectionWidth = 100,
  shouldNotAutoHeight,
  shouldNotMakeWiderIfNeighborIsClipped,
  ...rest
}: Props) => {
  if (!!backgroundImage) {
    return (
      <ScrollSectionBackgroundImageFluid
        backgroundImage={backgroundImage}
        contentPositionY={contentPositionY}
        critical={isCritical}
        fadeIn={!isCritical}
        index={index}
        isClipped={isClipped}
        isHiddenOnMobile={isHiddenOnMobile}
        isNeighborClipped={isNeighborClipped}
        isNotClipable={isNotClipable}
        sectionWidth={sectionWidth}
        shouldNotAutoHeight={shouldNotAutoHeight}
        shouldNotMakeWiderIfNeighborIsClipped={shouldNotMakeWiderIfNeighborIsClipped}
        Tag={as}
        {...rest}
      >
        {children}
      </ScrollSectionBackgroundImageFluid>
    );
  }

  return (
    <S.ScrollSection
      as={as}
      backgroundPattern={backgroundPattern}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      isNotClipable={isNotClipable}
      sectionWidth={sectionWidth}
      shouldNotMakeWiderIfNeighborIsClipped={shouldNotMakeWiderIfNeighborIsClipped}
      {...rest}
    >
      {children}
    </S.ScrollSection>
  );
};

export default ScrollSection;
