import css from '@emotion/css';

const frame = css`
  &::after,
  &::before {
    background-color: rgba(255, 255, 255, 0.6);
    content: '';
    display: block;
    height: 4px;
    position: relative;
    width: 120px;
  }

  &::after {
    top: 18px;
  }

  &::before {
    top: -18px;
  }
`;

const misc = {
  frame,
};

export default misc;
