const isArrowRightOrDown = (event: KeyboardEvent) => {
  if ('key' in event) {
    return event.key === 'Right' || event.key === 'ArrowRight' || event.key === 'Down' || event.key === 'ArrowDown';
  }

  if ('keyCode' in event) {
    // @ts-ignore
    return event.keyCode === 39 || event.keyCode === 40;
  }

  return false;
};

export default isArrowRightOrDown;
