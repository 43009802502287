import React from 'react';

import CollectionCoverName from './CollectionCoverName';
import buildCollectionPageUrl from '../../../../utils/url/buildCollectionPageUrl';

import * as S from './styles';

// Types
import { HTMLAttributes } from 'react';
import { FluidImage } from '../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  coverImage: FluidImage;
  name: string;
  slug: string;
} & HTMLAttributes<HTMLAnchorElement>;

const CollectionCover = ({ coverImage, name, slug, ...rest }: Props) => {
  const url = buildCollectionPageUrl(slug);

  return (
    <S.Link url={url} {...rest}>
      <S.Cover backgroundImage={coverImage} critical={true} fadeIn="soft">
        <CollectionCoverName id="name">{name}</CollectionCoverName>

        <S.Shadow id="shadow" />
      </S.Cover>
    </S.Link>
  );
};

export default CollectionCover;
