import css from '@emotion/css';

const fourToThree = css`
  &::before {
    content: '';
    display: inline-block;
    height: 0;
    padding-bottom: 75%;
    width: 0;
  }
`;

const oneToOne = css`
  &::before {
    content: '';
    display: inline-block;
    height: 0;
    padding-bottom: 100%;
    width: 0;
  }
`;

const sixteenToNine = css`
  &::before {
    content: '';
    display: inline-block;
    height: 0;
    padding-bottom: 56.25%;
    width: 0;
  }
`;

const threeToFour = css`
  &::before {
    content: '';
    display: inline-block;
    height: 0;
    padding-bottom: 133%;
    width: 0;
  }
`;

const ratio = {
  fourToThree,
  oneToOne,
  sixteenToNine,
  threeToFour,
};

export default ratio;
