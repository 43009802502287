import styled from '../../../utils/styling/styled';

import styleUtils from '../../../utils/styling/styleUtils';

export const Input = styled.input`
  ${styleUtils.margin.vertical.topQuarter};
  ${styleUtils.padding.horizontal.bothQuarter};
  ${styleUtils.padding.vertical.bothQuarter};

  border-right: 0;
  color: black;
  outline-color: ${props => props.theme.color.brand};
  width: 100%;

  &::placeholder {
    color: ${props => props.theme.color.black40};
  }
`;
