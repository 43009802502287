import React from 'react';

import theme from '../../../utils/styling/theme';

import * as S from './styles';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const TutorialMouse = (props: Props) => {
  return (
    <svg height="107.717" width="59.528" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M29.764 107.717C13.352 107.717 0 94.364 0 77.952V29.764C0 13.352 13.352 0 29.764 0s29.764 13.352 29.764 29.764v48.188c0 16.412-13.352 29.765-29.764 29.765zm0-104.882c-14.849 0-26.929 12.08-26.929 26.929v48.188c0 14.85 12.08 26.931 26.929 26.931 14.849 0 26.93-12.081 26.93-26.931V29.764c0-14.849-12.081-26.929-26.93-26.929zm0 14.149a5.67 5.67 0 0 0-5.669 5.669v14.183a5.67 5.67 0 0 0 11.338 0V22.654a5.67 5.67 0 0 0-5.669-5.67z"
        fill={theme.color.white}
      />

      <S.MouseWheel d="M29.767 30.365a1.418 1.418 0 0 1-2.834 0v-7.091a1.417 1.417 0 0 1 2.834 0v7.091z" />
    </svg>
  );
};

export default TutorialMouse;
