import React from 'react';

import BackButton from '../BackButton';

import * as S from './styles';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  backButtonTitle: string;
  headerTitle?: string | null;
  subtitle?: string | null;
  url?: string | null;
} & HTMLAttributes<HTMLDivElement>;

const TitleHeader = ({ backButtonTitle, headerTitle, subtitle, url, ...rest }: Props) => {
  return (
    <S.Wrapper {...rest}>
      <BackButton title={backButtonTitle} url={url} />

      <div>
        <S.Header>{headerTitle}</S.Header>

        {!!subtitle && <S.Title>{subtitle}</S.Title>}
      </div>
    </S.Wrapper>
  );
};

export default TitleHeader;
