import React from 'react';

import * as S from './styles';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const NavigationCollectionChevron = (props: Props) => {
  return (
    <S.SVG viewBox="0 0 17.41 45" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h4.52l12.9 22.5L4.52 45H0l12.9-22.5z" />
    </S.SVG>
  );
};

export default NavigationCollectionChevron;
