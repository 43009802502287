import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';
import { animated } from '../../../../utils/styling/spring';

export const InnerMenu = styled.div`
  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.margin.horizontal.both};
    ${styleUtils.margin.vertical.topDouble};

    /* Flex Container */
    display: flex;
    flex-direction: column;

    max-width: 100vw;
  }

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.horizontal.leftDouble};
    ${styleUtils.margin.vertical.topDouble};

    /* Flex Container */
    display: flex;

    max-height: 100vh;
    max-width: 100vw;

    flex-direction: row;
    height: 90vh;
  }
`;

export const Menu = styled(animated.div)`
  ${styleUtils.media.orientation.portrait} {
    height: 100vh;
    left: 0;
    overflow-x: hidden;
    top: 0;
    width: 100vw;
    z-index: 20;

    position: absolute;
  }

  ${styleUtils.media.orientation.landscape} {
    height: 100vh;
    left: 0;
    overflow-x: hidden;
    top: 0;
    width: 100vw;
    z-index: 20;

    position: fixed;
  }
`;

export const NavigationWrapper = styled.section`
  ${styleUtils.media.orientation.portrait} {
    /* Flex Container */
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    flex-shrink: 1;
  }

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.media.desktop.small} {
      ${styleUtils.margin.vertical.topQuadruple};
    }

    ${styleUtils.media.desktop.xsmall} {
      margin-top: 6rem;
    }

    /* Flex Container */
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    flex-shrink: 1;
    max-height: 2300px;

    width: 40%;
  }
`;
