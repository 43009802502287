import styled from '../../../../../../../utils/styling/styled';

import CollectionCover from '../../../../../../Collection/CollectionItems/CollectionCover';
import styleUtils from '../../../../../../../utils/styling/styleUtils';

export const Collection = styled(CollectionCover)`
  ${styleUtils.grid.gap};
  ${styleUtils.ratio.oneToOne};

  flex-basis: 30%;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 32%;
  min-height: 100px;
  min-width: 100px;

  ${styleUtils.media.desktop.xsmall} {
    max-width: 28%;
  }

  ${styleUtils.media.desktop.large} {
    max-width: 32%;
  }
`;
