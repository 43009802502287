import React from 'react';
import css from '@emotion/css';

import styleUtils from '../../utils/styling/styleUtils';
import { CLIPPED_ANGLE } from '../../config/constants';
import { getBackgroundPattern } from '../ScrollSection/utils';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../types/global';
import { FluidImage } from '../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../types/sections';

const COLLECTION_OVERVIEW_BUTTON_SAFETY = '4rem  + 150px';
const DEFAULT_PADDING = '3rem';

type GetIndexPaddingProps = {
  index?: number;
};

export const getIndexPadding = ({ index }: GetIndexPaddingProps) => {
  if (typeof index !== 'number' || index !== 0) {
    return '';
  }

  return ` + ${COLLECTION_OVERVIEW_BUTTON_SAFETY}`;
};

type GetIsClippedPaddingProps = {
  contentPositionY: string;
  isClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const getIsClippedPadding = ({ contentPositionY, isClipped, sectionWidth }: GetIsClippedPaddingProps) => {
  if (!isClipped) {
    return '';
  }

  const isContentTop = contentPositionY === 'flex-start';

  if (isContentTop) {
    return ` + ${CLIPPED_ANGLE}rem`;
  }

  if (sectionWidth !== 100) {
    return ` + ${Math.floor(CLIPPED_ANGLE / 3)}rem`;
  }

  return ` + ${Math.floor(CLIPPED_ANGLE / 2)}rem`;
};
type GetIsNeighborClippedPaddingProps = {
  contentPositionY: string;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const getIsNeighborClippedPadding = ({ contentPositionY, isNeighborClipped, sectionWidth }: GetIsNeighborClippedPaddingProps) => {
  if (!isNeighborClipped) {
    return '';
  }

  const isContentTop = contentPositionY === 'flex-start';

  if (isContentTop) {
    if (sectionWidth !== 100) {
      return ` + ${Math.floor(CLIPPED_ANGLE / 3)}rem`;
    }

    return ` + ${Math.floor(CLIPPED_ANGLE / 2)}rem`;
  }

  return ` + ${CLIPPED_ANGLE}rem`;
};

type DecidePaddingLeftProps = {
  contentPositionY: string;
  index?: number;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const decidePaddingLeft = (props: DecidePaddingLeftProps) => {
  return css`
    ${styleUtils.media.orientation.landscape} {
      padding-left: calc(${DEFAULT_PADDING} ${getIsClippedPadding(props)});
    }
  `;
};

type DecidePaddingRightProps = {
  contentPositionY: string;
  index?: number;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const decidePaddingRight = (props: DecidePaddingRightProps) => {
  return css`
    ${styleUtils.media.orientation.landscape} {
      padding-right: calc(${DEFAULT_PADDING} ${getIndexPadding(props)} ${getIsNeighborClippedPadding(props)});
    }
  `;
};

type DecidePaddingLeftLandscapeProps = {
  contentPositionY?: string;
  isClipped?: boolean;
};

export const decidePaddingLeftLandscape = (props: DecidePaddingLeftLandscapeProps) => {
  const isContentTop = props.contentPositionY === 'flex-start';

  switch (props.isClipped) {
    case true:
      if (isContentTop) {
        return css`
          ${styleUtils.media.orientation.landscape} {
            ${styleUtils.specificity.increaseSpecificity} {
              padding-left: ${CLIPPED_ANGLE + 2}rem;
            }
          }
        `;
      }

      return css`
        ${styleUtils.media.orientation.landscape} {
          ${styleUtils.specificity.increaseSpecificity} {
            padding-left: ${CLIPPED_ANGLE}rem;
          }
        }
      `;

    case false:
      if (isContentTop) {
        return css`
          ${styleUtils.media.orientation.landscape} {
            ${styleUtils.specificity.increaseSpecificity} {
              padding-left: ${CLIPPED_ANGLE + 2}rem;
            }
          }
        `;
      }

      return css`
        ${styleUtils.media.orientation.landscape} {
          ${styleUtils.specificity.increaseSpecificity} {
            padding-left: ${CLIPPED_ANGLE}rem;

            ${styleUtils.media.ie11} {
              padding-left: 0;
            }

            ${styleUtils.media.edge} {
              padding-left: 0;
            }
          }
        }
      `;
  }
};
type DecidePaddingLeftLandscapeSmallProps = {
  contentPositionY: string;
  isClipped?: boolean;
};

export const decidePaddingLeftLandscapeSmall = (props: DecidePaddingLeftLandscapeSmallProps) => {
  const isContentTop = props.contentPositionY === 'flex-start';

  switch (props.isClipped) {
    case true:
      if (isContentTop) {
        return css`
          ${styleUtils.media.orientation.landscape} {
            ${styleUtils.specificity.increaseSpecificity} {
              padding-left: ${CLIPPED_ANGLE + 2}rem;

              ${styleUtils.media.ie11} {
                padding-left: 2rem;
              }

              ${styleUtils.media.edge} {
                padding-left: 2rem;
              }
            }
          }
        `;
      }

      return css`
        ${styleUtils.media.orientation.landscape} {
          ${styleUtils.specificity.increaseSpecificity} {
            padding-left: ${CLIPPED_ANGLE}rem;

            ${styleUtils.media.ie11} {
              padding-left: 0;
            }

            ${styleUtils.media.edge} {
              padding-left: 0;
            }
          }
        }
      `;

    case false:
      if (isContentTop) {
        return css`
          ${styleUtils.media.orientation.landscape} {
            ${styleUtils.specificity.increaseSpecificity} {
              padding-left: 2rem;
            }
          }
        `;
      }

      return css`
        ${styleUtils.media.orientation.landscape} {
          ${styleUtils.specificity.increaseSpecificity} {
            padding-left: 1rem;
          }
        }
      `;
  }
};

type DecidePaddingRightLandscapeProps = {
  contentPositionY?: string;
  isNeighborClipped?: boolean;
};

export const decidePaddingRightLandscape = (props: DecidePaddingRightLandscapeProps) => {
  const isContentTop = props.contentPositionY === 'flex-start';

  switch (props.isNeighborClipped) {
    case true:
      if (isContentTop) {
        return css`
          ${styleUtils.media.orientation.landscape} {
            ${styleUtils.specificity.increaseSpecificity} {
              padding-right: ${Math.floor(CLIPPED_ANGLE / 2)}rem;

              ${styleUtils.media.ie11} {
                padding-right: 0;
              }

              ${styleUtils.media.edge} {
                padding-right: 0;
              }
            }
          }
        `;
      }

      return css`
        ${styleUtils.media.orientation.landscape} {
          ${styleUtils.specificity.increaseSpecificity} {
            padding-right: ${CLIPPED_ANGLE}rem;

            ${styleUtils.media.ie11} {
              padding-right: 0;
            }

            ${styleUtils.media.edge} {
              padding-right: 0;
            }
          }
        }
      `;

    case false:
      if (isContentTop) {
        return css`
          ${styleUtils.media.orientation.landscape} {
            ${styleUtils.specificity.increaseSpecificity} {
              padding-right: 2rem;
            }
          }
        `;
      }

      return css`
        ${styleUtils.media.orientation.landscape} {
          ${styleUtils.specificity.increaseSpecificity} {
            padding-right: 1rem;
          }
        }
      `;
  }
};

type MakePortraitAutoHeightIfNoBackgroundImageProps = {
  backgroundImage?: FluidImage | null;
  shouldNotAutoHeight?: boolean;
};

export const makePortraitAutoHeightIfNoBackgroundImage = (props: MakePortraitAutoHeightIfNoBackgroundImageProps) => {
  if (!!props.backgroundImage || props.shouldNotAutoHeight) {
    return '';
  }

  return css`
    ${styleUtils.media.orientation.portrait} {
      height: auto !important;
      min-height: auto !important;
    }
  `;
};

type MakeWiderIfNeighborIsClippedProps = {
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
  shouldNotMakeWiderIfNeighborIsClipped?: boolean;
};

export const makeWiderIfNeighborIsClipped = (props: MakeWiderIfNeighborIsClippedProps) => {
  if (!props.isNeighborClipped || props.sectionWidth !== 100 || props.shouldNotMakeWiderIfNeighborIsClipped) {
    return '';
  }

  return css`
    ${styleUtils.media.orientation.landscape} {
      ${styleUtils.specificity.increaseSpecificity} {
        min-width: calc(100vw + ${CLIPPED_ANGLE}rem);
        flex: 0 0 calc(100vw + ${CLIPPED_ANGLE}rem);
        width: calc(100vw + ${CLIPPED_ANGLE}rem);
      }
    }
  `;
};

type RemoveTopMarginIfMultipleChildrenProps = {
  children: ReactNode;
};

// This is a bit hacky, but if we have both a logo and a text,
// we want to have the top margin of the text removed.
export const removeTopMarginIfMultipleChildren = (props: RemoveTopMarginIfMultipleChildrenProps) => {
  const childrenCount = React.Children.count(props.children);

  if (childrenCount > 1) {
    return css`
      div {
        &:nth-of-type(2) {
          margin-top: 0;
        }
      }
    `;
  }
};

type SetBackgroundColorProps = {
  backgroundColor?: string | null;
  backgroundPattern?: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
};

export const setBackgroundColor = ({ backgroundColor, backgroundPattern }: SetBackgroundColorProps) => {
  if (!backgroundColor || !!backgroundPattern) {
    return '';
  }

  return css`
    background-color: ${backgroundColor};
  `;
};

type SetBackgroundPatternProps = {
  backgroundPattern?: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
};

export const setBackgroundPattern = ({ backgroundPattern }: SetBackgroundPatternProps) => {
  if (!backgroundPattern) {
    return '';
  }

  const backgroundPatternPattern = getBackgroundPattern(backgroundPattern);

  return css`
    background-image: url(${backgroundPatternPattern});
    background-repeat: repeat;
  `;
};

type SetClipPathProps = {
  isClipped?: boolean;
  isNotClipable?: boolean;
};

export const setClipPath = ({ isClipped, isNotClipable }: SetClipPathProps) => {
  if (!isClipped || isNotClipable) {
    return '';
  }

  return css`
    ${styleUtils.media.orientation.landscape} {
      ${styleUtils.specificity.increaseSpecificity} {
        clip-path: polygon(${CLIPPED_ANGLE}rem 0, 100% 0, 100% 100%, 0% 100%);

        ${styleUtils.media.ie11} {
          clip-path: none;
        }

        ${styleUtils.media.edge} {
          clip-path: none;
        }
      }
    }
  `;
};

type SetHiddenOnMobileProps = {
  isHiddenOnMobile: boolean;
};

export const setHiddenOnMobile = ({ isHiddenOnMobile }: SetHiddenOnMobileProps) => {
  if (!isHiddenOnMobile) {
    return '';
  }

  return css`
    ${styleUtils.media.orientation.portrait} {
      display: none;
    }
  `;
};

type SetTextShadowProps = {
  hasBackgroundImage: boolean;
};

export const setTextShadow = ({ hasBackgroundImage }: SetTextShadowProps) => {
  if (!hasBackgroundImage) {
    return '';
  }

  return css`
    ${styleUtils.font.shadow.default}

    ${styleUtils.media.tablet.small} {
      ${styleUtils.font.shadow.none}
    }
  `;
};
