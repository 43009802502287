import css from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../../utils/styling/styled';

import AppStoreIcon from '../../../../SVG/AppStoreIcon';
import NavigationMenuItemList from '../NavigationMenuItems/NavigationMenuItemList';
import NavigationMenuItemListItem from '../NavigationMenuItems/NavigationMenuItemListItem';
import styleUtils from '../../../../../utils/styling/styleUtils';

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const AppStoreButton = styled(AppStoreIcon)`
  width: 6rem;
`;

export const List = styled(NavigationMenuItemList)`
  margin-top: 2rem;

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.margin.vertical.bottom};
  }

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.vertical.bottomNone};
  }
`;

type ListItemProps = {
  hasBorderTop?: boolean;
};

export const ListItem = styled(NavigationMenuItemListItem)<ListItemProps>`
  ${props =>
    !props.hasBorderTop &&
    css`
      border-top: none;
    `};

  ${styleUtils.specificity.increaseSpecificity} {
    border-bottom: none;
  }
`;

type ListItemAppProps = {
  hasBorderTop?: boolean;
};

export const ListItemApp = styled(NavigationMenuItemListItem)<ListItemAppProps>`
  display: none;

  ${styleUtils.media.touchDevice} {
    display: list-item;
  }

  ${props =>
    !props.hasBorderTop &&
    css`
      border-top: none;
    `};

  ${styleUtils.specificity.increaseSpecificity} {
    border-bottom: none;
  }
`;
