import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLSpanElement>;

/**
 * This adds additional information only for screenreaders.
 */
const VisuallyHiddenSpan = ({ children, ...rest }: Props) => {
  return <S.VisuallyHiddenSpan {...rest}>{children}</S.VisuallyHiddenSpan>;
};

export default VisuallyHiddenSpan;
