import isPropValid from '@emotion/is-prop-valid';

import styled from '../../utils/styling/styled';

import Section from '../Heading/Section';
import styleUtils from '../../utils/styling/styleUtils';
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../types/global';
import {
  setBackgroundColor,
  setBackgroundPattern,
  setClipPath,
  setHiddenOnMobile,
  decidePaddingLeft,
  decidePaddingRight,
} from '../Section/utils';
import { setMarginLeft, setWidth } from './utils';

// Types
import { SectionWidth } from '../../../types/sections';

type ScollSectionProps = {
  backgroundColor?: string | null;
  backgroundPattern?: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  contentPositionY: string;
  index?: number;
  isClipped?: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped?: boolean;
  isNotClipable?: boolean;
  sectionWidth: SectionWidth;
  shouldNotMakeWiderIfNeighborIsClipped?: boolean;
};

export const ScrollSection = styled(Section, { shouldForwardProp: isPropValid })<ScollSectionProps>`
  ${decidePaddingLeft};
  ${decidePaddingRight};
  ${setBackgroundColor};
  ${setBackgroundPattern};
  ${setClipPath};
  ${setHiddenOnMobile};
  ${setWidth};

  /* Flex Container */
  display: flex;

  min-width: auto;

  ${styleUtils.media.orientation.portrait} {
    flex: none;
    height: auto;
    /* The -1px will prevent the background from coming through between sections, especially in dark sections. */
    margin-top: -1px;
    width: 100%;
  }

  ${styleUtils.media.orientation.landscape} {
    ${setMarginLeft};

    flex: 0 0 calc(${props => props.sectionWidth + 1}% + 1px);
    height: 100%;
    max-height: 100vh;
    min-width: ${props => props.sectionWidth + 1}rem;
  }
`;
