import styled from '../../../utils/styling/styled';

import styleUtils from '../../../utils/styling/styleUtils';

export const Text = styled.p`
  ${styleUtils.margin.vertical.bottomQuarter};

  color: ${props => props.theme.color.white};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.media.desktop.xsmall} {
      font-size: 0.9rem;
    }

    ${styleUtils.media.desktop.small} {
      font-size: 1rem;
    }
  }
`;
