import { keyframes } from '@emotion/core';

import styled from '../../../utils/styling/styled';

const mouseWheelAnimation = keyframes`
  0%,
  100% {
      transform: translateY(-2px)
  }

  50% {
      transform: translateY(8px)
  }
`;

export const MouseWheel = styled.path`
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-name: ${mouseWheelAnimation};
  animation-timing-function: ease;
`;
