import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import NavigationMenuCollectionGridItemCollection from '../../../../../../../components/Layout/Navigation/NavigationMenu/NavigationMenuCollectionGrid/NavigationMenuCollectionGridItems/NavigationMenuCollectionGridItemCollection';
import useNavigationContext from '../../../../../../../utils/context/NavigationContext';

// Types
import { NavigationMenuCollectionGridItemCollectionContainerFragment } from './__generated__/NavigationMenuCollectionGridItemCollectionContainerFragment';

export const fragment = graphql`
  fragment NavigationMenuCollectionGridItemCollectionContainerFragment on CMS_Collection {
    name
    collectionPage {
      slug
    }
    cover {
      ...MenuCollectionGridPictureFragment
    }
  }
`;

type Props = {
  collection: NavigationMenuCollectionGridItemCollectionContainerFragment;
};

const NavigationMenuCollectionGridItemCollectionContainer = ({ collection }: Props) => {
  const { closeMenu } = useNavigationContext();

  const coverImage = idx(collection, _ => _.cover.file.childImageSharp.fluid);
  const slug = idx(collection, _ => _.collectionPage.slug);

  if (!coverImage || !collection.name || !slug) {
    return null;
  }

  return <NavigationMenuCollectionGridItemCollection coverImage={coverImage} name={collection.name} onNavigation={closeMenu} slug={slug} />;
};

export default NavigationMenuCollectionGridItemCollectionContainer;
