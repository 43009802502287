import GatsbyImage from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { normalizeFluidObject } from '../utils';

// Types
import { GatsbyImageProps } from 'gatsby-image';

export type FluidImage = {
  __typename: 'ImageSharpFluid';
  aspectRatio: number | null;
  base64?: string | null;
  media?: string | null;
  sizes: string | null;
  src: string | null;
  srcSet: string | null;
  srcSetWebp?: string | null;
  srcWebp?: string | null;
  tracedSVG?: string | null;
};

// This is needed because of: https://github.com/gatsbyjs/gatsby/issues/12149

type Props = {
  fluid?: FluidImage | FluidImage[] | null;
} & Omit<GatsbyImageProps, 'fluid'>;

const GatsbyImageWrapperFluid = ({ fluid, ...rest }: Props) => {
  if (!fluid) {
    return null;
  }

  const normalizedFluid = Array.isArray(fluid) ? fluid.map(normalizeFluidObject) : normalizeFluidObject(fluid);

  return <GatsbyImage fluid={normalizedFluid} {...rest} />;
};

export default GatsbyImageWrapperFluid;
