import styled from '../../../../utils/styling/styled';

import Heading from '../../../Heading';
import Link from '../../../Link';
import styleUtils from '../../../../utils/styling/styleUtils';

type ContentProps = {
  textColor: string;
};

export const Content = styled.div<ContentProps>`
  /* Flex Container */
  display: flex;
  justify-content: space-between;

  color: ${props => props.textColor};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.vertical.bottom};

    /* Flex Container */
    flex-direction: column;

    height: 100%;
    width: 300px;
  }

  ${styleUtils.media.orientation.portrait} {
    /* Flex Container */
    flex-direction: row;
  }
`;

type ContactWrapperProps = {
  textColor: string;
};

export const ContactWrapper = styled.address<ContactWrapperProps>`
  ${styleUtils.padding.vertical.bothQuarter};

  ${styleUtils.media.orientation.portrait} {
    flex: 0 0 45%;
  }
`;

export const ContactHeadline = styled(Heading)`
  ${styleUtils.font.weight.bold};

  font-size: 0.9rem;
  text-transform: uppercase;
`;

export const ContactRow = styled.p`
  font-size: 0.9rem;
`;

export const ContactRowLink = styled(Link)`
  ${styleUtils.animation.navigationListItem};

  font-size: 0.9rem;
  text-transform: lowercase;
`;
