import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLUListElement>;

const NavigationMenuItemList = ({ children, ...rest }: Props) => {
  return <S.List {...rest}>{children}</S.List>;
};

export default NavigationMenuItemList;
