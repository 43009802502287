import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './styles';
import { getChevronFromDirection } from './utils';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  direction: 'down' | 'left' | 'right' | 'up';
} & (HTMLAttributes<HTMLButtonElement>);

const CarouselButton = ({ direction, ...rest }: Props) => {
  const chevron = getChevronFromDirection(direction);

  return (
    <S.Button direction={direction} type="button" {...rest}>
      <FontAwesomeIcon icon={chevron} size="2x" />
    </S.Button>
  );
};

export default CarouselButton;
