import css from '@emotion/css';

import styled from '../../../utils/styling/styled';

import GatsbyImageWrapperFluid from '../GatsbyImageWrapperFluid';
import createOmitProps from '../../../utils/styling/createOmitProps';
import styleUtils from '../../../utils/styling/styleUtils';

type ImageFluidProps = {
  vh?: number;
};

export const ImageFluid = styled(GatsbyImageWrapperFluid, { shouldForwardProp: createOmitProps(['vh']) })<ImageFluidProps>`
  ${props =>
    typeof (props as any).fluid.aspectRatio === 'number' &&
    typeof props.vh === 'number' &&
    css`
      ${styleUtils.media.orientation.landscape} {
        height: ${props.vh}vh;
        width: ${(props as any).fluid.aspectRatio * props.vh}vh;
      }
    `}

  width: 100%;
`;
