import React from 'react';

import * as S from './styles';

// Types
import { FluidImage } from '../GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { GatsbyBackgroundImageFluidProps } from '../GatsbyBackgroundImageWrapperFluid/GatsbyBackgroundImageWrapperFluid';

type Props = {
  backgroundImage: FluidImage;
  caption?: string | null;
} & GatsbyBackgroundImageFluidProps;

const MediaBackgroundImageFluid = ({ alt, backgroundImage, caption, title, ...rest }: Props) => {
  return (
    <S.ImageFluid alt={alt || title || caption || undefined} fluid={backgroundImage} title={title || caption || undefined} {...rest} />
  );
};

export default MediaBackgroundImageFluid;
