import React from 'react';
import useMeasure from 'use-measure';

const MAX_SIZE = 2300;
const MIN_SIZE = 600;
// The minimum amount of difference between the next and previous values to actually re-render.
const SIZE_DIFF_PADDING = 5;

const useCollectionGridSizing = () => {
  // Has to be direct parent of `gridRef`. Used to measure the available height & width.
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  // const gridRef = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState<number>(0);
  const [height, setHeight] = React.useState<number>(0);
  const [returnSize, setReturnSize] = React.useState<number>(0);

  const { height: measuredHeight, width: measuredWidth } = useMeasure(wrapperRef);

  React.useEffect(() => {
    if (measuredHeight < height - SIZE_DIFF_PADDING || measuredHeight > height + SIZE_DIFF_PADDING) {
      setHeight(Math.floor(measuredHeight));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measuredHeight]);

  React.useEffect(() => {
    if (measuredWidth < width - SIZE_DIFF_PADDING || measuredWidth > width + SIZE_DIFF_PADDING) {
      setWidth(Math.floor(measuredWidth));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measuredWidth]);

  React.useEffect(() => {
    const handleResize = () => {
      // Get the smaller value of height or width.
      const minimumSize = Math.min(height, width);
      // Make sure we're inside the bounds of the `MAX_SIZE` and `MIN_SIZE`.
      const nextSize = Math.max(Math.min(minimumSize - 10, MAX_SIZE), MIN_SIZE);

      setReturnSize(nextSize);
    };

    handleResize();
  }, [height, width]);

  return { returnSize, wrapperRef };
};

export default useCollectionGridSizing;
