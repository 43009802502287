import css from '@emotion/css';

const fontShadowDefault = css`
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

const fontShadowStrong = css`
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
`;

const fontShadowNone = css`
  text-shadow: none;
`;

const font = {
  shadow: {
    default: fontShadowDefault,
    strong: fontShadowStrong,
    none: fontShadowNone,
  },
  weight: {
    bold: css`
      font-family: 'FF DIN Web Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      font-weight: 700;
    `,
    regular: css`
      font-family: 'FF DIN Web Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      font-weight: 300;
    `,
  },
};

export default font;
