// If you change something here, think of the `gatsby-node.js`,
// where you might also need to update the paths.

const routes = {
  collection: '/collection',
  designerAndCompany: '/company/designer-and-company',
  globalDistribution: '/global-distribution',
  home: '/',
  imprint: '/imprint',
  pressAndMedia: 'https://press.jan-kath.com',
  pressNewsletter: '/press-newsletter',
  privacyPolicyDe: '/privacy-policy-de',
  privacyPolicyEn: '/privacy-policy-en',
  qualityAndManufacturing: '/company/quality-and-manufacturing',
  search: '/search',
  showrooms: '/showrooms',
  showsAndEvents: '/shows-and-events',
  sustainabilityAndFairtrade: '/company/sustainability-and-fairtrade',
};

export default routes;
