import styled from '../../../../utils/styling/styled';

import LocalLink from '../../../Link';
import styleUtils from '../../../../utils/styling/styleUtils';

export const Link = styled(LocalLink)`
  /* Flex Container */
  align-items: center;
  display: flex;

  background-color: ${props => props.theme.color.brand};
  height: 100%;
  width: 100%;
`;

export const Text = styled.p`
  ${styleUtils.font.weight.bold};

  color: ${props => props.theme.color.white};
  font-size: 0.9rem;
  text-align: center;
  text-transform: uppercase;
`;

type WrapperProps = {
  marginBottom: number;
  marginRight: number;
};

export const Wrapper = styled.div<WrapperProps>`
  ${styleUtils.animation.buttons};

  background-color: ${props => props.theme.color.brand};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  position: fixed;

  z-index: 1;

  height: 75px;
  right: 2rem;
  width: 75px;

  ${styleUtils.media.phone.large} {
    height: 100px;
    width: 100px;
  }

  ${styleUtils.media.desktop.huge} {
    height: 130px;
    width: 130px;
  }

  ${styleUtils.media.desktop.small} {
    right: 4rem;
  }

  ${styleUtils.media.orientation.portrait} {
    bottom: 2rem;
    margin-bottom: ${props => props.marginBottom}px;
  }

  ${styleUtils.media.orientation.landscape} {
    margin-right: ${props => props.marginRight}px;
    top: calc(50% - 50px);
  }
`;
