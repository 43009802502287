import styled from '../../../../utils/styling/styled';

import LocalLink from '../../../Link';
import MediaBackgroundImageFluid from '../../../MediaImage/MediaBackgroundImageFluid';
import styleUtils from '../../../../utils/styling/styleUtils';

export const Cover = styled(MediaBackgroundImageFluid)`
  ${styleUtils.animation.collectionHover};

  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute !important;
  right: 0;
  width: 100%;
`;

export const Link = styled(LocalLink)`
  /* Flex Container */
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;

  position: relative;
`;

export const Shadow = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;

  ${styleUtils.media.ie11} {
    display: none;
  }
`;
