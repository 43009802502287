import React from 'react';

import SectionItemFooterSocialListItem from './SectionItemFooterSocialListItem';
import SectionItemFooterSocialListItemNewsletter from './SectionItemFooterSocialListItemNewsletter';
import envConfig from '../../../../config/env';
import routes from '../../../../config/routes';

import * as S from './styles';

type Props = {
  architonicUrl: string | null;
  facebookUrl: string | null;
  houzzUrl: string | null;
  instagramUrl: string | null;
  textColor: string;
};

const SectionItemFooterSocial = ({ architonicUrl, facebookUrl, houzzUrl, instagramUrl, textColor }: Props) => {
  return (
    <S.Wrapper>
      <S.Title>Visit Jan Kath On</S.Title>

      <ul>
        {!!facebookUrl && <SectionItemFooterSocialListItem icon={<S.Facebook textColor={textColor} />} name="Facebook" url={facebookUrl} />}

        {!!instagramUrl && (
          <SectionItemFooterSocialListItem icon={<S.Instagram textColor={textColor} />} name="Instagram" url={instagramUrl} />
        )}

        {!!houzzUrl && <SectionItemFooterSocialListItem icon={<S.Houzz textColor={textColor} />} name="Houzz" url={houzzUrl} />}

        {!!architonicUrl && (
          <SectionItemFooterSocialListItem icon={<S.Architonic textColor={textColor} />} name="Architonic" url={architonicUrl} />
        )}

        {/* TODO: Enable in production when ready. */}
        {envConfig.isDev && <SectionItemFooterSocialListItemNewsletter />}

        <SectionItemFooterSocialListItem name="Imprint" title="Go to imprint page" url={routes.imprint} />

        <SectionItemFooterSocialListItem name="Privacy Policy" title="Go to privacy policy page" url={routes.privacyPolicyDe} />
      </ul>
    </S.Wrapper>
  );
};

export default SectionItemFooterSocial;
