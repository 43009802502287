import React from 'react';

import * as S from './styles';

type Props = {
  marginBottom: number;
  marginRight: number;
  url: string;
};

const CollectionOverviewButton = ({ marginBottom, marginRight, url }: Props) => {
  return (
    <S.Wrapper marginBottom={marginBottom} marginRight={marginRight}>
      <S.Link url={url}>
        <S.Text>View Collection</S.Text>
      </S.Link>
    </S.Wrapper>
  );
};

export default CollectionOverviewButton;
