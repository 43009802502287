import css from '@emotion/css';
import isPropValid from '@emotion/is-prop-valid';

import styled from '../../../../../../../utils/styling/styled';

import CollectionCoverName from '../../../../../../Collection/CollectionItems/CollectionCover/CollectionCoverName';
import NavigationCollectionChevron from '../../../../../../SVG/NavigationCollectionChevron';
import styleUtils from '../../../../../../../utils/styling/styleUtils';

export const Button = styled.button`
  ${styleUtils.animation.collectionGridButtonHover};
  ${styleUtils.grid.gap};
  ${styleUtils.ratio.oneToOne};

  /* Flex Container */
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: start;

  background-color: rgba(0, 0, 0, 0.3);
  flex-basis: 30%;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 32%;
  min-height: 100px;
  min-width: 100px;
  position: relative;

  &:focus {
    outline: 0;
  }
  ${styleUtils.media.desktop.xsmall} {
    max-width: 28%;
  }
  ${styleUtils.media.desktop.large} {
    max-width: 32%;
  }
`;

type ChevronProps = {
  arrowDirection: 'left' | 'right';
};

export const Chevron = styled(NavigationCollectionChevron, { shouldForwardProp: isPropValid })<ChevronProps>`
  ${styleUtils.margin.horizontal.leftHalf};

  color: ${props => props.theme.color.brand};
  height: 2rem;
  width: 2rem;

  ${props =>
    props.arrowDirection === 'left' &&
    css`
      transform: rotate(-180deg);
    `}
`;

export const ContentWrapper = styled.div`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: row;

  width: 100%;
`;

export const Text = styled(CollectionCoverName)`
  font-size: 1rem;
  text-align: start;
`;
