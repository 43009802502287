import styled from '@emotion/styled';

import theme from './theme';

// Types
import { CreateStyled } from '@emotion/styled';

type ThemeType = typeof theme;

export type ThemeProps = {
  theme: ThemeType;
};

export default styled as CreateStyled<ThemeType>;
