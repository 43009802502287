import React from 'react';

import { animated, useSpring } from '../../../../../utils/styling/spring';

import * as S from './styles';

type Props = {
  isMenuButtonHovering: boolean;
  isMenuOpen: boolean;
};

const NavigationMenuButtonHamburger = ({ isMenuButtonHovering, isMenuOpen }: Props) => {
  const burgerSpring = useSpring({ transform: !isMenuButtonHovering ? 'scale(1)' : 'scale(1.15)' });

  const tomatoSpring = useSpring({
    height: !isMenuOpen ? 15 : 20,
    transform: !isMenuOpen ? 'rotate(0deg) translateY(0) translateX(0)' : 'rotate(45deg) translateY(0) translateX(0)',
    transformOrigin: '15% 35%',
  });

  const cheeseSpring = useSpring({
    height: 15,
    opacity: !isMenuOpen ? 1 : 0,
  });

  const pattySpring = useSpring({
    height: !isMenuOpen ? 15 : 20,
    transform: !isMenuOpen ? 'rotate(0deg) translateY(0) translateX(0)' : 'rotate(-45deg) translateY(0) translateX(0)',
    transformOrigin: '11% 69%',
  });

  return (
    <>
      <S.Hamburger style={burgerSpring} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <animated.rect width="90" y="13" rx="8" x="5" style={tomatoSpring} />

        <animated.rect width="90" y="43" rx="8" x="5" style={cheeseSpring} />

        <animated.rect width="90" y="73" rx="8" x="5" style={pattySpring} />
      </S.Hamburger>
    </>
  );
};

export default NavigationMenuButtonHamburger;
