import React from 'react';
import { Generic, JSONLD } from 'react-structured-data';
import { graphql, useStaticQuery } from 'gatsby';

import buildNavigationListItem from './buildNavigationListItem';
import notNullOrUndefined from '../misc/notNullOrUndefined';
import routes from '../../config/routes';

// Types
import { NavigationStructuredDataQuery } from './__generated__/NavigationStructuredDataQuery';

type NormalizePageInput = {
  index: number;
  slug: string;
  title: string;
};

const normalizePage = ({ index, slug, title }: NormalizePageInput) => {
  const navigationListItem = buildNavigationListItem({ name: title, position: index + 1, url: slug });

  return navigationListItem;
};

type Props = {};
const NavigationStructuredData = (_props: Props) => {
  const data = useStaticQuery<NavigationStructuredDataQuery>(graphql`
    query NavigationStructuredDataQuery {
      cms {
        contentpages {
          slug
          title
        }
        collectionpages {
          slug
          title
        }
        showroompages {
          slug
          title
        }
      }
    }
  `);

  if (!data) {
    throw Error(`Something went wrong.`);
  }

  const staticPages = [
    {
      slug: routes.home,
      title: 'JAN KATH',
    },
    {
      // TODO: Save somewhere
      slug: routes.search,
      title: 'Search',
    },
    {
      // TODO: Save somewhere
      slug: routes.imprint,
      title: 'Imprint',
    },
    {
      // TODO: Save somewhere
      slug: routes.privacyPolicyDe,
      title: 'Datenschutzerklärung',
    },
    {
      // TODO: Save somewhere
      slug: routes.privacyPolicyEn,
      title: 'Privacy Policy',
    },
    {
      // TODO: Save somewhere
      slug: routes.pressAndMedia,
      title: 'Press & Media',
    },
  ];
  const contentPages = (data.cms.contentpages || []).filter(notNullOrUndefined);
  const collectionPages = (data.cms.collectionpages || []).filter(notNullOrUndefined);
  const showroomPages = (data.cms.showroompages || []).filter(notNullOrUndefined);

  const allPages = [...staticPages, ...contentPages, ...collectionPages, ...showroomPages];

  const navigationListItems = allPages
    // Filter out the homepage
    .filter(({ slug }) => slug !== 'home')
    .map(({ slug, title }, index) => normalizePage({ index, slug, title }));

  const schema = {
    itemListElement: navigationListItems,
    name: 'SiteNavigation',
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="ItemList" schema={schema} />
    </JSONLD>
  );
};

export default NavigationStructuredData;
