import React from 'react';

import * as S from './styles';
import ieButton from './assets/button_ie.png';

type Props = {
  onMenuButtonClick: () => void;
};

const NavigationMenuButtonIe = ({ onMenuButtonClick }: Props) => {
  return (
    <S.Button onClick={onMenuButtonClick} type="button">
      <S.Hamburger src={ieButton} />
    </S.Button>
  );
};

export default NavigationMenuButtonIe;
