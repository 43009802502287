import React from 'react';

import NavigationMenuButtonHamburger from './NavigationMenuButtonHamburger';
import VisuallyHiddenSpan from '../../../VisuallyHiddenSpan';

import * as S from './styles';

type Props = {
  isMenuOpen: boolean;
  isMenuButtonHovering: boolean;
  onMenuButtonClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const NavigationMenuButton = ({ isMenuOpen, isMenuButtonHovering, onMenuButtonClick, onMouseEnter, onMouseLeave }: Props) => {
  return (
    <S.Button onClick={onMenuButtonClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} type="button">
      <VisuallyHiddenSpan>{!isMenuOpen ? 'Open Menu' : 'Close Menu'}</VisuallyHiddenSpan>

      <NavigationMenuButtonHamburger isMenuButtonHovering={isMenuButtonHovering} isMenuOpen={isMenuOpen} />
    </S.Button>
  );
};

export default NavigationMenuButton;
