import styled from '../../../utils/styling/styled';

import Link from '../../Link';
import LogoSVG from '../../SVG/Logo';
import TitleHeader from '../../TitleHeader';
import createOmitProps from '../../../utils/styling/createOmitProps';
import styleUtils from '../../../utils/styling/styleUtils';

type HeaderProps = {
  isTitleHeaderVisible: boolean;
};

export const Header = styled.header<HeaderProps>`
  /* Flex Container */
  align-items: flex-end;
  display: flex;

  position: fixed;
  pointer-events: none;
  width: 100vw;

  z-index: 2;

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.right};

    /* Flex Container */
    justify-content: ${props => (!props.isTitleHeaderVisible ? 'flex-end' : 'space-between')};

    height: 9rem;

    ${styleUtils.media.desktop.large} {
      ${styleUtils.padding.horizontal.rightDouble};
    }
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.right};

    /* Flex Container */
    justify-content: flex-end;

    height: 6rem;

    ${styleUtils.media.phone.large} {
      ${styleUtils.padding.horizontal.right};
      height: 9rem;
    }
  }

  *:not(svg) {
    pointer-events: all;
  }
`;

export const StyledTitleHeader = styled(TitleHeader)`
  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.vertical.bottomHalf};

    /* Flex Container */
    display: flex;
    flex-direction: column;

    position: relative;
  }

  ${styleUtils.media.orientation.portrait} {
    display: none;
  }
`;

export const LogoLink = styled(Link)`
  margin-left: auto;
  z-index: 50;
`;

type LogoProps = {
  logoColor?: string | null;
};

export const Logo = styled(LogoSVG, { shouldForwardProp: createOmitProps(['logoColor']) })<LogoProps>`
  bottom: 0;
  fill: ${props => (!props.logoColor ? props.theme.color.white : props.logoColor)};
  right: 0;
  transition: fill 500ms ease-in-out;

  ${styleUtils.media.orientation.landscape} {
    height: 75px;
    width: 75px;

    ${styleUtils.media.desktop.large} {
      height: 100px;
      width: 100px;
    }
  }

  ${styleUtils.media.orientation.portrait} {
    height: 50px;
    width: 50px;

    ${styleUtils.media.phone.large} {
      height: 100px;
      width: 100px;
    }
  }
`;
