import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

export const Award = styled.img`
  ${styleUtils.animation.rugAward};

  ${styleUtils.media.orientation.portrait} {
    max-width: 80px;

    ${styleUtils.media.phone.large} {
      max-width: 100px;
    }

    ${styleUtils.media.tablet.small} {
      max-width: 90px;
    }
  }

  ${styleUtils.media.orientation.landscape} {
    max-width: 90px;

    ${styleUtils.media.tablet.small} {
      max-width: 100px;
    }

    ${styleUtils.media.desktop.large} {
      max-width: 140px;
    }

    ${styleUtils.media.desktop.huge} {
      max-width: 200px;
    }
  }
`;
