import React from 'react';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import * as S from './styles';

// Types
import { ButtonHTMLAttributes } from 'react';

type Props = {
  buttonText: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const NewsletterSubscribeButton = ({ buttonText, ...rest }: Props) => {
  return (
    <S.Button type="submit" {...rest}>
      <S.Icon icon={faEnvelope} size="sm" /> {buttonText}
    </S.Button>
  );
};

export default NewsletterSubscribeButton;
