import React from 'react';

import HeadingContext from '../../../utils/context/HeadingContext';

// Types
import { HTMLAttributes, ReactHTML, ReactNode } from 'react';

type Props = {
  as?: keyof ReactHTML;
  children: ReactNode;
  hasNoTag?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Section = React.forwardRef<HTMLDivElement, Props>(({ as = 'section', children, hasNoTag, ...rest }: Props, ref) => {
  return (
    <HeadingContext.Consumer>
      {level => {
        const Tag = as as 'section';

        return (
          <HeadingContext.Provider value={level + 1}>
            {!hasNoTag ? (
              <Tag ref={ref} {...rest}>
                {children}
              </Tag>
            ) : (
              children
            )}
          </HeadingContext.Provider>
        );
      }}
    </HeadingContext.Consumer>
  );
});

export default Section;
