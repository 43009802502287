import React from 'react';

import NavigationMenuMenu from './NavigationMenuMenu';
import useNavigationContext from '../../../../utils/context/NavigationContext';
import { useSpring } from '../../../../utils/styling/spring';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  collectionGrid: ReactNode;
  showrooms: ReactNode;
  social: ReactNode;
};

// We also store the `isMenuOpen` locally, so we can handle the close animation.
// When the menu is closed, the spring will animated to 0 opacity and callback
// to actually hide it (instead of just having it invisible but still clickable etc.).
const NavigationMenu = ({ collectionGrid, showrooms, social }: Props) => {
  const { isMenuOpen } = useNavigationContext();
  const [isMenuHidden, setIsMenuHidden] = React.useState(isMenuOpen);

  const handleAnimationEnd = () => {
    if (!isMenuOpen) {
      setIsMenuHidden(true);
    }
  };

  React.useEffect(() => {
    if (isMenuOpen) {
      setIsMenuHidden(!isMenuOpen);
    }
  }, [isMenuOpen]);

  const menuSpring = useSpring({
    config: { mass: 1, tension: 580, friction: 100 },
    onRest: handleAnimationEnd,
    opacity: !isMenuOpen ? 0 : 0.99,
    pointerEvents: !isMenuOpen ? 'none' : 'all',
  });

  if (isMenuHidden) {
    return null;
  }

  return (
    <S.Menu style={menuSpring}>
      <S.InnerMenu>
        {collectionGrid}

        <S.NavigationWrapper>
          <NavigationMenuMenu showrooms={showrooms} />

          {social}
        </S.NavigationWrapper>
      </S.InnerMenu>
    </S.Menu>
  );
};

export default NavigationMenu;
