import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../utils/styling/styled';

import styleUtils from '../../../utils/styling/styleUtils';
import { animated } from '../../../utils/styling/spring';

export const Button = styled.button`
  ${styleUtils.font.weight.bold};
  ${styleUtils.padding.horizontal.bothHalf};
  ${styleUtils.margin.vertical.top};

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
  margin-left: auto;
  margin-right: auto;
  min-width: 80px;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  width: 8vw;

  &:focus {
    outline: 0;
  }
`;

export const Headline = styled.h2`
  ${styleUtils.font.weight.bold};
  ${styleUtils.margin.vertical.topHalf};

  color: white;
  font-size: 16px;
  max-width: 100%;

  a {
    text-decoration: underline;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color.white};
  margin-left: auto;
  margin-right: auto;
`;

export const Text = styled.p`
  ${styleUtils.margin.vertical.topHalf};
  ${styleUtils.font.weight.bold};

  color: white;
  max-width: 100%;
  font-size: 14px;

  a {
    font-size: 14px;
    text-decoration: underline;
  }
`;

export const Wrapper = styled(animated.dialog)`
  ${styleUtils.padding.horizontal.both};
  ${styleUtils.padding.vertical.bothHalf};

  /* Flex Container */
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  background-color: ${props => props.theme.color.brand};
  height: 300px;
  left: calc(50% - 200px);
  position: absolute;
  top: calc(50% - 150px);
  width: 400px;
  z-index: 23;

  display: none;

  ${styleUtils.media.ie11} {
    /* Flex Container */
    display: flex;
  }
`;
