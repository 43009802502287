/* eslint-disable no-await-in-loop */
/* eslint-disable no-constant-condition */

import React from 'react';

import TutorialMenu from '../../SVG/TutorialMenu';
import TutorialMouse from '../../SVG/TutorialMouse';
import delay from '../../../utils/misc/delay';
import envConfig from '../../../config/env';
import usePermanentToggle from '../../../utils/hooks/usePermanentToggle';
import useSelfDestroyingOnScroll from '../../../utils/hooks/useSelfDestroyingOnScroll';
import useLocalStorage from '../../../utils/hooks/useLocalStorage';
import { animated, config, useSpring } from '../../../utils/styling/spring';

import * as S from './styles';

const ANIMATION_CYCLE_LENGTH = 4000;

type Props = {};

const Tutorial = (_props: Props) => {
  const [isVisible, toggleIsVisible] = usePermanentToggle(true);
  // We want to fade the tutorial out before removing it from the DOM.
  // So we toggle the opacity through `isVisible` and the DOM on `isTutorialWatched`.
  // This also helps with saving whether the tutorial should be initially visible or not in local storage.
  const [isTutorialWatched, setIsTutorialWatched] = useLocalStorage(envConfig.lsKeyTutorialWatched, false);

  const permanentlyToggleIsTutorialWatched = () => {
    if (!isTutorialWatched) {
      setIsTutorialWatched(true);
    }
  };

  const handleScroll = () => {
    toggleIsVisible();
  };

  const handleVisibilityAnimationEnd = () => {
    if (!isVisible) {
      permanentlyToggleIsTutorialWatched();
    }
  };

  useSelfDestroyingOnScroll(handleScroll);

  const animationWrapperSpring = useSpring({
    config: config.slow,
    from: { transform: 'translateY(0px)' },
    to: async (next: any) => {
      while (true) {
        await delay(ANIMATION_CYCLE_LENGTH);
        await next({ transform: 'translateY(-400px)' });
        await delay(ANIMATION_CYCLE_LENGTH);
        await next({ transform: 'translateY(0px)' });
        await delay(ANIMATION_CYCLE_LENGTH);
      }
    },
  });

  const tutorialSpring = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: !isVisible ? 0 : 1 },
    onRest: handleVisibilityAnimationEnd,
  });

  if (isTutorialWatched) {
    return null;
  }

  return (
    <S.TutorialWrapper onClick={handleScroll} style={tutorialSpring}>
      <animated.div style={animationWrapperSpring}>
        <S.Section>
          <TutorialMouse />

          <S.Text>Please scroll...</S.Text>
        </S.Section>

        <S.Section>
          <TutorialMenu />

          <S.Text>Or use the menu</S.Text>
        </S.Section>
      </animated.div>
    </S.TutorialWrapper>
  );
};

export default Tutorial;
