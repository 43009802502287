import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  level?: 1 | 2 | 3 | 4;
} & HTMLAttributes<HTMLHeadingElement>;

const StaticPageHeadline = ({ children, level = 1, ...rest }: Props) => {
  switch (level) {
    case 1:
      return <S.Heading1 {...rest}>{children}</S.Heading1>;

    case 2:
      return <S.Heading2 {...rest}>{children}</S.Heading2>;

    case 3:
      return <S.Heading3 {...rest}>{children}</S.Heading3>;

    case 4:
      return <S.Heading4 {...rest}>{children}</S.Heading4>;

    default:
      return null;
  }
};

export default StaticPageHeadline;
