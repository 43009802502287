import styled from '../../../utils/styling/styled';

import MediaBackgroundImageFluid from '../../MediaImage/MediaBackgroundImageFluid';
import createOmitProps from '../../../utils/styling/createOmitProps';
import styleUtils from '../../../utils/styling/styleUtils';
import {
  setClipPath,
  makePortraitAutoHeightIfNoBackgroundImage,
  setHiddenOnMobile,
  decidePaddingLeft,
  decidePaddingRight,
} from '../../Section/utils';
import { setMarginLeft, setWidth } from '../utils';

// Types
import { SectionWidth } from '../../../../types/sections';

type ScollSectionProps = {
  contentPositionY: string;
  index?: number;
  isClipped?: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped?: boolean;
  isNotClipable?: boolean;
  sectionWidth: SectionWidth;
  shouldNotAutoHeight?: boolean;
  shouldNotMakeWiderIfNeighborIsClipped?: boolean;
};

export const ScrollSection = styled(MediaBackgroundImageFluid, {
  shouldForwardProp: createOmitProps([
    'contentPositionX',
    'contentPositionY',
    'hasExtraMarginTop',
    'isClipped',
    'isHiddenOnMobile',
    'isNeighborClipped',
    'isNotClipable',
    'sectionWidth',
    'shouldNotAutoHeight',
    'shouldNotMakeWiderIfNeighborIsClipped',
  ]),
})<ScollSectionProps>`
  ${decidePaddingLeft};
  ${decidePaddingRight};
  ${makePortraitAutoHeightIfNoBackgroundImage};
  ${setClipPath};
  ${setHiddenOnMobile};
  ${setWidth};

  /* Flex Container */
  display: flex;

  min-width: auto;

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.specificity.increaseSpecificity} {
      ${props => props.index === 0 && styleUtils.padding.vertical.topDouble};
    }

    flex: none;
    /* The -1px will prevent the background from coming through between sections, especially in dark sections. */
    margin-top: -1px;
    width: 100%;

    min-height: 50vh !important;

    ${styleUtils.media.tablet.small} {
      min-height: 56.25vw !important;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      }
    }
  }

  ${styleUtils.media.orientation.landscape} {
    ${setMarginLeft};

    height: 100%;
    max-height: 100vh;
  }
`;
