import React from 'react';

import Heading from '../../Heading';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  contact: ReactNode;
  isHiddenOnMobile: boolean;
  social: ReactNode;
  textColor: string;
};

const SectionFooterContact = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  contact,
  isHiddenOnMobile,
  social,
  textColor,
}: Props) => {
  return (
    <S.Wrapper
      as="footer"
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionY="center"
      isCritical={true}
      isHiddenOnMobile={isHiddenOnMobile}
      isNotClipable={true}
      shouldNotAutoHeight={true}
      shouldNotMakeWiderIfNeighborIsClipped={true}
    >
      <Heading isVisuallyHidden={true}>Contact Information</Heading>

      <S.Content textColor={textColor}>
        <S.ContactWrapper textColor={textColor}>{contact}</S.ContactWrapper>

        {social}
      </S.Content>
    </S.Wrapper>
  );
};

export default SectionFooterContact;
