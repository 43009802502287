import React from 'react';

import theme from '../styling/theme';

// Types
import { RefObject } from 'react';

const SAFE_ZONE_BOTTOM = theme.section.carousel.portrait.height + theme.section.footer.portrait.height;
const SAFE_ZONE_RIGHT = theme.section.carousel.landscape.width + theme.section.footer.landscape.width;
const SAFE_ZONE_PADDING = 30;

type UseStickRightInput = {
  containerRef: RefObject<HTMLDivElement>;
  isDisabled: boolean;
};

const useSticky = ({ containerRef, isDisabled }: UseStickRightInput) => {
  const [marginBottom, setMarginBottom] = React.useState<number>(0);
  const [marginRight, setMarginRight] = React.useState<number>(0);

  React.useEffect(() => {
    const calculateMarginBottom = () => {
      if (!!containerRef.current) {
        const { clientHeight, scrollHeight, scrollTop } = containerRef.current;

        const howMuchOvershot = scrollHeight - (scrollTop + clientHeight + SAFE_ZONE_BOTTOM + SAFE_ZONE_PADDING);

        if (howMuchOvershot <= 0) {
          setMarginBottom(howMuchOvershot * -1);
        } else {
          setMarginBottom(0);
        }
      }
    };

    const calculateMarginRight = () => {
      if (!!containerRef.current) {
        const { clientWidth, scrollLeft, scrollWidth } = containerRef.current;

        const howMuchOvershot = scrollWidth - (scrollLeft + clientWidth + SAFE_ZONE_RIGHT + SAFE_ZONE_PADDING);

        if (howMuchOvershot <= 0) {
          setMarginRight(howMuchOvershot * -1);
        } else {
          setMarginRight(0);
        }
      }
    };

    const calculateMargin = () => {
      calculateMarginBottom();
      calculateMarginRight();
    };

    if (!isDisabled) {
      calculateMargin();
    }

    // The ref might changed when the cleanup function runs,
    // so React suggest to put it into it's own variable inside the effect.
    const currentContainerRef = containerRef.current;

    if (!isDisabled && !!currentContainerRef && typeof window !== 'undefined') {
      currentContainerRef.addEventListener('scroll', calculateMargin);
      window.addEventListener('resize', calculateMargin);
    }

    return () => {
      if (!!currentContainerRef && typeof window !== 'undefined') {
        currentContainerRef.removeEventListener('scroll', calculateMargin);
        window.removeEventListener('resize', calculateMargin);
      }
    };
  });

  return { marginBottom, marginRight };
};

export default useSticky;
