import React from 'react';

import * as S from './styles';

// Types

type Props = {
  arrowDirection: 'left' | 'right';
  onClick: () => void;
};

const NavigationMenuCollectionGridItemPaginationButton = ({ arrowDirection, onClick }: Props) => {
  return (
    <S.Button onClick={onClick}>
      <S.ContentWrapper>
        <S.Chevron arrowDirection={arrowDirection} />

        <S.Text id="name">
          {arrowDirection === 'left' ? 'Previous' : 'Further'}
          <br />
          Collections
        </S.Text>
      </S.ContentWrapper>
    </S.Button>
  );
};

export default NavigationMenuCollectionGridItemPaginationButton;
