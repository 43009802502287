import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  isChecked: boolean;
  label: ReactNode;
  onClick: () => void;
  title: string;
};

const FormCheckbox = ({ isChecked, label, onClick, title }: Props) => {
  return (
    <S.Wrapper>
      <S.ActualCheckbox checked={isChecked} id="checkbox" onChange={onClick} title={title} type="checkbox" />

      <S.Checkbox isChecked={isChecked} onClick={onClick}>
        {isChecked && <S.Checkmark icon={faCheck} />}
      </S.Checkbox>

      <S.Label>{label}</S.Label>
    </S.Wrapper>
  );
};

export default FormCheckbox;
