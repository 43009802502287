import styled from '../../../../../../../utils/styling/styled';

export const Wrapper = styled.section`
  /* Flex Container */
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  width: auto;
`;
