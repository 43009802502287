import React from 'react';
import Helmet from 'react-helmet';

type Props = {
  cardType?: string | null;
};

const SEOTwitter = ({ cardType }: Props) => {
  return (
    <>
      <Helmet>{!!cardType && <meta name="twitter:card" content={cardType} />}</Helmet>
    </>
  );
};

export default SEOTwitter;
