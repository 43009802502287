import React from 'react';

import Heading from '../../Heading';
import Section from '../../Heading/Section';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import routes from '../../../config/routes';
import SectionFooterShowroomsShowroom from './SectionFooterShowroomsShowroom';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  isHiddenOnMobile: boolean;
  showrooms: ReactNode;
  social: ReactNode;
  textColor: string;
};

const SectionFooterShowrooms = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  isHiddenOnMobile,
  showrooms,
  social,
  textColor,
}: Props) => {
  return (
    <S.Wrapper
      as="footer"
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionY="center"
      isCritical={true}
      isHiddenOnMobile={isHiddenOnMobile}
      isNotClipable={true}
      shouldNotAutoHeight={true}
      shouldNotMakeWiderIfNeighborIsClipped={true}
      textColor={textColor}
    >
      <Heading isVisuallyHidden={true}>Experience the collection at Jan Kath showrooms.</Heading>

      <S.Content textColor={textColor}>
        <S.ShowroomsWrapper textColor={textColor}>
          <S.ShowroomsTitle aria-hidden={true}>
            Experience the collection
            <br />
            at Jan Kath showrooms.
          </S.ShowroomsTitle>
          <Heading isVisuallyHidden={true}>Showrooms:</Heading>

          <Section>
            <S.ShowroomsList>
              {showrooms}

              <SectionFooterShowroomsShowroom hasExtraTopMargin={true} name="Global Distribution" url={routes.globalDistribution} />
            </S.ShowroomsList>
          </Section>
        </S.ShowroomsWrapper>

        {social}
      </S.Content>
    </S.Wrapper>
  );
};

export default SectionFooterShowrooms;
