import css from '@emotion/css';

import media from './media';

export const normalizeButton = css`
  appearance: none;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
`;

export const normalizeHr = css`
  border-style: solid;
  border-width: 1px;
  margin: 0;
  padding: 0;
`;

export const normalizeUl = css`
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;

  /* IE11 Fix */
  ${media.ie11} {
    width: 100%;
  }
`;

const normalizers = {
  normalizeButton,
  normalizeHr,
  normalizeUl,
};

export default normalizers;
