import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { isInternalLink } from './utils';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  asExternal?: boolean;
  children: ReactNode;
  state?: any;
  target?: string;
  url?: string | null;
} & HTMLAttributes<HTMLAnchorElement>;

const Link = ({ asExternal, children, state, target, url, ...rest }: Props) => {
  if (typeof url !== 'string') {
    return <>{children}</>;
  }

  if (!asExternal && isInternalLink(url)) {
    return (
      // @ts-ignore
      <GatsbyLink to={url} state={state} {...rest}>
        {children}
      </GatsbyLink>
    );
  }

  const rel = target === '_blank' || asExternal ? 'noopener noreferrer' : undefined;

  return (
    <a href={url} rel={rel} target={asExternal ? '_blank' : target} {...rest}>
      {children}
    </a>
  );
};

export default Link;
