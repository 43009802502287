import React from 'react';
import { globalHistory } from '@reach/router';
import { withPrefix } from 'gatsby';

import routes from '../../config/routes';

// Types
import { Dispatch, ReactNode } from 'react';

enum HeaderContextActionType {
  SetBackButtonUrl = 'SET_BACK_BUTTON_URL',
  SetHeader = 'SET_HEADER',
  SetSubtitle = 'SET_SUBTITLE',
  SetTitle = 'SET_TITLE',
}

type HeaderContextAction =
  | { type: HeaderContextActionType.SetBackButtonUrl; payload: string | null }
  | {
      type: HeaderContextActionType.SetHeader;
      payload: { backButtonTitle?: string | null; backButtonUrl?: string | null; subtitle?: string | null; title?: string | null };
    }
  | { type: HeaderContextActionType.SetSubtitle; payload: string | null }
  | { type: HeaderContextActionType.SetTitle; payload: string | null };

type State = {
  backButtonTitle: string;
  backButtonUrl: string;
  subtitle: string | null;
  title: string | null;
};

type Context = {
  dispatch: Dispatch<HeaderContextAction> | null;
  state: State;
};

const initialState: State = {
  backButtonTitle: 'Back',
  backButtonUrl: routes.home,
  subtitle: null,
  title: null,
};

const reducer = (state: State, action: HeaderContextAction) => {
  switch (action.type) {
    case HeaderContextActionType.SetBackButtonUrl:
      return {
        ...state,
        backButtonUrl: action.payload || initialState.backButtonUrl,
      };

    case HeaderContextActionType.SetHeader:
      return {
        ...state,
        backButtonTitle: typeof action.payload.backButtonTitle === 'string' ? action.payload.backButtonTitle : initialState.backButtonTitle,
        backButtonUrl: typeof action.payload.backButtonUrl === 'string' ? action.payload.backButtonUrl : initialState.backButtonUrl,
        subtitle: typeof action.payload.subtitle === 'string' ? action.payload.subtitle : null,
        title: typeof action.payload.title === 'string' ? action.payload.title : null,
      };

    case HeaderContextActionType.SetSubtitle:
      return {
        ...state,
        subtitle: action.payload,
      };

    case HeaderContextActionType.SetTitle:
      return {
        ...state,
        title: action.payload,
      };

    default:
      return state;
  }
};

const HeaderContext = React.createContext<Context>({ state: initialState, dispatch: null });

type HeaderContextProviderProps = {
  children: ReactNode;
};

export const HeaderContextProvider = ({ children }: HeaderContextProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <HeaderContext.Provider value={{ dispatch, state }}>{children}</HeaderContext.Provider>;
};

type SetHeaderInput = { backButtonTitle?: string | null; backButtonUrl?: string | null; subtitle?: string | null; title?: string | null };

const useHeaderContext = () => {
  const {
    dispatch,
    state: { backButtonTitle, backButtonUrl, subtitle, title },
  } = React.useContext(HeaderContext);

  const pathname = globalHistory.location.pathname;

  const isTitleHeaderVisible = pathname.includes('/overview') || pathname.includes(withPrefix(routes.search));

  const setBackButtonUrl = (nextBackButtonUrl: string | null) => {
    if (!!dispatch) {
      dispatch({ type: HeaderContextActionType.SetBackButtonUrl, payload: nextBackButtonUrl });
    }
  };

  const setHeader = ({
    backButtonTitle: nextBackButtonTitle,
    backButtonUrl: nextBackButtonUrl,
    subtitle: nextSubtitle,
    title: nextTitle,
  }: SetHeaderInput) => {
    if (!!dispatch) {
      dispatch({
        type: HeaderContextActionType.SetHeader,
        payload: { backButtonTitle: nextBackButtonTitle, backButtonUrl: nextBackButtonUrl, subtitle: nextSubtitle, title: nextTitle },
      });
    }
  };

  const setSubtitle = (nextSubtitle: string | null) => {
    if (!!dispatch) {
      dispatch({ type: HeaderContextActionType.SetSubtitle, payload: nextSubtitle });
    }
  };

  const setTitle = (nextTitle: string | null) => {
    if (!!dispatch) {
      dispatch({ type: HeaderContextActionType.SetTitle, payload: nextTitle });
    }
  };

  return { backButtonTitle, backButtonUrl, isTitleHeaderVisible, setBackButtonUrl, setHeader, setSubtitle, setTitle, subtitle, title };
};

export default useHeaderContext;
