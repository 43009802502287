import React from 'react';

import theme from '../../../utils/styling/theme';

import * as S from './styles';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const TutorialMenu = (props: Props) => {
  return (
    <svg height="114.834" width="188.834" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M183.417 0h-178A5.423 5.423 0 0 0 0 5.417v104a5.423 5.423 0 0 0 5.417 5.417h178a5.423 5.423 0 0 0 5.417-5.417v-104A5.422 5.422 0 0 0 183.417 0zM186 109.417a2.586 2.586 0 0 1-2.583 2.583h-178a2.586 2.586 0 0 1-2.583-2.583V41.666L32 12.501h154v96.916zM5.6 18.155h9.877a1 1 0 0 1 0 2H5.6a1 1 0 0 1 0-2zm-1-3.074a1 1 0 0 1 1-1h9.877a1 1 0 0 1 0 2H5.6a1 1 0 0 1-1-1zm1 7.149h9.877a1 1 0 0 1 0 2H5.6a1 1 0 0 1 0-2zM186 9.666H2.835V5.417a2.586 2.586 0 0 1 2.583-2.583h178a2.586 2.586 0 0 1 2.583 2.583v4.249z"
        fill={theme.color.white}
      />

      <S.Cursor
        d="M95.726 69.645a.998.998 0 0 1-.899-.562l-3.573-7.311-3.914 3.555a.999.999 0 0 1-1.672-.74V45.333a.999.999 0 0 1 1.616-.787l15.214 11.926a1 1 0 0 1-.449 1.773l-5.181.881 3.545 7.227a.999.999 0 0 1-.459 1.338l-3.791 1.853a.987.987 0 0 1-.437.101z"
        fill={theme.color.white}
      />
    </svg>
  );
};

export default TutorialMenu;
