import styled from '../../utils/styling/styled';

import styleUtils from '../../utils/styling/styleUtils';

export const BannersWrapper = styled.div`
  /* Flex Container*/
  display: flex;
  flex-direction: column;

  bottom: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  z-index: 11;

  > {
    &:last-of-type {
      margin-top: 0.25rem;
    }
  }
`;

type ScrollContainerProps = {
  backgroundColor?: string;
  isMenuOpen: boolean;
};

export const ScrollContainer = styled.main<ScrollContainerProps>`
  /* Flex Container */
  display: flex;

  background-color: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.color.black)};

  ${styleUtils.media.orientation.portrait} {
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: ${props => (!props.isMenuOpen ? 'auto' : 'hidden')};
    width: 100%;
  }

  ${styleUtils.media.orientation.landscape} {
    flex-direction: row;
    height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &::-webkit-scrollbar-track {
    background-color: ${props => props.theme.color.black};
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar {
    background-color: ${props => props.theme.color.black};
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.color.grey};
    border-radius: 10px;
  }
`;
