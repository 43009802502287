import React from 'react';

import CarouselButton from './CarouselButton';
import useCarousel from '../../utils/hooks/useCarousel';

import * as S from './styles';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  renderItem: (item: any, index: number) => JSX.Element;
  slides: any[];
} & HTMLAttributes<HTMLDivElement>;

const Carousel = ({ renderItem, slides, ...rest }: Props) => {
  const carouselRef = React.useRef(null);

  const { currentSlides, handleScrollPrevious, handleScrollNext, setShouldAutoScroll } = useCarousel<HTMLDivElement>({
    allSlides: slides,
    ref: carouselRef,
  });

  const handleMouseEnter = () => {
    setShouldAutoScroll(false);
  };

  const handleMouseLeave = () => {
    setShouldAutoScroll(true);
  };

  return (
    <S.Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <CarouselButton direction="up" onClick={handleScrollPrevious} />
      <CarouselButton direction="left" onClick={handleScrollPrevious} />

      <S.Carousel ref={carouselRef} {...rest}>
        {currentSlides.map((slide: any, index) => renderItem(slide, index))}
      </S.Carousel>

      <CarouselButton direction="right" onClick={handleScrollNext} />
      <CarouselButton direction="down" onClick={handleScrollNext} />
    </S.Wrapper>
  );
};

export default Carousel;
