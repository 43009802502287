import React from 'react';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';

import NavigationMenuItemListItem from '../NavigationMenuItems/NavigationMenuItemListItem';
import NavigationMenuSubMenu from '../NavigationMenuSubMenu';
import envConfig from '../../../../../config/env';
import routes from '../../../../../config/routes';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  showrooms: ReactNode;
};

const NavigationMenuMenu = ({ showrooms }: Props) => {
  return (
    <S.List>
      <NavigationMenuItemListItem url={routes.home}>
        <S.HomeIcon icon={faHome} size="sm" /> Home
      </NavigationMenuItemListItem>

      <NavigationMenuItemListItem url={routes.search}>
        <S.HomeIcon icon={faSearch} size="sm" /> Search
      </NavigationMenuItemListItem>

      <NavigationMenuSubMenu title="About Us">
        <NavigationMenuItemListItem isLevel2={true} url={routes.designerAndCompany}>
          Designer &amp; Company
        </NavigationMenuItemListItem>

        <NavigationMenuItemListItem isLevel2={true} url={routes.qualityAndManufacturing}>
          Quality &amp; Manufacturing
        </NavigationMenuItemListItem>

        <NavigationMenuItemListItem isLevel2={true} url={routes.sustainabilityAndFairtrade}>
          Sustainability &amp; Fairtrade
        </NavigationMenuItemListItem>
      </NavigationMenuSubMenu>

      <NavigationMenuSubMenu title="Showrooms &amp; Stores">
        {showrooms}

        <NavigationMenuItemListItem hasLargePadding={true} isLevel2={true} url={routes.globalDistribution}>
          Global Distribution
        </NavigationMenuItemListItem>
      </NavigationMenuSubMenu>

      <NavigationMenuItemListItem url="/walkthrough">3D Walkthrough Bochum Showroom</NavigationMenuItemListItem>

      <NavigationMenuItemListItem url={routes.showsAndEvents}>Shows &amp; Events</NavigationMenuItemListItem>

      <NavigationMenuItemListItem url="/shows-and-events/family-affair">A Family Affair</NavigationMenuItemListItem>

      <NavigationMenuItemListItem url="/thebook">The Book</NavigationMenuItemListItem>

      <NavigationMenuItemListItem url="/Daydreamer">Daydreamer</NavigationMenuItemListItem>

      <NavigationMenuItemListItem url={routes.imprint}>Imprint</NavigationMenuItemListItem>

      <NavigationMenuItemListItem url={routes.privacyPolicyDe}>Privacy Policy</NavigationMenuItemListItem>

      {/* TODO: Enable in production when ready. */}
      {envConfig.isDev && <NavigationMenuItemListItem url={routes.pressNewsletter}>Press Newsletter</NavigationMenuItemListItem>}
    </S.List>
  );
};

export default NavigationMenuMenu;
