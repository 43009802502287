import React from 'react';
import { ThemeProvider } from 'emotion-theming';

import theme from '../styling/theme';
import { HeaderContextProvider } from './HeaderContext';
import { NavigationContextProvider } from './NavigationContext';
import { SearchContextProvider } from './SearchContext';

// Types
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const ContextProvider = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <NavigationContextProvider>
        <SearchContextProvider>
          <HeaderContextProvider>{children}</HeaderContextProvider>
        </SearchContextProvider>
      </NavigationContextProvider>
    </ThemeProvider>
  );
};

export default ContextProvider;
