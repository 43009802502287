import styled from '../../../../../../utils/styling/styled';

import NavigationMenuCollectionGridItemWrapper from '../NavigationMenuCollectionGridItems/NavigationMenuCollectionGridItemWrapper';
import styleUtils from '../../../../../../utils/styling/styleUtils';

export const Wrapper = styled(NavigationMenuCollectionGridItemWrapper)`
  display: none;

  ${styleUtils.media.orientation.landscape} {
    margin-right: 1rem;
    display: inline-flex;
    overflow: hidden;
  }
`;
