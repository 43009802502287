import envConfig from '../../config/env';
import useSessionStorage from './useSessionStorage';

// This one is a bit mindfucking:
// The grid is 3x3, but if we have a next page,
// the last collection is a link to the next page,
// if we have a previous page the first collection is
// a link to the previous page.
// This makes it a bit more complicated to figure out
// which slice of the array to show.

const GRID_SIZE = 9;

type GetHasNextPageInput = {
  allCollections: any[];
  currentPage: number;
};

const getHasNextPage = ({ allCollections, currentPage }: GetHasNextPageInput): boolean => {
  if (currentPage === 0) {
    // The page has more collections than fit on the first page.
    if (allCollections.length - GRID_SIZE > 0) {
      return true;
    }

    return false;
  }

  // If we're not on the first page,
  // we need to calculate how many collections we already show
  // to figure out if the rest would fit on one page.
  // If the current page would only have `GRID_SIZE - 1` collections to show,
  // we wouldn't need another one and show all `GRID_SIZE - 1` collections +
  // the link to the previous page. But if we have more than `GRID_SIZE - 1`
  // we can only show `GRID_SIZE - 2` (because of the next page link).
  const firstPageEnd = allCollections.length - 1 - GRID_SIZE - 1;

  if (firstPageEnd - currentPage * (GRID_SIZE - 2) > 0) {
    return true;
  }

  return false;
};

type GetNextCollectionsInput = {
  allCollections: any[];
  currentPage: number;
};

const getNextCollections = ({ allCollections, currentPage }: GetNextCollectionsInput) => {
  const hasNextPage = getHasNextPage({ allCollections, currentPage });

  if (currentPage === 0) {
    if (hasNextPage) {
      // If we're on the first page and have a next page,
      // we'll show the first `GRID_SIZE - 1` collections.
      return allCollections.slice(0, GRID_SIZE - 1);
    }

    // Otherwise show all collections.
    return allCollections.slice(0, GRID_SIZE);
  }

  // This page starts where the previous page left off,
  // so we need to find that out first.
  // If we're not on the first page, the page before should have a
  // next page and previous page link, so `GRID_SIZE - 2` collections.
  // Except if this is the second page, but to handle that we'll calculate
  // This formular would not work if we're on the second page,
  // but because we starting to count the pages with `0`, `page - 1`
  // will be zero aswell so we'll start exactly where the first page left off.
  const currentPageStart = GRID_SIZE - 1 + (GRID_SIZE - 2) * (currentPage - 1);

  if (hasNextPage) {
    // We have a previous and a next page, so we can only show `GRID_SIZE - 2` collections.
    return allCollections.slice(currentPageStart, currentPageStart + GRID_SIZE - 2);
  }

  // We only have a previous page, so we can show `GRID_SIZE - 1` collections.
  return allCollections.slice(currentPageStart, currentPageStart + GRID_SIZE - 1);
};

const useCollectionGridPagination = <T>(allCollections: T[]) => {
  const [currentPage, setCurrentPage] = useSessionStorage<number>(envConfig.ssKeyMenuPosition, 0);

  const currentCollections = getNextCollections({ allCollections, currentPage });
  const hasNextPage = getHasNextPage({ allCollections, currentPage });
  const hasPreviousPage = currentPage > 0;

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  return {
    currentCollections,
    hasNextPage,
    hasPreviousPage,
    nextPage: handleNextPage,
    previousPage: handlePreviousPage,
  };
};

export default useCollectionGridPagination;
