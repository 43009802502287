import React from 'react';
import store from 'store2';

import notNullOrUndefined from '../misc/notNullOrUndefined';

type UseLocalStorageOptions = {
  saveInitialIfNone?: boolean;
};

// Based on: https://usehooks.com/useLocalStorage
function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options: UseLocalStorageOptions = {},
): [T, (value: T | ((value: T) => T)) => void] {
  const { saveInitialIfNone } = options;

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      // Get from local storage by key
      const item = store(key);

      if (!notNullOrUndefined(item) && saveInitialIfNone) {
        store(key, initialValue);

        const setItem = store(key);

        return setItem;
      }

      if (notNullOrUndefined(item)) {
        return item;
      }

      return initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = (value: T | ((value: T) => T)) => {
    try {
      const currentValue = store(key);
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(currentValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      store(key, valueToStore);
    } catch (error) {
      //
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
