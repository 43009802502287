import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import Section from '../../Heading/Section';
import createOmitProps from '../../../utils/styling/createOmitProps';
import styleUtils from '../../../utils/styling/styleUtils';

type ContentProps = {
  textColor: string;
};

export const Content = styled(Section, { shouldForwardProp: createOmitProps(['textColor']) })<ContentProps>`
  /* Flex Container */
  display: flex;
  justify-content: space-between;

  color: ${props => props.textColor};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.vertical.bottom};

    /* Flex Container */
    flex-direction: column;

    height: 100%;
    width: 300px;

    ${styleUtils.media.desktop.xsmall} {
      margin-top: -0.5rem;
    }

    ${styleUtils.media.desktop.large} {
      margin-top: 0rem;
    }
  }

  ${styleUtils.media.orientation.portrait} {
    /* Flex Container */
    flex-direction: row;
  }
`;

type ShowroomsWrapperProps = {
  textColor: string;
};

export const ShowroomsWrapper = styled.div<ShowroomsWrapperProps>`
  ${styleUtils.padding.vertical.bothQuarter};

  border-bottom: 0.5px solid ${props => props.textColor};
  border-top: 0.5px solid ${props => props.textColor};

  ${styleUtils.media.orientation.portrait} {
    flex: 0 0 45%;
  }
`;

export const ShowroomsTitle = styled.span`
  font-size: 0.9rem;
  line-height: 1.5;
  text-transform: uppercase;
`;

export const ShowroomsList = styled.ul`
  ${styleUtils.padding.vertical.top};

  ${styleUtils.media.orientation.landscape} {
    flex: 0 0 45%;

    ${styleUtils.media.desktop.xsmall} {
      ${styleUtils.padding.vertical.none};
    }

    ${styleUtils.media.desktop.large} {
      ${styleUtils.padding.vertical.top};

      margin-top: 0.1rem;
    }
  }
`;

type SectionProps = {
  backgroundColor: string | null;
  textColor: string;
};

export const Wrapper = styled(ScrollSection, { shouldForwardProp: createOmitProps(['textColor']) })<SectionProps>`
  /* Flex Container */
  flex-direction: column;

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.left};

    flex: 0 0 ${props => props.theme.section.footer.landscape.width}px;
    min-width: ${props => props.theme.section.footer.landscape.width}px;
    width: ${props => props.theme.section.footer.landscape.width}px;

    padding-top: calc(4rem + 90px);

    ${styleUtils.media.desktop.xsmall} {
      padding-top: 2.5rem;
    }

    ${styleUtils.media.desktop.large} {
      padding-top: calc(4rem + 120px);
    }
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.top};

    height: ${props => props.theme.section.footer.portrait.height}px;
    max-height: ${props => props.theme.section.footer.portrait.height}px;
    min-height: ${props => props.theme.section.footer.portrait.height}px;
  }
`;
