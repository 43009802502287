import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import CarouselItem from '../../../../components/Carousel/CarouselItem';
import SectionCarouselItem from '../../../../components/Section/SectionCarousel/SectionCarouselItem';
import buildCollectionPageUrl from '../../../../utils/url/buildCollectionPageUrl';

// Types
import { SectionCarouselCollectionsCollectionContainerFragment } from './__generated__/SectionCarouselCollectionsCollectionContainerFragment';

export const fragment = graphql`
  fragment SectionCarouselCollectionsCollectionContainerFragment on CMS_Collection {
    name
    collectionPage {
      slug
    }
    cover {
      ...CarouselPictureFragment
    }
  }
`;

type Props = {
  collection: SectionCarouselCollectionsCollectionContainerFragment;
};

const SectionCarouselCollectionsCollectionContainer = ({ collection }: Props) => {
  const picture = idx(collection, _ => _.cover.file.childImageSharp.fluid);
  const slug = idx(collection, _ => _.collectionPage.slug);

  if (!collection.name || !picture || !slug) {
    return null;
  }

  const url = buildCollectionPageUrl(slug);

  return (
    <CarouselItem>
      <SectionCarouselItem name={collection.name} picture={picture} url={url} />
    </CarouselItem>
  );
};

export default SectionCarouselCollectionsCollectionContainer;
