import isPropValid from '@emotion/is-prop-valid';

import styled from '../../../../utils/styling/styled';

import ArchitonicIcon from '../../../SVG/ArchitonicIcon';
import FacebookIcon from '../../../SVG/FacebookIcon';
import Heading from '../../../Heading';
import HouzzIcon from '../../../SVG/HouzzIcon';
import InstagramIcon from '../../../SVG/InstagramIcon';
import styleUtils from '../../../../utils/styling/styleUtils';

// Icons

type SocialIconProps = {
  textColor: string;
};

export const Architonic = styled(ArchitonicIcon, { shouldForwardProp: isPropValid })<SocialIconProps>`
  fill: ${props => props.textColor};
  height: 1rem;
  width: 1rem;
`;

export const Facebook = styled(FacebookIcon, { shouldForwardProp: isPropValid })<SocialIconProps>`
  fill: ${props => props.textColor};
  height: 1rem;
  width: 1rem;
`;

export const Houzz = styled(HouzzIcon, { shouldForwardProp: isPropValid })<SocialIconProps>`
  fill: ${props => props.textColor};
  height: 1rem;
  width: 1rem;
`;

export const Instagram = styled(InstagramIcon, { shouldForwardProp: isPropValid })<SocialIconProps>`
  fill: ${props => props.textColor};
  height: 1rem;
  width: 1rem;
`;

//

export const Title = styled(Heading)`
  ${styleUtils.font.weight.bold};
  font-size: 0.9rem;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.vertical.top};
  }

  ${styleUtils.media.orientation.portrait} {
    flex: 0 0 45%;
  }
`;
