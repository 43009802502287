import buildBreadcrumbListItem from './buildBreadcrumbListItem';
import envConfig from '../../config/env';

import janKathLogo from './assets/default_og_image.png';

export const STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM = buildBreadcrumbListItem({ name: 'JAN KATH', position: 1, url: '/' });

// Also update `gatsby-config`.
export const STRUCTURED_DATA_ORGANIZATION_SCHEMA = {
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Bochum, Germany',
    postalCode: 'D-44789',
    streetAddress: 'Friederikastrasse 148',
  },
  email: 'info@jan-kath.com',
  faxNumber: '+49 (0) 234 941 23 90',
  logo: {
    '@type': 'ImageObject',
    height: 1600,
    url: janKathLogo,
    width: 1600,
  },
  name: 'Jan Kath Design GmbH',
  sameAs: ['https://www.facebook.com/JanKathRugs/', 'https://www.instagram.com/jankathofficial/'],
  url: envConfig.appUrl,
  telephone: '+49 (0) 234 941 23 44',
};

export const STRUCTURED_DATA_ORGANIZATION = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  ...STRUCTURED_DATA_ORGANIZATION_SCHEMA,
};
